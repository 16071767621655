import React from 'react';
import { useHistory } from 'react-router-dom';
import { Connection } from 'twilio-client';
import profile from '../../img/male.png';
import { Api, Endpoint } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';


interface Props {
    connection?: Connection
    callData: any
}

enum BrowserStatus {
    Online = "online",
    Offline = "offline",
    OnCall = "on-call"
}

const CallButton = (props: Props) => {

    const getUser = () => {
        return AuthService.getUser();
    }

    const conn = props.connection;
    const statusChange = async (status: BrowserStatus) => {
        const data = getUser();
        const r = await Api.patch<any, any>(Endpoint.USER, { browserStatus: status, id: data?.user.id });
    }
    const { push: navigateTo } = useHistory();
    const answerCall = () => {
        statusChange(BrowserStatus.OnCall);
        conn?.accept();
    }

    const endCall = () => {
        statusChange(BrowserStatus.Online);
        conn?.disconnect();
    }
    const callData = props.callData;
    console.log('Connection from call Button', props.connection);

    return (
        <div >
            {/* {props.connection ? */}
            <div className="monitoring-floating-button"
                style={{
                    left: "20px",
                    right: "auto",
                    marginBottom: "50px",
                    backgroundColor: '#1b55e2',
                    boxShadow: '0 2px 20px 0 #403d3b'

                }}>
                <div className="emergency-icon">
                    <img src={profile} alt="info-emergency-logo" className="emergency-image" width="80px" height="70px" style={{
                        marginRight: "20px",
                        width: "80px",
                        height: "70px"
                    }} />
                </div>
                <div className="emergency-name">
                    <p>Incoming Call from <br></br>{conn?.parameters.From}</p>
                    <button className="btn btn-success" style={{ marginRight: '05px' }} onClick={answerCall}>Answer</button>
                    <button className="btn btn-danger" onClick={endCall}>End</button>
                </div>
            </div>
            {/* : ""} */}

        </div>
    )
}
export default CallButton;
