import { GoogleMap } from '@react-google-maps/api';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import { MediaStreamRecorder, RecordRTCPromisesHandler } from 'recordrtc';
import 'video-react/dist/video-react.css';
import { Loading } from '../../../components/alerts/loading.component';
import { ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { EmergencyContext } from '../../../context/emergency-request.context';
import { Driver, Gender } from '../../../entities/driver.entity';
import { Emergency, EmergencyStatus } from '../../../entities/emergency.entity';
import { GPS } from '../../../entities/gps.entity';
import { IceServers } from '../../../entities/ice.servers.entity';
import { Protocol } from '../../../entities/protocol.entity';
import { IPaginationOptions, IPaginationResponse } from '../../../interfaces/paginate.interface';
import { Api, Endpoint } from '../../../services/api.service';
import { ACTIONS, EmergencyService } from '../../../services/emergency.service';
import { getStorageUrl } from '../../../util/file.util';
import { showToastError } from '../../../util/notification';

let recenterNeeded = false;
let emergencyMap: google.maps.Map | undefined = undefined;
let carMarker: google.maps.Marker | undefined = undefined;
let mapCenter: google.maps.LatLng | undefined = undefined;
let trackLine: google.maps.Polyline | undefined = undefined;
let locationListener: NodeJS.Timeout | undefined = undefined;
let mapBounds: google.maps.LatLngBounds | undefined = undefined;

interface FindBookingByPlateNo { plate: string | undefined }

const BLUE = "#047bf8";
const EmergencyCall = () => {
    // DRIVER EMERGENCY
    // PASSENGER EMERGENCY
    // EMERGENCY RECORIDING AND SAVING
    // DRIVER VEHICLE INFO
    // MAP DRIVER / PASSENGER LOCATION
    const history = useHistory();
    const UPLOADED_URL = useRef<string>();
    const { t } = useTranslation('forms');
    const PLAYER = useRef<ReactPlayer>(null);
    const EMERGENCY = useRef<EmergencyService>();
    const RECORDER = useRef<RecordRTCPromisesHandler>();
    const emergencyContext = useContext(EmergencyContext);
    const REMOTE_STREAM_ELEMENT = useRef<HTMLCanvasElement>(null);
    const [action, setAction] = useState<ACTIONS>(ACTIONS.CONNECTING);
    const [uploadPercentage, setUploadPercentage] = useState<number>();
    const [protocols, setProtocols] = useState<Protocol[] | undefined>(undefined);
    const [selectedProtocol, setSelectedProtocol] = useState<Protocol | undefined>(undefined);

    const getRecordingUrl = (emergency: Emergency): string | undefined => {
        if (!emergency.recordingFile) return undefined;
        return `${getStorageUrl()}/emergency/${emergency.id}/recording/${emergency.recordingFile.id}/${`recording`}.${emergency.recordingFile.extension}`;
    };
    async function getGPSByPlateNumber(plateNumber: string) {
        type ResponseGps = { gps: GPS; driver: Driver; }
        locationListener = setInterval(() => {
            Api.get<ResponseGps, FindBookingByPlateNo>(Endpoint.GPS, { plate: plateNumber }).then(res => {
                mapCenter = new google.maps.LatLng(res.gps.latitude, res.gps.longitude);
                carMarker && carMarker.setPosition(mapCenter);
                trackLine && trackLine.getPath().push(mapCenter);
                if (!recenterNeeded) {
                    mapBounds ? mapBounds.extend(mapCenter) : mapBounds = new google.maps.LatLngBounds(mapCenter);
                    emergencyMap && emergencyMap.fitBounds(mapBounds);
                }
            }).catch(err => {
                console.log(err);
            });
        }, 15000);
    }
    function createRecenterButton(remove: Function) {
        const div = document.createElement("div");
        div.setAttribute("style", "margin: 10px; height: 40px; width: 40px; justify-content: center; align-items: center; border-radius: 2px; box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;");

        const button = document.createElement("button");
        button.setAttribute("style", "width: 100%; height: 100%; border-radius: 2px; border: none; background-color: white");

        const image = document.createElement("img");
        image.setAttribute("src", "https://cdn.iconscout.com/icon/free/png-256/compass-2451562-2082565.png");
        image.setAttribute("style", "width: 100%; height: 100%; object-fit: contain");

        button.onclick = function () {
            if (mapCenter) {
                emergencyMap!.setCenter(mapCenter);
                remove();
            }
        }
        button.append(image);
        div.append(button);
        return div;
    }
    function startRecording(canvasStreamWithAudio: MediaStream) {
        if (!RECORDER.current) {
            try {
                log("startRecording", "RECORDING STARTED", "DEBUG");
                //memory full maybe an issue because of which recorder shows blank screen. below mime types 
                //have been tried to solve the txt format issue.
                //options.mimeType = 'video/webm\;codecs=vp8'; // vp8
                // options.mimeType = 'video/webm\;codecs=vp9'; // vp9
                // options.mimeType = 'video/webm\;codecs=h264'; // h264
                // options.mimeType = 'video/x-matroska;codecs=avc1'; // mkv
                RECORDER.current = new RecordRTCPromisesHandler(canvasStreamWithAudio, {
                    type: 'video',
                    mimeType: 'video/webm;codecs=h264',
                    recorderType: MediaStreamRecorder,
                    // canvas: {
                    //     width: 720,
                    //     height: 1600
                    // }
                });
                RECORDER.current.startRecording();
                // showToastSuccess("We are recording this stream...");
                //@ts-ignore
                // RECORDER.current = new CanvasRecorder(REMOTE_STREAM_ELEMENT.current!, { disableLog: false }); RECORDER.current.record();
            }
            catch(e) {
                console.error(e);
                showToastError("Unable to start recording...");
            }
        }
        else log("startRecording", "RECORDER ALREADY STARTED", "ERROR");
    }
    async function saveRecording(): Promise<ACTIONS> {
        const fName = "saveRecording";
        log(fName, "Stoppped Recording", "DEBUG");

        console.log("stop = ",await RECORDER.current?.stopRecording());
        return new Promise<ACTIONS>(async (resolve) => {
            const saveRec = window.confirm("Do you want to save this recording?");
            if (!saveRec) { resolve(ACTIONS.CALL_ENDED); RECORDER.current?.reset(); return; }
            else setAction(ACTIONS.SAVE_RECORDING);

            try {
                const blob = await RECORDER.current?.getBlob();
                console.log('This is Blob Data:', blob);
                if (blob) {
                    const upload = new FormData();                
                    if (blob) {
                        upload.append("emergencyId", emergencyContext.activeRequest!.id);
                        upload.append("file", blob, 'file');
                        console.log('upload Data', blob);
                        try {
                            // await new Promise((resolve) => {
                            //     let progress = 0;
                            //     const interval = setInterval(() => {
                            //         progress += 25; setUploadPercentage(progress);
                            //         if (progress == 100) { clearInterval(interval); resolve(true) }
                            //     }, 1000);
                            // });
                            const response: Emergency = await Api.upload(Endpoint.EMERGENCY_SAVE_RECORING, upload, (e: ProgressEvent) => {
                                const progress = Math.round((e.total / e.loaded) * 100);
                                setUploadPercentage(progress < 90 ? progress: 90);
                            });
                            setUploadPercentage(100);
                            console.log("Emergency Response:", response);
                            const videoLink = getRecordingUrl(response);
                            UPLOADED_URL.current = videoLink;
                            resolve(ACTIONS.SAVED);
                        } catch (error: any) {
                            log(fName, error.message || error, "ERROR");
                            console.log("error at saveRecording = ", error);
                            resolve(ACTIONS.ERROR_SAVE);
                        }
                    }
                }
                else resolve(ACTIONS.ERROR_SAVE);
            }
            catch (error: any) {
                log(fName, error.message || error, "ERROR");
                resolve(ACTIONS.ERROR_SAVE)
            }
        });
    }
    function playRecording() {
        setAction(ACTIONS.PLAY);
    }
    async function handleActions(action: ACTIONS) {
        const fname = "handleActions";
        setAction(action);
        switch (action) {
            case ACTIONS.CONNECTED:
                log(fname, "CALL CONNETED = " + !!REMOTE_STREAM_ELEMENT.current, "DEBUG");
                if (REMOTE_STREAM_ELEMENT.current) {
                    const remoteStream = EMERGENCY.current?.getRemoteStream();
                    if (remoteStream) {
                        const video = document.createElement("video");
                        const context = REMOTE_STREAM_ELEMENT.current.getContext("2d");
                        
                        video.srcObject = remoteStream;
                        video.addEventListener("loadedmetadata", function () {
                            REMOTE_STREAM_ELEMENT.current!.width = video.videoWidth;
                            REMOTE_STREAM_ELEMENT.current!.height = video.videoHeight;
                            video.play();
                        });

                        setInterval(() => {
                            context && context.drawImage(video, 0, 0, REMOTE_STREAM_ELEMENT.current?.width || 720, REMOTE_STREAM_ELEMENT.current?.height || 1600);
                        }, 16);

                        const canvasStream = REMOTE_STREAM_ELEMENT.current.captureStream();
                        const combinedStream = new MediaStream([...remoteStream.getAudioTracks(), ...canvasStream.getVideoTracks()]);
                        startRecording(combinedStream);
                    }
                }
                break;
            case ACTIONS.CALL_ENDED:
                setAction(ACTIONS.CALL_ENDED);
                if (locationListener) clearInterval(locationListener);
                log(fname, "CALL ENDED BY SERVICE", "DEBUG");
                break;
            case ACTIONS.SAVE_RECORDING:
                const response = await saveRecording();
                console.log("SAVE RECORDING RESPONSE = ", response);
                setAction(response);
                emergencyContext.endActive(false);
                break;
        }
    }
    function log(fName: string, log: string, level: "DEBUG" | "ERROR") {
        if (level == "DEBUG")
            console.log(`${new Date().toLocaleTimeString()} [DEBUG] EmergencyComponent@${fName} ${log}`)
        else
            console.error(`${new Date().toLocaleTimeString()} [ERROR] EmergencyComponent@${fName} ${log}`)
    }
    async function selectProtocol(Protocol: Protocol) {
        setSelectedProtocol(Protocol);
        console.log("protocol", Protocol)
        const response = await Api.patch<any, any>(Endpoint.Emergency, { id: emergencyContext.activeRequest?.id, protocol: Protocol.id });
    }
    async function endCall(sendEvent: boolean = true) {
        EMERGENCY.current?.endCall(sendEvent);
        setAction(ACTIONS.CALL_ENDED);
    }
    function getMessage(action: ACTIONS) {
        switch (action) {
            case ACTIONS.CONNECTING:
                return "Connecting with remote stream...";
            case ACTIONS.CALL_ENDED:
                return "Call Ended";
            case ACTIONS.INTERNET_DOWN:
                return "Looks like your internet is down, please check Internet connection and try again";
            case ACTIONS.SAVE_RECORDING:
                return "SAVING RECORDING...";
            case ACTIONS.SAVED:
                return "Recording is saved";
            case ACTIONS.BUFFER_FAIL:
                return "No stream found to save! This can happen because the live stream was corrupted.";
        }
    }

    useEffect(() => {
        async function startEmergency() {
            try {
                if (emergencyContext.activeRequest) {
                    const { type, driver, passenger, vehicle, status } = emergencyContext.activeRequest;
                    console.log("EMERGENCY STATUS = ", status);
                    if (status == EmergencyStatus.New) {
                        const userId = type == "DriverApp" ? driver.id! : passenger.id!;
                        const iceServers = await Api.get(Endpoint.ICE, {}) as IceServers;
                        EMERGENCY.current = new EmergencyService(userId, handleActions, iceServers);
                        getGPSByPlateNumber(vehicle.plate);
                    }
                }
            }
            catch (e) {
                showToastError('Cannot Start Emergency');
                console.log("Error at startEmergency = ", e);
            }
        }
        startEmergency();
    }, [emergencyContext.activeRequest]);

    useEffect(() => {
        emergencyMap = new google.maps.Map(document.getElementById("googleMaps@emergency")!);
        google.maps.event.addListenerOnce(emergencyMap, "idle", async function () {
            emergencyMap!.addListener("dragend", () => {
                if (recenterNeeded == false) {
                    recenterNeeded = true;
                    emergencyMap!.controls[google.maps.ControlPosition.RIGHT_CENTER].push(createRecenterButton(() => {
                        emergencyMap!.controls[google.maps.ControlPosition.RIGHT_CENTER].pop();
                        recenterNeeded = false;
                    }));
                }
            });
        });
        // Api.on('emergency_update', handleUpdatedEmergency);
        Api.get<IPaginationResponse<Protocol>, IPaginationOptions>(Endpoint.PROTOCOL_LIST, { page: 0 }).then(({ items }) => {
            setProtocols(items);
        });
        return () => {
            // Api.off('emergency_update', handleUpdatedEmergency);
            mapCenter = undefined; carMarker = undefined; emergencyMap = undefined;
            mapBounds = undefined; recenterNeeded = false; locationListener = undefined;
        }
    }, []);

    return (
        <div className="content-box">
            <div className='row'>
                <div className='col-md-4'>
                    <div className='w-100 h-100'>
                        {(action && (![ACTIONS.CONNECTED, ACTIONS.PLAY].includes(action))) && (
                            <div className='w-100 h-100 bg-white' style={{ position: 'absolute', top: 0, right: 0, zIndex: 2 }}>
                                <div className='d-flex flex-column h-100 justify-content-center align-items-center'>
                                    {(() => {
                                        const buffer = [];
                                        if ([ACTIONS.CONNECTING, ACTIONS.SAVE_RECORDING].includes(action)) {
                                            buffer.push(<Loading loading key="loading" />, <p key="action">{getMessage(action)}</p>);
                                            if (action == ACTIONS.SAVE_RECORDING) {
                                                buffer.push(
                                                    <div key="progress" style={{ width: `${uploadPercentage || 0}%`, backgroundColor: 'blue', height: '0.3rem', alignSelf: 'flex-start' }}></div>
                                                );
                                            }
                                        }
                                        if (action == ACTIONS.SAVED) {
                                            const buttons = <div key="savedButtons" className='d-flex flex-row w-75 justify-content-around'>
                                                <div onClick={playRecording} className='btn btn-outline-primary'>Play recording</div>
                                                <div onClick={() => history.push('/')} className='btn btn-primary'>Dashboard</div>
                                            </div>
                                            buffer.push(<p key="action1">{getMessage(action)}</p>, buttons);
                                        }
                                        if (action == ACTIONS.CALL_ENDED) {
                                            const buttons = <div key="buttons" className='d-flex flex-row w-50 justify-content-around'>
                                                <div onClick={() => handleActions(ACTIONS.SAVE_RECORDING)} className='btn btn-outline-primary'>Save and close</div>
                                                <div onClick={() => history.push('/')} className='btn btn-outline-danger'>Close</div>
                                            </div>
                                            buffer.push(<p key="actionn">{getMessage(action)}</p>, buttons);
                                        }
                                        if ([ACTIONS.INTERNET_DOWN, ACTIONS.ERROR_SAVE].includes(action)) {
                                            buffer.push(<p key="actionnn">{getMessage(action)}</p>);
                                        }
                                        if (action == ACTIONS.BUFFER_FAIL) {
                                            buffer.push(<p key="actionnnn">{getMessage(action)}</p>, <div key="retry" className='btn btn-outline-secondary'>Retry Upload</div>);
                                        }
                                        return buffer;
                                    })()}
                                </div>
                            </div>
                        )}
                        <canvas ref={REMOTE_STREAM_ELEMENT} style={{
                            display: action == ACTIONS.CONNECTED ? "block" : "none", width: "100%", height: "100%", zIndex: 1
                        }}></canvas>
                        <ReactPlayer controls volume={1} url={UPLOADED_URL.current} ref={PLAYER} width={"100%"} height={"100%"} style={{
                            display: action == ACTIONS.PLAY ? "block" : "none", zIndex: 1
                        }} />
                        {[ACTIONS.CONNECTED, ACTIONS.CONNECTING].includes(action) && (
                            <button onClick={() => endCall(true)} key="endCall" type='button' className='btn btn-outline-danger text-danger text-center rounded-pill fs-5'
                                style={{ position: 'absolute', top: '80%', left: '40%', backgroundColor: 'transparent', zIndex: 3 }}>
                                End Call
                            </button>
                        )}
                    </div>
                </div>
                <div className='col-md-8'>
                    {(() => {
                        if (emergencyContext.activeRequest) {
                            if (emergencyContext.activeRequest.level == "high") return (
                                <div className='alert alert-warning'>
                                    <h6>Now in Emergency mode</h6>
                                </div>
                            )
                            else return (
                                <div className='alert alert-info'>
                                    <h6>Now in Monitor mode</h6>
                                </div>
                            );
                        }
                        else return null;
                    })()}
                    <GoogleMap
                        id="googleMaps@emergency"
                        options={{ streetViewControl: false }}
                        mapContainerStyle={{ width: '100%', height: "100%", minWidth: 400, minHeight: 500, borderRadius: 20 }}>
                    </GoogleMap>
                </div>
            </div>

            {/* LOWER DIV */}
            {emergencyContext.activeRequest && (
                <div className="row" style={{ marginTop: '2%' }}>
                    {/* VECHICLE DIV */}
                    <div className="col-md-4" id="vehicleDiv">
                        <ViewContent title="Vehicle Details">
                            <ViewContentItems>
                                <ViewContentItem title={t('vehicle.registrationSection.vinDigits')}>
                                    {emergencyContext.activeRequest.vehicle.vin}
                                </ViewContentItem>
                                <ViewContentItem title={t('vehicle.registrationSection.registrationExpiry')}>
                                    {emergencyContext.activeRequest.vehicle.registrationExpiryDate}
                                </ViewContentItem>
                                <ViewContentItem title={t('vehicle.modelSection.model')}>
                                    {emergencyContext.activeRequest.vehicle.model}
                                </ViewContentItem>
                                <ViewContentItem title={t('vehicle.registrationSection.numPassengers')}>
                                    {emergencyContext.activeRequest.vehicle.numPassengers}
                                </ViewContentItem>
                                <ViewContentItem title={t('vehicle.modelSection.numberOfLuggages')}>
                                    {emergencyContext.activeRequest.vehicle.numLuggage}
                                </ViewContentItem>
                            </ViewContentItems>
                        </ViewContent>
                    </div>
                    {/* DRIVER DIV */}
                    <div className="col-md-4">
                        <ViewContent title="Driver Details">
                            <ViewContentItems>
                                <ViewContentItem title="NAME">
                                    {emergencyContext.activeRequest.driver.firstName + " " + emergencyContext.activeRequest.driver.firstName}
                                </ViewContentItem>
                                <ViewContentItem title="EMAIL">
                                    {emergencyContext.activeRequest.driver.email}
                                </ViewContentItem>
                                <ViewContentItem title="PHONE">
                                    {emergencyContext.activeRequest.driver.phone}
                                </ViewContentItem>
                                <ViewContentItem title="GENDER">
                                    {(emergencyContext.activeRequest.driver.gender || Gender.Male).toUpperCase()}
                                </ViewContentItem>
                                <ViewContentItem title="DATE OF BIRTH">
                                    {emergencyContext.activeRequest.driver.dateOfBirth}
                                </ViewContentItem>
                            </ViewContentItems>
                        </ViewContent>
                    </div>
                    {emergencyContext.activeRequest.passenger && (
                        <div className="col-md-4">
                            <ViewContent title="Passenger Details">
                                <ViewContentItems>
                                    <ViewContentItem title="NAME">
                                        {emergencyContext.activeRequest.passenger.firstName}
                                    </ViewContentItem>
                                    <ViewContentItem title="EMAIL">
                                        {emergencyContext.activeRequest.passenger.email}
                                    </ViewContentItem>
                                    <ViewContentItem title="PHONE">
                                        {emergencyContext.activeRequest.passenger.phone}
                                    </ViewContentItem>
                                    <ViewContentItem title="GENDER">
                                        {(emergencyContext.activeRequest.passenger.gender || Gender.Male).toUpperCase()}
                                    </ViewContentItem>
                                    <ViewContentItem title="DATE OF BIRTH">
                                        {emergencyContext.activeRequest.passenger.dateOfBirth}
                                    </ViewContentItem>
                                </ViewContentItems>
                            </ViewContent>
                        </div>
                    )}
                    {/* EMERGENCY PROTOCOL DIV */}
                    {/*                 
                    {emergencyHeight && (
                        <div className="col-md-4" style={{ display: 'flex', overflowY: 'scroll', height: emergencyHeight }}>
                            <ViewContent title="Emergency Protocol">
                                <ViewContentItems>
                                    {typeof protocols !== "undefined" && (
                                        <>
                                            {protocols.map((item) => (
                                                <ViewContentItem title={item.title} key={item.id}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <div style={{ flex: 0.6 }}>
                                                            {item.description}
                                                        </div>
                                                        <div onClick={() => selectProtocol(item)} className="text-right" style={{ flex: 0.4 }}>
                                                            <button className="btn btn-primary text-bold">
                                                                {selectedProtocol?.title === item.title ? "Protocol Assigned" : "Select Protocol"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </ViewContentItem>
                                            ))}
                                        </>
                                    )}
                                </ViewContentItems>
                            </ViewContent>
                        </div>
                    )}
                    */}
                </div>
            )}

            {emergencyContext.activeRequest && (
                <div className="col-md-4 d-flex flex-row my-2 gap-2">
                    <ViewContent title="Emergency Details">
                        <ViewContentItems>
                            <ViewContentItem title={`Type`}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div style={{ flex: 0.6 }}>
                                        {emergencyContext.activeRequest.type}
                                    </div>
                                </div>
                            </ViewContentItem>
                            <ViewContentItem title={`Level`}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div style={{ flex: 0.6 }}>
                                        {emergencyContext.activeRequest.level == 'high' ? 'Emergency' : 'Moniter'}
                                    </div>
                                </div>
                            </ViewContentItem>
                            <ViewContentItem title={`Status`}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div style={{ flex: 0.6 }}>
                                        {emergencyContext.activeRequest.status}
                                    </div>
                                </div>
                            </ViewContentItem>
                        </ViewContentItems>
                    </ViewContent>
                    {protocols && (
                        <ViewContent title="Emergency Protocol">
                            <ViewContentItems>
                                {typeof protocols !== "undefined" && (
                                    <>
                                        {protocols.map((item) => (
                                            <ViewContentItem title={item.title} key={item.id}>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <div style={{ flex: 0.6 }}>
                                                        {item.description}
                                                    </div>
                                                    <div onClick={() => selectProtocol(item)} className="text-right" style={{ flex: 0.4 }}>
                                                        <button className="btn btn-primary text-bold">
                                                            {selectedProtocol?.title === item.title ? "Protocol Assigned" : "Select Protocol"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </ViewContentItem>
                                        ))}
                                    </>
                                )}
                            </ViewContentItems>
                        </ViewContent>
                    )}
                </div>
            )}
        </div>
    );
}

export default EmergencyCall;