import { Autocomplete, GoogleMap, TrafficLayer } from '@react-google-maps/api';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Fleet } from "../../../entities/fleet.entity";
import { GPS } from '../../../entities/gps.entity';
import { Group } from "../../../entities/group.entity";
import { Network } from "../../../entities/network.entity";
import { Operator } from "../../../entities/operator.entity";
import { Service } from "../../../entities/service.entity";
import { Vehicle } from "../../../entities/vehicle.entity";
import { Zone } from "../../../entities/zone.entity";
import car from '../../../img/car.svg';
import greyPointA from '../../../img/greyPointA.png';
import greyPointB from '../../../img/greyPointB.png';
import { Api, Endpoint } from '../../../services/api.service';
import { OptionsPost } from './section/post.component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Driver } from '../../../entities/driver.entity';
import { getCarSVG } from './car.svg';
import { FormInput } from '../../../components/form/input/input.component';
import { useFormContext } from 'react-hook-form';
import { getGeneralZoneDistanceTitle, getGeneralZoneDistanceValue } from '../../../util/distance';
import { getDatesForFilters, StatsFilterPeriod } from '../../../interfaces/stats.interface';
var polyline = require('@mapbox/polyline');

type MapRenderType =
  { type: "Clear" } |
  { type: "RenderAll", polyline: boolean } |
  { type: "TrackOne", polyline: boolean }

let directionsService: google.maps.DirectionsService;
let gpsData: any;
export interface Search {
  vehicle: string | undefined,
  fleet: string | undefined,
  zone: string | undefined,
  network: string | undefined,
  service: string | undefined,
  operator: string | undefined,
  group: string | undefined,
  showPolyLine: boolean | undefined,
  trackVehicle: boolean | undefined

}

let mapCenter: google.maps.LatLng | undefined = undefined;
let trackLine: google.maps.Polyline | undefined = undefined;
let markerPickup: google.maps.Marker | undefined = undefined;
let markerDropOff: google.maps.Marker | undefined = undefined;
let trackMap: google.maps.Map<HTMLElement> | undefined = undefined;
let trackMapBounds: google.maps.LatLngBounds | undefined = undefined;
let vehicleId: any;
let trackStatus: boolean | undefined;
const BLUE = "#047bf8";
const GREY = "#808080";
const ORANGE = "#FFA500"


let recenterNeeded = false;
let dashboardMap: google.maps.Map | undefined = undefined;
let mapMarkers: Map<string, { marker: google.maps.Marker | null, polyline: google.maps.Polyline }> = new Map();
let dashboardMapBounds: google.maps.LatLngBounds | undefined = undefined;
let driverCode: string;
let vehiclePlate: string;
let expandTemp = false;
export function DashboardMap() {
  let pickupAutocompleteRef = useRef<Autocomplete>(null);
  const history = useHistory();
  const formProps = useFormContext();
  const [zoom, setZoom] = useState(18);
  const [to, setTo] = useState<string>('');
  const [zone, setZone] = useState<Zone>();
  const [fleet, setFleet] = useState<Fleet>();
  const [group, setGroup] = useState<Group>();
  const [from, setFrom] = useState<string>('');
  const [height, setHeight] = useState<number>();
  const [service, setService] = useState<Service>();
  const [map, setMap] = useState<google.maps.Map>();
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [network, setNetwork] = useState<Network>();
  const [gps, setGps] = useState<GPS[] | undefined>();
  const [operator, setOperator] = useState<Operator>();
  const [size, setSize] = useState<google.maps.Size>();
  const [sendData, setSendData] = useState<boolean>(true);
  const [point, setPoint] = useState<google.maps.Point>();
  const [center, setCenter] = useState<google.maps.LatLng>();
  const [trackVehicle, setTrackVehicle] = useState<boolean>();
  const [showPolyLine, setShowPolyLine] = useState<boolean>();
  const [showTrafficLayer, setTrafficLayer] = useState<boolean>(false);
  const [location, setLocation] = useState<google.maps.places.PlaceResult>();
  const [time, setTime] = useState<StatsFilterPeriod>(StatsFilterPeriod.Today);

  const [expand, setExpand] = useState(false);
  const { t } = useTranslation("acl");
  const translationDashboard = useTranslation('dashboard')

  const [count, setCount] = useState(0);
  let infowindow = new google.maps.InfoWindow();
  let trafficLayer: any = new google.maps.TrafficLayer();


  useLayoutEffect(() => {
    var body = document.body,
      html = document.documentElement;
    var height = Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight);
    setHeight(height)

  }, []);

  



  useEffect(() => {
    dashboardMap = new google.maps.Map(
      document.getElementById("googleMaps@dashboard")!
    );

    dashboardMap.setOptions({
      minZoom: 2.5,
    });

    google.maps.event.addListenerOnce(dashboardMap, "idle", async function () {
      console.log("Map Loaded and is IDLE");
      dashboardMap!.controls[google.maps.ControlPosition.RIGHT_TOP].push(
        createFilterButton()
      );
      dashboardMap!.addListener("dragend", () => {
        if (recenterNeeded == false) {
          recenterNeeded = true;
          dashboardMap!.controls[google.maps.ControlPosition.RIGHT_TOP].push(
            createRecenterButton(() => {
              dashboardMap!.controls[
                google.maps.ControlPosition.RIGHT_TOP
              ].pop();
              recenterNeeded = false;
            })
          );
        }
      });
      renderMap({ type: "Clear" });
      renderMap({ type: "RenderAll", polyline: false });
      pickupAutocompleteRef.current?.state.autocomplete?.addListener(
        "place_changed",
        () => onAddressSelect("pickup")
      );
    });

    function onGPS(payload: any) {
      console.log("onGPS:", payload);

      if (payload.vehicleId) {
        liveGpsLocation(payload)
      }
    }
    Api.on("gps_latest_location", onGPS);
    return () => {
      Api.off("gps_latest_location", onGPS);
    };
  }, []);

  function liveGpsLocation(payload: any, vehicleId?: string) {
    const car = mapMarkers.get(payload.vehicleId);
    if (car) {
      const position = new google.maps.LatLng(
        payload.latitude,
        payload.longitude
      );
      car.polyline.getPath().push(position);
      car.marker!.setPosition(position);
      const options = {
        content: `
        <br>
        <span><strong>DRIVER CODE : </strong>${payload.driverCode}</span>
        <br>
        <span><strong>VEHICLE PLATE : </strong>${payload.vehiclePlate}</span>
        <br>
        <span><strong>SPEED : </strong>${
          parseInt(payload.speed) / getGeneralZoneDistanceValue()
        } ${getGeneralZoneDistanceTitle()}</span>
        <br>
        <span><strong>TIMESTAMP : </strong>${new Date(
          payload.createTime
        ).toLocaleString()}</span>
        
        `,
        disableAutoPan: true,
      };
      handleInfoWindow(dashboardMap, car.marker, options);

      if (vehicleId == payload.vehicleId) {
        dashboardMap!.setOptions({
          center: position,
          zoom: 18,
        });
      }
    }
  }

  
  function decodePolyLine(value: string) {
    if (value) {
      const decodedValue = polyline.decode(value, 5)
      return decodedValue
    }
  }
  const onSubmitSearch = async (e: any) => {
    e.preventDefault();
    console.log('Data Fleet:', fleet)
    console.log('Data Zone:', zone)
    console.log('Data Service:', service)
    console.log('Data Network:', network)
    console.log('Data Operator:', operator)
    console.log('Data Group:', group)
    const { gpsLatest } = await Api.post<any, any>(
      Endpoint.GPS_LIST,
      { fleet: fleet?.id, zone: zone?.id, service: service?.id, network: network?.id, operator: operator?.id, group: group?.id, vehicle: vehicle?.id, showPolyLine, trackVehicle, time, from, to }
    );
    console.log('Final Data:', gpsLatest);
    setGps(gpsLatest);
  }

  const expandIt = () => {
    expandTemp = !expandTemp;
    setExpand(expandTemp);

  }
  // const onLoad = React.useCallback((map) => {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map)
  //   setTimeout(() => {
  //     // Sydney
  //     const DEFAULT_CENTER = new google.maps.LatLng(-33.8688, 151.2093);
  //     const DEFAULT_POINT = new google.maps.Point(32, 32);
  //     const DEFAULT_SIZE = new google.maps.Size(64, 64);
  //     // setCenter(DEFAULT_CENTER);
  //     setPoint(DEFAULT_POINT)
  //     setSize(DEFAULT_SIZE)
  //     setZoom(2);
  //   }, 100);

  //   directionsService = new google.maps.DirectionsService();

  //   pickupAutocompleteRef.current?.state.autocomplete?.addListener('place_changed', () => onAddressSelect('pickup'));

  // }, []);
  // const onUnmount = React.useCallback(() => {
  //   setMap(undefined)
  // }, []);
  const drawPolyLine = async (gps: any, idOfVehicle: string, heading: number, infoWindowOptions:any, carColor: string) => {
    console.log("gps data",gps)
    if (gps && gps?.length > 0) {
      const lastIndex = gps.length - 1;
      const icon = {
        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getCarSVG(carColor || 'black', heading)),
        anchor: new google.maps.Point(32, 50),
        scaledSize: new google.maps.Size(50, 100)
      }
      const carMarker = new google.maps.Marker({ position: new google.maps.LatLng(parseFloat(gps[lastIndex][0]), parseFloat(gps[lastIndex][1])), icon, map: dashboardMap!, clickable: true });

      dashboardMapBounds = new google.maps.LatLngBounds();

      const positions = gps.map((item: any) => {
        const point = new google.maps.LatLng(item[0], item[1])
        dashboardMapBounds!.extend(point);
        return point;
      });

      trackLine = new google.maps.Polyline({
        path: positions, strokeColor: GREY, strokeWeight: 2,
        draggable: false, clickable: false, editable: false, visible: true, map: dashboardMap!
      });

      console.log("vehicle id", idOfVehicle)
      handleInfoWindow(dashboardMap, carMarker, infoWindowOptions)
      mapMarkers.delete(idOfVehicle)
      mapMarkers.set(idOfVehicle, { marker: carMarker, polyline: trackLine });
      dashboardMap!.fitBounds(dashboardMapBounds);
      if (!vehicleId) {

        dashboardMap!.setOptions({
          zoom: 10
        });
      }
      else {
        dashboardMap!.setOptions({
          center: new google.maps.LatLng(parseFloat(gps[lastIndex][0]), parseFloat(gps[lastIndex][1])), zoom: 18
        });

      }
      // mapCenter = positions;
      // dashboardMap!.setCenter(positions)

    }

  }
  const onAddressSelect = (type: 'pickup') => {
    const ref = pickupAutocompleteRef;
    const autocomplete: google.maps.places.Autocomplete | null | undefined = ref.current?.state.autocomplete;
    if (!autocomplete) {
      return;
    }
    mapCenter = new google.maps.LatLng(autocomplete.getPlace().geometry?.location.lat() as number, autocomplete.getPlace().geometry?.location.lng() as number);
    dashboardMap!.setOptions({
      center: mapCenter, zoom: 15
    });
  }
  const refresh = async () => {
    try {
      // renderMap({ type: "Clear"});
      renderMap({ type: "RenderAll", polyline: false });

    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    console.log("network", showPolyLine, trackVehicle)
    const search: Search = { fleet: fleet?.id, service: service?.id, group: group?.id, network: network?.id, zone: zone?.id, operator: operator?.id, vehicle: vehicle?.id, trackVehicle, showPolyLine }
    refresh();
    vehicleId = vehicle?.id
    trackStatus = trackVehicle
    //add the layer - don't view it unless user toggles button  
    // renderMap({ type: "RenderAll", polyline: false });
  }, [fleet, network, service, zone, vehicle, operator, group, showPolyLine, trackVehicle, to, from, time])
 
  function onMarkerDoubleClick(vehicleId: string) {

  }
  function onMarkerSingleClick(vehicleId: string) {
    const marker = mapMarkers.get(vehicleId);
    if (marker) {
      trackMapBounds = new google.maps.LatLngBounds(marker.marker!.getPosition() || undefined);
      marker.polyline.getPath().forEach(({ lat, lng }) => {
        trackMapBounds?.extend(new google.maps.LatLng(lat(), lng()));
      });
      dashboardMap!.fitBounds(trackMapBounds);
    }
  }
  function makeCarMessage(code: string, vehicle: string) {
    let message = `${code} DRIVING ${vehicle}`;
    message = message + (operator ? "\nOPERATOR: " + operator.title : "");
    message = message + (fleet ? "\nFLEET: " + fleet.title : "");
    message = message + (zone ? "\nZONE: " + zone.title : "");
    message = message + (service ? "\nSERVICE: " + service.title : "");
    message = message + (network ? "\nNETWORK: " + network.title : "");
    message = message + (group ? "\nGROUP: " + group.title : "");
    return message;
  }

  function handleInfoWindow(map: any, carMarker: any, options: any) {

    google.maps.event.addListener(carMarker, "mouseover", function () {

      infowindow.setOptions(options)
      infowindow.open(map, carMarker);

    });

    google.maps.event.addListener(carMarker, "mouseout", function () {
      infowindow.close();

    });

  }
  async function requestVehicles() {
    try {
      
      Api.delete(Endpoint.DASHBOARD_TRACK_SESSION_GPS, { id: "WILL DELETE MAP" });
      console.log("markers", mapMarkers)

      for (let [key, { marker, polyline }] of mapMarkers) {
        polyline?.setMap(null);
        mapMarkers.delete(key);
        // marker!.setMap(null);
        marker!.setVisible(false);//this line works
        marker!.setMap(null);
        marker!.setPosition(null);
        marker = null
          };
      // mapMarkers.clear()
      console.log("markers after", mapMarkers)

      dashboardMapBounds = new google.maps.LatLngBounds(mapCenter);
      
      let toDate: Date, fromDate: Date;
      if (time == StatsFilterPeriod.Custom) {
          if (from) fromDate = new Date(from); else fromDate = new Date();
          if (to) toDate = new Date(to); else toDate = new Date();
        }
      else ({ startDate: fromDate, endDate: toDate } = getDatesForFilters(time));
      const data = {
        zone: zone?.id, fleet: fleet?.id, vehicle: vehicle?.id, network: network?.id, service: service?.id,
        showPolyLine, trackVehicle, from: fromDate, to: toDate, time
      }
      console.log("data = ", data);
      const { gpsLatest, polyline } = await Api.post<any, any>(Endpoint.GPS_LIST, data);
      console.log("polyline in tr", polyline, gpsLatest);
      // draw a polyline
      if (polyline && polyline?.length > 0) {
        
        polyline.map((async (item: any) => {
          const decodedRoutePolyline = decodePolyLine(item.encode_polyline)
          const options = {
            content: `
        <br>
        <span><strong>DRIVER CODE : </strong>${item.driver.code}</span>
        <br>
        <span><strong>VEHICLE PLATE : </strong>${item.vehicle.plate}</span>
        <br>
        
        
        `,
            disableAutoPan: true
          }
          drawPolyLine(decodedRoutePolyline, item.driver.code + " | " + item.vehicle.code, gpsLatest.length > 0 ? gpsLatest[0].heading : 0, options, item.vehicle.color)
        }));
      }
      else {
        const drivers: string[] = [];
        if (gpsLatest.length > 0) {
          for (let res of gpsLatest) {
            res.driver = res.driver as Driver; res.vehicle = res.vehicle as Vehicle;
            const location = new google.maps.LatLng(res.latitude, res.longitude);
            console.log("Heading Rotation:", res.heading)
            const icon = {
              url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getCarSVG(res.vehicle.color, res.heading)),
              anchor: new google.maps.Point(32, 50),
              scaledSize: new google.maps.Size(50, 100)
            }

            driverCode = res.driver.code
            vehiclePlate = res.vehicle.plate
            const carMarker = new google.maps.Marker({ position: location, icon, map: dashboardMap, clickable: true, });
            // carMarker.addListener("dblclick", () => onMarkerDoubleClick(res.vehicle.id!));
            // carMarker.addListener("click", () => onMarkerSingleClick(res.vehicle.id!));
            const options = {
              content: `
          <br>
          <span><strong>DRIVER CODE : </strong>${res.driver.code}</span>
          <br>
          <span><strong>VEHICLE PLATE : </strong>${res.vehicle.plate}</span>
          <br>
          <span><strong>SPEED : </strong>${parseInt(res.speed) / getGeneralZoneDistanceValue()} ${getGeneralZoneDistanceTitle()}</span>
          <br>
          <span><strong>TIMESTAMP : </strong>${new Date(res.createTime).toLocaleString()}</span>
          
          `,
              disableAutoPan: true
            }
            handleInfoWindow(dashboardMap, carMarker, options)
            const polyline = new google.maps.Polyline({});
            mapMarkers.set(driverCode + " | "  + vehiclePlate, { marker: carMarker, polyline });
            dashboardMapBounds.extend(location);
            drivers.push(res.driver.id!);
          }
          Api.post(Endpoint.DASHBOARD_TRACK_SESSION_GPS, { drivers: drivers });
          dashboardMap!.fitBounds(dashboardMapBounds);
          // dashboardMap!.setOptions({
          //   zoom: 18
          // });
          // if(vehicle?.id == gpsLatest[0].vehicle.id ){
          //   mapCenter = dashboardMap!.getCenter();
          //   dashboardMap!.setZoom(15)
          // }
          // mapCenter = dashboardMap!.getCenter();
          // dashboardMap!.setZoom(15)
          const centerPoint = new google.maps.LatLng(gpsLatest[0].location.coordinates[1], gpsLatest[0].location.coordinates[0])
          setTimeout(() => {
            if (vehicle) {
              dashboardMap?.setCenter(centerPoint)
              mapCenter = centerPoint
              dashboardMap!.setOptions({
                center: mapCenter, zoom: 15
              });
            }
          }, 500)
        }
      }
    }
    catch (e: any) {
      showToast(e.message || e);
    }
  }
  function createFilterButton() {
    const div = document.createElement("div");
    div.setAttribute("style", "margin: 10px; height: 40px; width: 40px; justify-content: center; align-items: center; border-radius: 2px; box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;");

    const button = document.createElement("button");
    button.setAttribute("style", "width: 100%; height: 100%; border-radius: 2px; border: none; background-color: white");

    const image = document.createElement("img");
    const filterImage = require('../../../img/filter.png');
    image.setAttribute("src", filterImage);
    image.setAttribute("style", "width: 100%; height: 100%; object-fit: contain");

    button.onclick = function () {
      expandIt();
    }
    button.append(image);
    div.append(button);
    return div;
  }
  function createRecenterButton(remove: Function) {
    const div = document.createElement("div");
    div.setAttribute("style", "margin: 10px; height: 40px; width: 40px; justify-content: center; align-items: center; border-radius: 2px; box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;");

    const button = document.createElement("button");
    button.setAttribute("style", "width: 100%; height: 100%; border-radius: 2px; border: none; background-color: white");

    const image = document.createElement("img");
    const recenterImage = require('../../../img/recenter.png')
    image.setAttribute("src", recenterImage);
    image.setAttribute("style", "width: 100%; height: 100%; object-fit: contain");

    button.onclick = function () {
      if (mapCenter) {
        dashboardMap!.setCenter(mapCenter);
        remove();
      }
    }
    button.append(image);
    div.append(button);
    return div;
  }
  function showToast(message: string) {
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  function renderMap(data: MapRenderType) {
    switch (data.type) {
      case "Clear":
        navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
          mapCenter = new google.maps.LatLng(latitude, longitude);
          for (let [key, { marker, polyline }] of mapMarkers) {
            marker!.setMap(null);
            marker = null;
            polyline?.setMap(null);
            mapMarkers.delete(key);
          };
          // mapMarkers.clear()
          dashboardMap!.setOptions({
            center: mapCenter, zoom: 2
          });
        }, (err) => {
          showToast(err.message);
        });
        break;
      case "RenderAll":
        requestVehicles();
        break;
    }
  }
  const onChangeFrom = (e: any) => {
    setFrom(e.target.value)
  }
  const onChangeTo = (e: any) => {
    setTo(e.target.value)
  }
  const changeTime = (e: any) => {
    setTime(e.target.value)
  }

  return (
    <div>
      <GoogleMap
        id="googleMaps@dashboard"
        mapContainerStyle={{
          width: "100%",
          height: "100vh",
        }}
      >
        {showTrafficLayer ? (
          <TrafficLayer
            onLoad={(layer: any) => {
              layer.setMap(dashboardMap);
            }}
            options={{
              autoRefresh: true,
            }}
          />
        ) : null}
        <Autocomplete ref={pickupAutocompleteRef} fields={["geometry"]}>
          <input
            style={{ zIndex: "1" }}
            autoFocus
            className="register-input white-input"
            required
            placeholder={translationDashboard.t("map.findAddress")}
            type="search"
          />
        </Autocomplete>
        <div
          style={{
            border: "0px",
            margin: "0px",
            padding: "0px",
            position: "absolute",
            cursor: "pointer",
            userSelect: "none",
            borderRadius: "2px",
            overflow: "hidden",
            top: "0px",
            right: "60px",
          }}
        >
          <div
            style={{
              marginRight: "15px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            <form className="form-inline justify-content-sm-end">
              <select
                value={time}
                onChange={changeTime}
                className="form-control form-control-sm rounded"
              >
                <option value={StatsFilterPeriod.Today}>
                  {translationDashboard.t("dropdown.today")}
                </option>
                <option value={StatsFilterPeriod.Yesterday}>
                  {translationDashboard.t("dropdown.yesterday")}
                </option>
                <option value={StatsFilterPeriod.Custom}>
                  {translationDashboard.t("dropdown.custome")}
                </option>
              </select>
            </form>
          </div>
          {time == StatsFilterPeriod.Custom ? (
            <div className="row rounded" style={{ marginTop: "10px" }}>
              <FormInput
                label={t("From")}
                name="from"
                type="datetime-local"
                style={{ display: "flex", fontWeight: "500", fontSize: "20px" }}
                onChange={onChangeFrom}
                className="col-sm-6"
                validation={{
                  required: time === StatsFilterPeriod.Custom ? true : false,
                }}
                tabIndex={7}
                {...formProps}
              />
              <FormInput
                label={t("To")}
                name="to"
                type="datetime-local"
                style={{ display: "flex", fontWeight: "500", fontSize: "20px" }}
                onChange={onChangeTo}
                className="col-sm-6"
                validation={{
                  required: time === StatsFilterPeriod.Custom ? true : false,
                }}
                tabIndex={7}
                {...formProps}
              />
            </div>
          ) : null}
        </div>
        {expand ? (
          <div
            style={{
              border: "0px",
              margin: "10px",
              padding: "0px",
              position: "absolute",
              cursor: "pointer",
              userSelect: "none",
              borderRadius: "2px",
              height: "inherit",
              width: "300px",
              overflow: "hidden",
              top: "50px",
              right: "60px",
            }}
          >
            <div
              style={{
                borderRadius: "10px",
                backdropFilter: "blur(10px)",
                background: "none rgb(210 222 243 / 81%)",
                border: "0px",
                padding: "0px",
                position: "absolute",
                cursor: "pointer",
                userSelect: "none",
                // height: "94vh",
                width: "300px",
                boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
              }}
            >
              <div className="container">
                <legend
                  style={{
                    fontSize: "25px",
                    textAlign: "center",
                    marginTop: "10px",
                    fontWeight: "lighter",
                  }}
                >
                  {t("dashboard.map.map-elements.filter")}
                </legend>
                <hr />
                <OptionsPost
                  setTrafficLayer={setTrafficLayer}
                  setTrackVehicle={setTrackVehicle}
                  setShowPolyLine={setShowPolyLine}
                  submit={requestVehicles}
                  setFleet={setFleet}
                  setService={setService}
                  setVehicle={setVehicle}
                  setZone={setZone}
                  setGroup={setGroup}
                  setOperator={setOperator}
                  setNetwork={setNetwork}
                />
              </div>
            </div>
          </div>
        ) : null}
      </GoogleMap>
      <ToastContainer theme="dark" />
    </div>
  );
}
