import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormSelect } from '../../../../components/form/input/select.component';
import { Fleet } from '../../../../entities/fleet.entity';
import { Group } from '../../../../entities/group.entity';
import { Network } from '../../../../entities/network.entity';
import { Operator } from '../../../../entities/operator.entity';
import { Service } from '../../../../entities/service.entity';
import { Vehicle } from '../../../../entities/vehicle.entity';
import { Zone } from '../../../../entities/zone.entity';
import { IPaginationOptions, IPaginationResponse } from '../../../../interfaces/paginate.interface';
import { Api, Endpoint } from '../../../../services/api.service';
import { RideType } from '../../../fare/post/post.component';


const dataPoints = [
  Endpoint.VEHICLE_LIST,
  Endpoint.ZONE_LIST,
  Endpoint.SERVICE_LIST,
  Endpoint.FLEET_LIST,
  Endpoint.OPERATOR_LIST,
  Endpoint.NETWORK_LIST,
  Endpoint.GROUP_LIST,
]

interface Props {
  rideTypes: RideType[]
  setFleet: any
  setService: any
  setZone: any
  setOperator: any
  setGroup: any
  setNetwork: any
  setVehicle: any
}

export function FilterOptions({
  rideTypes, setFleet, setService, setZone, setOperator, setGroup, setNetwork, setVehicle
}: Props) {

  async function getData<T>(endpoint: Endpoint): Promise<IPaginationResponse<T>> {
    console.log('This Is End Point:', endpoint);
    const data = await Api.get<IPaginationResponse<T>, IPaginationOptions>(endpoint, { page: 0, limit: 20 })
    console.log('This Is return Data:', data);
    return data
  }

  const [fleets, setFleets] = useState<Fleet[]>([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [networks, setNetworks] = useState<Network[]>([]);
  const [operators, setOperators] = useState<Operator[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [zones, setZones] = useState<Zone[]>([]);

  const init = useCallback(async () => {
    const [vehicles, zones, services, fleets, operators, networks, groups] = await Promise.all(dataPoints.map(getData))
    setVehicles((vehicles as IPaginationResponse<Vehicle>).items);
    setFleets((fleets as IPaginationResponse<Fleet>).items);
    setNetworks((networks as IPaginationResponse<Network>).items);
    setOperators((operators as IPaginationResponse<Operator>).items);
    setGroups((groups as IPaginationResponse<Group>).items);
    setZones((zones as IPaginationResponse<Zone>).items);
    setServices((services as IPaginationResponse<Service>).items);
  }, []);

  useEffect(() => {
    init();
  }, [init]);


  const { t } = useTranslation(['forms', 'main']);
  const childProps = useFormContext();

  return (
    <>
      <FormSelect<Vehicle>
        label={t("Vehicles")}
        name="vehicle"
        className="col-sm-12"
        showLabel={false}
        validation={{ required: false }}
        items={vehicles}
        onChange={setVehicle}
        labelField="plate"
        tabIndex={14}
        {...childProps}
      />
      <FormSelect<Network>
        label={t("vehicle.networkSection.networksLabel")}
        name="network"
        className="col-sm-12"
        showLabel={false}
        validation={{ required: false }}
        items={networks}
        labelField="title"
        onChange={setNetwork}
        tabIndex={14}
        {...childProps}
      />
      <FormSelect<Fleet>
        label={t("vehicle.fleetSection.fleetsLabel")}
        name="fleet"
        className="col-sm-12"
        showLabel={false}
        validation={{ required: false }}
        items={fleets}
        labelField="title"
        onChange={setFleet}
        tabIndex={14}
        {...childProps}
      />
      <FormSelect<Operator>
        label={t("vehicle.operatorSection.operatorsLabel")}
        name="operator"
        className="col-sm-12"
        showLabel={false}
        validation={{ required: false }}
        items={operators}
        labelField="title"
        onChange={setOperator}
        tabIndex={14}
        {...childProps}
      />
      <FormSelect<Group>
        label={t("Groups")}
        name="group"
        className="col-sm-12"
        showLabel={false}
        validation={{ required: false }}
        items={groups}
        labelField="title"
        onChange={setGroup}
        tabIndex={14}
        {...childProps}
      />
      <FormSelect<Service>
        label={t("vehicle.serviceSection.servicesLabel")}
        name="service"
        className="col-sm-12"
        showLabel={false}
        validation={{ required: false }}
        items={services}
        labelField="title"
        onChange={setService}
        tabIndex={14}
        {...childProps}
      />
      <FormSelect<Zone>
        label={t("Zones")}
        name="zone"
        className="col-sm-12 mb-2"
        showLabel={false}
        validation={{ required: false }}
        items={zones}
        labelField="title"
        onChange={setZone}
        tabIndex={14}
        {...childProps}
      />
    </>);
}