import { Acl } from "../../interfaces/core.interface";

export enum ReportAclCode {
  BalanceSheet = 'balance-sheet',
  Ride = "ride",
  Shift = "shift",
  Gps = 'gps',
  Zone = 'zone'
}

export const ReportAcl = [
  {
    code: ReportAclCode.BalanceSheet,
    title: 'report.balanceSheet.title',
    description: 'report.balanceSheet.subTitle',
  },

  {
    code: ReportAclCode.Ride,
    title: 'report.ride.title',
    description: 'report.ride.subTitle',
  },

  {
    code: ReportAclCode.Shift,
    title: 'report.shift.title',
    description: 'report.shift.subTitle',
  },

  {
    code: ReportAclCode.Gps,
    title: 'report.gps.title',
    description: 'report.gps.subTitle',
  },
  {
    code: ReportAclCode.Zone,
    title: 'report.zoneReport.title',
    description: 'report.zoneReport.subTitle',
  }
 
] as Acl<ReportAclCode>;
