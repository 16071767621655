import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FloatingButton from "../components/emergency/floating.button";
import { Emergency, EmergencyStatus } from "../entities/emergency.entity";
import { Api, Endpoint } from "../services/api.service";

type EmergencyContextType = {
    requests: Emergency[], activeRequest?: Emergency, endActive: (self: boolean) => void;
}
export const EmergencyContext = React.createContext<EmergencyContextType>({
    requests: [], endActive: (self: boolean) => { }
});

interface Props {
    children: React.ReactNode | React.ReactNode[]
}
export function EmergencyContextProvider(props: Props) {
    const { push } = useHistory();
    const [showRequests, setShowRequests] = useState(true);
    const [requests, setRequests] = useState<Emergency[]>([]);
    const [activeRequest, setActiveRequest] = useState<Emergency>();

    function addEmergency(emergency: Emergency) {
        setRequests((previous) => [emergency, ...previous]);
    }
    function updateEmergency(emergency: Emergency) {
        console.log({ activeRequest: activeRequest?.id, emergency: emergency.id });
        if (activeRequest && activeRequest.id == emergency.id) setActiveRequest(emergency);
        setRequests((previous) => previous.map((request) => request.id == emergency.id 
            ? typeof emergency.visible == "undefined" ? { ...emergency, visible: request.visible }: emergency
            : request
        ));
    }
    function newEmergency(data: Emergency) {
        data.visible = true;
        setTimeout(() => {
            data.visible = false;
            updateEmergency(data);    
        }, 30000);
        addEmergency(data);
    }
    async function activateEmergency(reqId: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            const canView = await Api.patch(Endpoint.Emergency, { id: reqId, status: EmergencyStatus.TakenByWebAdmin })
                .catch((err) => { console.log("err at patch taken by web admin = ",err); resolve(false); return; }) as boolean;
            
            if (canView) {
                setShowRequests(false);
                setActiveRequest(() => requests.find(({ id }) => id == reqId));
                push('/emergency');
                resolve(true);
            }
            else resolve(false);
        });
    }
    function endActive(self: boolean) {
        if (self) {
            const response = window.confirm("Do you want to end this call?");
            if (!response) return;
        }
        Api.patch(Endpoint.Emergency, { id: activeRequest?.id, status: EmergencyStatus.Completed }).catch((e) => console.log("Patch Error Emergency = ",e));
        setRequests((previous) => previous.filter(({ id }) => id !== activeRequest?.id ));
        setActiveRequest(undefined);
        setShowRequests(true);
    }

    useEffect(() => {
        Api.once('emergency_alert', newEmergency);
        Api.once('modify_alert', updateEmergency);
        Api.once('emergency_update', updateEmergency);
        return () => {
            Api.off('emergency_alert', newEmergency);
            Api.off('modify_alert', updateEmergency);
            Api.off('emergency_update', updateEmergency);
        }
    }, [activateEmergency]);

    return <EmergencyContext.Provider value={{
        requests, activeRequest, endActive
    }}>
        {props.children}
        <div style={{ position: 'absolute', bottom: 10, right: 10, display: showRequests ? "block": "none" }}>
            {requests.filter(({ visible, level }) => visible || level == "high").slice(0.3).map((request, index) => (
                <FloatingButton activateRequest={activateEmergency} type='DRIVER SIDE' key={index + ""} request={request} marginBottom={index * 90} />
            ))}
        </div>
    </EmergencyContext.Provider>
}