import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../components/form/form.component";
import { Endpoint } from "../../../../services/api.service";
import { FilterOptions } from "./filter.component";

export interface RideType {
    id: string;
    name: string;
}

const rideTypes = [
    { name: 'Hail', id: 'hail ' },
    { name: 'App', id: 'app ' },
    { name: 'Dispatch', id: 'dispatch ' },
] as RideType[]

interface Props {
    setFleet: any
    setService: any
    setZone: any
    setOperator: any
    setGroup: any
    setNetwork: any
    setVehicle: any
    submit: () => Promise<void>,
    setTrackVehicle: any,
    setShowPolyLine: any,
    setTrafficLayer: any

}

export function OptionsPost({
    setFleet, setService, setZone, setOperator, setGroup, setNetwork, setVehicle, submit, setTrackVehicle, setShowPolyLine, setTrafficLayer
}: Props) {
    const { t } = useTranslation(['acl']);
    const [onSubmitText, setOnSubmitText] = useState("Search");
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setOnSubmitText("Loading...")
        submit();
        setOnSubmitText("Search");
    }
    /**
     * Render
     */
    return (
        <>
            <Form className='mt-0 pt-0 pl-1 pr-1'
                endpoint={Endpoint.GPS_LIST}
                childClassName="filterAlign"
            >
                <FilterOptions rideTypes={rideTypes} setFleet={setFleet} setService={setService} setVehicle={setVehicle} setZone={setZone} setGroup={setGroup} setOperator={setOperator} setNetwork={setNetwork} />
                <div className="filterCheckbox">

              
                <div className="pl-2 form-check-inline">

                    <label className="form-check-label">
                        <input onChange={(e) => { setTrackVehicle(e.target.checked) }} className="form-check-input" type="checkbox" /> {t("dashboard.map.map-elements.track")}
                    </label>
                </div>
                <div className="pl-2 form-check-inline">
                    <label className="form-check-label">
                        <input onChange={(e) => { setShowPolyLine(e.target.checked) }} className="form-check-input" type="checkbox" /> {t("Draw Polyline")}
                    </label>
                </div>

                <div className="pl-2 form-check-inline">
                    <label className="form-check-label">
                        <input onChange={(e) => { setTrafficLayer(e.target.checked) }} className="form-check-input" type="checkbox" /> {t("Show Traffic Layer")}
                    </label>
                </div>
                </div>
                <br></br>
                <div className="pl-2 form-check-inline" style={{ width: "100%", justifyContent: "space-around" }}>
                    <button style={{ width: "50%" }} className="btn btn-success" type="submit" onClick={onSubmit}> {onSubmitText} </button>
                </div>
            </Form>
        </>
    );
}
