import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormCheckBox } from '../../../components/form/input/checkbox.component';
import { FormInput } from '../../../components/form/input/input.component';
import { FormRadio, RadioOption } from '../../../components/form/input/radio.component';
import { FormSelect } from '../../../components/form/input/select.component';
import { FormSection } from '../../../components/form/section.component';
import { Currency } from '../../../entities/currency.entity';
import { Settings, Timezone } from '../../../entities/settings.entity';
import { Enviroment } from '../../../enums/environment.enum';
import { IPaginationOptions, IPaginationResponse } from '../../../interfaces/paginate.interface';
import { Api, Endpoint } from '../../../services/api.service';
import { SettingsService } from '../../../services/settings.service';
import Timezones from '../../settings/timezone.data';

const timezones = Timezones.map(tz => ({ id: tz, name: tz }))

interface PaymentMethod {
  id: string;
  name: string;
}

const paymentMethods = [
  { name: 'Cash', id: 'cash' },
  { name: 'App', id: 'app' },
  { name: 'Credit Card', id: 'credit-card' },
] as PaymentMethod[];
// onChange()={}

export function BasicSettings() {

  const { t } = useTranslation('forms');
  const childProps = useFormContext();
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [levy, setLevy] = useState();
  const [currency, setCurrency] = useState<any>();
  const [createCurrency, setCreateCurrency] = useState<any>();
  const [checked, setChecked] = useState<boolean>(false);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  

  useLayoutEffect(() => { 
    ref();
  }, [])

  useEffect(() => {

    getCurrency()

  }, [])

  const getCurrency = async () => {
    const { items } = await Api.get<IPaginationResponse<Currency>, IPaginationOptions>(Endpoint.SETTINGS_CURRENCIES_LIST, { page: 0 });
    setCreateCurrency(items);
  }

  const toCurrencyOption = (currency: Currency): RadioOption => {
    return {
      label: currency.code,
      value: currency.id as string,
    }
  }

  const toSubUnit = (value: number): number => {
    const settings: Settings | undefined = SettingsService.getSetting();
    const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
    return value / unit;
  }
  const form = childProps.getValues();

  const ref = useCallback(async() => { 
      console.log("form v",form)
      if(form?.id){
      const data = await Api.get<any, any>(Endpoint.ZONE_DATA, { id: form.id });
      console.log("Data zone",data)
      const time = data?.generalTimezone
      if(data)
      {
      data.generalTimezone = { id: time as string, name: time as string };
      setCurrency(data.generalCurrency) 

      }
     
      if (form.makeDefault == true) {
        setChecked(true)
      }
      else {
        setChecked(false)
      }
      childProps.reset(data); 
      setLevy(form.levy);
    }
  }, [childProps, currency, levy])


  return (
    <FormSection>
      <FormInput
        name="title"
        label={t("zone.zoneLabel")}
        type="text"
        className="col-sm-12"
        tabIndex={1}
        autoFocus
        validation={{ required: true }}
        {...childProps}
      />
      {process.env.REACT_APP_NAME == Enviroment.Poles? null :
      <>
      <FormInput
        name="waitSpeedThreshold"
        label={t("zone.speedThreshold")}
        placeholder="Km/hr"
        type="number"
        step={1}
        className="col-sm-12"
        tabIndex={2}
        validation={{ required: true, 
          
        }
        }
        
        {...childProps}
      />
      <FormInput
        name="levy"
        label={t("zone.levy")}  
        value = {levy}
        onChange = {(e:any)=>setLevy(e.target.value)}
        placeholder="Levy"
        type="number"
        step={0.01}
        className="col-sm-12"
        tabIndex={3}
        validation={{ required: true }}
        {...childProps}
      />
      </>}
      <FormSelect<Timezone>
        label={t("zone.timezone")}
        name="generalTimezone"
        className="col-lg-12"
        validation={{ required: true }}
        items={timezones}
        onChange={() => { }}
        tabIndex={4}
        {...childProps}
      />
      <FormCheckBox
        name="makeDefault"
        label={t("common.default")}
        checked={checked}
        onChange={handleChange}
        className="col-sm-12"
        tabIndex={5}
        validation={{ required: false }}
        {...childProps}
      />
      <FormRadio
        label={t("zone.unitLabel")}
        name="generalUnit"
        className="col-sm-12"
        validation={{ required: true }}
        {...childProps}
        options={[
          { label: 'Metric', value: 'metric' },
          { label: 'Imperial', value: 'imperial' },
        ]}
        tabIndex={6}
      />
      {currency ? 
      <FormRadio
        label={t("zone.currencyLabel")}
        name="generalCurrency"
        className="col-sm-12"
        validation={{ required: true }}
        
              {...childProps}
        onChange={(e: any) => {
          console.log("Currence", currency)
          const data = currency.map((cur: any) => {
            if (cur.value == e.target.value) {
              return {
                selected: cur?.value,
                label: cur.label,
                value: cur.value,
              }
            }
            else {
              return {
                label: cur.label,
                value: cur.value,
              }
            }
          })
          setCurrency(data);
        }}
        options={currency ? currency :[]}
        tabIndex={7}
      /> :
      <FormRadio
      label={t("zone.currencyLabel")}
      name="generalCurrency"
      className="col-sm-12"
      validation={{ required: true }}
      {...childProps}
      onChange={(e: any) => {
        const data = currency?.map((cur: any) => {
          if (cur.value == e.target.value) {
            return {
              selected: cur?.value,
              label: cur.label,
              value: cur.value,
            }
          }
          else {
            return {
              label: cur.label,
              value: cur.value,
            }
          }
        })
        setCurrency(data);
      }}
      options={currency ? currency :createCurrency ? createCurrency.map(toCurrencyOption):[]}
      tabIndex={7} />
      }


      {process.env.REACT_APP_NAME == Enviroment.Poles? null :<div className="row">
      <FormInput
          name="paymentDriverWarningThreshold"
          label={ t("zone.paymentWarningThreshold") }
          placeholder={`Enter Amount`}
          type="number"
          // step={ 0.01 }
          className="col-sm-6"
          tabIndex={9}
          validation={{
            pattern: /^[+-]?\d+(?:[.,]\d+)?/ 
          }}
          { ...childProps }
        />
        <FormInput
          name="paymentDriverBlockThreshold"
          label={ t("zone.paymentBlockThreshold") }
          placeholder={`Enter Amount`}
          type="number"
          className="col-sm-6"         
          tabIndex={10}
          validation={{
            pattern:/^[0-9]+$/ 
            
          }}
          { ...childProps }
        />
      </div>}
    </FormSection>
  );
}