import classNames from 'classnames';
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import 'react-responsive-modal/styles.css';
import { Link, RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import Select, { OptionsType } from 'react-select';
import ReactTooltip from 'react-tooltip';
import { Role, RoleAssignment } from "../../entities/role.entity";
import { ApiLogsRoute } from '../../pages/apiLogs/routes';
import { Api, Endpoint } from "../../services/api.service";
import { AuthService } from "../../services/auth.service";
import i18n from '../../services/i18n';
import { MenuItem, SubItem } from "./header.interface";
import Menu from './menu.data';

const linkMi = require(`../../img/${process.env.REACT_APP_LOGO_MINI}`);
interface Option<Entity> {
  label: string;
  value: string;
  ref: Entity,
  entity: string
}

interface moduleType {
  module: string,
  action: string
}

function HeaderComponent({ location }: RouteComponentProps) {
  const [menu, setMenu] = useState<Array<MenuItem>>(Menu);
  const [mobDisplay, setMobDisplay] = useState(false);
  const [driverCount, setDriverCount] = useState(0);
  const [search, setSearch] = useState("");
  const { t } = useTranslation('main');
  const Payload: any | undefined = AuthService.getUser();
  // const { user } = Payload;
  // const assignments: RoleAssignment[] = user ? [...((user?.role as Role).assignments as RoleAssignment[])] : [];
  // const moduleCodes = 

  const [assignments, setAssignments] = useState(Payload?.user ? [...((Payload.user?.role as Role).assignments as RoleAssignment[])] : [])
  const [moduleCodes, setModuleCodes] = useState<moduleType[]>(Array.from(assignments, assignment => ({
    module: assignment.module,
    action: assignment.action
  })));
  const listRef: any = useRef(null);
  const [listWidth, setListWidth] = useState<number>(0);
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const [options, setOptions] = useState<OptionsType<Option<any>>>([]);
  useEffect(autoSelect, [location]);
  useLayoutEffect(() => {
    getDriverCount()
    let userData = AuthService.getUser()
    userData = userData?.user?.id ? userData.user : userData
    const assignData = userData ? [...((userData?.role as Role).assignments as RoleAssignment[])] : []
    console.log("assign data", assignData)
    setAssignments(assignData)
    setModuleCodes(Array.from(assignData, assignment => ({
      module: assignment.module,
      action: assignment.action
    })))

  }, [])

  useEffect(() => {
    let lWidth = listRef?.current.offsetWidth
    setListWidth(lWidth)

    // const screenWidth = window.innerWidth    
    // setScreenWidth(screenWidth)
  }, [window.innerWidth])

  async function getDriverCount() {
    const count = await Api.get<any, any>(Endpoint.DRIVER_APPROVAL_COUNT)
    console.log("count", count);
    setDriverCount(count);
  }
  function autoSelect() {
    const link = location.pathname;
    const currentMenu = menu.find(item =>
      item.link === link || item.submenu?.find(submenu =>
        submenu.find(subsubmenu => subsubmenu.link === link))
    );
    if (currentMenu) {
      toggle(currentMenu, 'selected', true);
    } else {
      toggle(menu[0], 'selected', true);
    }
  }
  function toggle(item: MenuItem, property: 'active' | 'selected', value: boolean) {
    const _menu = [...menu];
    _menu.forEach(_m => _m[property] = false);
    const i = _menu.findIndex(m => m.title === item.title);
    if (i !== -1) {
      _menu[i][property] = value;
      setMenu(_menu);
    }
  }
  function subMenuToggle(item: MenuItem, property: 'active' | 'selected', value: boolean) {
    const _menu = [...menu];
    _menu.forEach(_m => _m[property] = false);
    const i = _menu.findIndex(m => m.title === item.title);
    if (i !== -1) {
      _menu[i][property] = value;
      setMenu(_menu);
      setMobDisplay(false);
    }
  }
  function subToggle(item: MenuItem, property: 'active' | 'selected', value: boolean, itemdisplay: string | undefined) {
    const _menu = [...menu];
    _menu.forEach(_m => _m[property] = false);
    const i = _menu.findIndex(m => m.title === item.title);
    if (i !== -1) {
      _menu[i][property] = value;
      setMenu(_menu);

      if (!(item.submenu === undefined)) {
        if (itemdisplay === undefined) {
          item.display = "block";
        }
        else if (item.display === "block") {
          item.display = "";
        }
        else {
          item.display = "block";
        }
      }
      //  const j= document.getElementById(item.title);
      //  if(!(item.submenu==undefined)){
      //  if(!(j?.classList.contains('d-block')))
      //  {j?.classList.add('d-block');}
      //  else{
      //    j?.classList.remove('d-block');
      //  }
      // }
    }
  }
  const showItem = (item: MenuItem) => {
    if (typeof item.module === "string") {
      return !!moduleCodes.find(singularity => item.isPublic || item.module === singularity.module);
    } else {
      return item.module.filter(mod => moduleCodes.find(singularity => mod === singularity.module)).length > 0;
    }
  }
  const showSubItem = (subitem: SubItem) => {
    if (subitem.code === undefined) {
      return true;
    } else if (typeof subitem.code === "string") {
      return !!moduleCodes.find(singularity => subitem.module === singularity.module && subitem.code === singularity.action);
    }
    else {
      return subitem.code.filter((singleCode: any) => moduleCodes.find(singularity => singleCode === singularity.action && subitem.module === singularity.module)).length > 0;
    }
  }
  const Mobmenu = () => {
    if (mobDisplay === true) {
      setMobDisplay(false);
    }
    if (mobDisplay === false) {
      setMobDisplay(true);
    }

  }
  const Search = (search: any) => {
    console.log("Search element", search);
    getSearchData(search);
    // setSearch(event.target.value);
    // const items = await Api.get<any, any>(Endpoint.GLOBAL_SEARCH,{search:search});
    // console.log("responseee from search",items);
    // setOptions(
    //   items ? items.map((i: any) => ({
    //     label:i.firstName,
    //     value: i.firstName,
    //     ref: i,
    //   })) : []
    // );

  }
  const checkInclude = (search: string, entity: any, key: string) => {
    console.log('Inside Check Include:', search, entity, key)
    if (key == 'driver' || key == 'operator' || key == 'passenger') {
      if (entity?.firstName?.includes(search))
        return entity.firstName;
      if (entity?.lastName?.includes(search))
        return entity.lastName;
      if (entity?.email?.includes(search)) {
        return entity.email;
      }
      if (entity?.code?.toLowerCase().includes(search.toLowerCase()))
        return entity.code;
    }
    if (key == 'fare' || key == 'zone' || key === "article" || key == "service") {
      console.log("entity", entity.title)

      if (entity?.title?.toLowerCase().includes(search.toLowerCase())) {
        return entity.title;
      }
    }
    if (key == 'vehicle')
      if (entity?.plate?.includes(search))
        return entity.plate;
  }
  const getSearchData = async (search: string) => {
    if (search !== "") {

      const items = await Api.get<any, any>(Endpoint.GLOBAL_SEARCH, { search: search });
      console.log("search items", items)
      let newarray: any = [];
      Object.keys(items).map(function (key, index) {
        const final = items[key].map((i: any) => {
          return {
            label: <div key={index}>
              {<strong> {checkInclude(search, i, key)} </strong>}
              {/* `:i.title!==undefined?`${i.title} • (${key}) `:`${i.plate} • (${key})` */}
              <span style={{ float: "right", marginLeft: "10px" }}> <span style={{ color: "#D3D3D3", fontSize: "10px" }}>{key.toLocaleUpperCase()} </span></span>
            </div>,
            value: checkInclude(search, i, key),
            ref: i,
            entity: key
          }
        })

        newarray.push(...final);

      });
      console.log('search response new:', newarray)
      setOptions(newarray);

    }
    else {
      setOptions([]);
    }
  }
  useEffect(() => {
    console.log('Options:', options); console.log("user op", Payload?.user, AuthService.getUser())
  }, [options]);
  let history = useHistory();
  const selectedContent = (value: any) => {
    console.log("selectedContent", value);
    if (value?.entity) {
      if (value.entity == 'fare') {
        history.replace({ pathname: `/${value.entity}/list/detail`, state: value.ref });
      }
      else {
        history.replace({ pathname: `/${value.entity}/list`, state: value.ref });
        // this.props.history.push({
        //   pathname: '/template',
        //   search: '?query=abc',
        //   state: { detail: response.data }
        // })
      }
    }
  }

  return (
    <div>
      <div className="menu-mobile menu-activated-on-click color-scheme-dark">
        <div className="mm-logo-buttons-w">
          <a className="mm-logo" href="#logo">
            <img src={linkMi} alt="LinkMi" />
            <span>{t('mainMenu.main.admin')}</span>
          </a>
          <div className="mm-buttons">
            <div className="content-panel-open">
              <div className="os-icon os-icon-grid-circles"></div>
            </div>
            <div className="mobile-menu-trigger">
              <div className="os-icon os-icon-hamburger-menu-1" onClick={Mobmenu} ></div>
            </div>
          </div>
        </div>
        {mobDisplay ?
          <div className="menu-and-user" style={{ display: 'block' }}>
            <ul className="main-menu">
              {menu.map((item: MenuItem) => (
                showItem(item) && (

                  <li key={item.title} className={classNames({ 'has-sub-menu': !!item.submenu })}>
                    <Link to={item.link} onClick={() => subToggle(item, 'selected', true, item.display)}>
                      <div className="icon-w">
                        <div className={`os-icon os-icon-${item.icon}`}></div>
                      </div>
                      <span>{t(item.title)} {t(item.title) == "Fleet" ? <strong style={{ marginLeft: "5px" }} className="badge badge-danger">{driverCount > 0 ? driverCount : ""}</strong> : ""}</span>

                    </Link>

                    <ul className="sub-menu" style={{ display: item.display }} id={item.title}>
                      {item.submenu?.map(submenu => submenu.map(subitem => (
                        showSubItem(subitem) && (
                          <li key={subitem.link}>

                            <Link to={subitem.link} onClick={() => subMenuToggle(item, 'selected', true)}>
                              {t(subitem.title)}
                              {subitem.badge && <strong className="badge badge-danger">{subitem.badge}</strong>}
                              {t(subitem.title) == "Drivers" ? <strong style={{ marginLeft: "5px" }} className="badge badge-danger">{driverCount > 0 ? driverCount : ""}</strong> : ""}
                            </Link>

                          </li>
                        )
                      )))}
                    </ul>
                  </li>
                )
              ))}
            </ul>

            <div className="row r9" style={{ marginRight: "10px", width: "14rem", marginLeft: "4%" }}>
              <Select
                placeholder={"Search"}
                // backspaceRemovesValue={  }
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#1b55e2',
                    primary: 'white',
                    neutral0: "#293145",
                    // neutral90:"red",
                    neutral80: "white",
                    neutral20: "#1b55e2",
                    neutral50: "white",
                  },
                })}
                isClearable
                isSearchable
                // hideSelectedOptions={ multiple }
                // value={ selectedOption }
                isDisabled={false}
                isLoading={false}
                // isMulti={ multiple }
                options={options}
                // closeMenuOnSelect={false }
                escapeClearsValue={true}
                // autoFocus={ autoFocus }
                noOptionsMessage={() => "No items to show"}
                // tabIndex={ tabIndex?.toString() }
                onChange={(value) => selectedContent(value as any)}
                onInputChange={(e) => Search(e)}
              />
            </div>
          </div> : null
        }
      </div>
      <div className="menu-w color-scheme-dark color-style-default menu-position-top menu-layout-compact sub-menu-style-over sub-menu-color-bright selected-menu-color-bright menu-activated-on-hover menu-has-selected-link"
        style={{ marginBottom: location.pathname == ApiLogsRoute.List ? 0: undefined }}
      >
        <div className="logo-w">
          <Link className="logo" to="/dashboard/overview">
            <div className="logo-element">
              <img src={linkMi} alt="LinkMi" />
            </div>
            <div className="logo-label">{t('mainMenu.main.admin')}</div>
          </Link>
        </div>
        {
          <ul ref={listRef} className="main-menu">
            {location.pathname == ApiLogsRoute.List ? []: menu.map((item: MenuItem) => (
              showItem(item) && (
                <li key={item.title} className={classNames({
                  'has-sub-menu': !!item.submenu,
                  selected: item.selected,
                  active: item.active,
                })}
                  onMouseEnter={() => toggle(item, 'active', true)}
                  onMouseLeave={() => toggle(item, 'active', false)}
                >
                  <Link to={item.link} onClick={() => toggle(item, 'selected', true)}>
                    <div className="icon-w" data-tip={t(item.title)}>
                      <div className={`os-icon os-icon-${item.icon}`}></div>
                    </div>
                    {/* if menu items area is greater than 8 if we are showing their icons only */}
                    {i18n.language == 'en' ? (listWidth < 1400 ? <div className={`text-${i18n.language}`}>
                      <span id='t1'>{t(item.title)}{t(item.title) == "Fleet" ? <strong style={{ marginLeft: "5px" }} className="badge badge-danger">{driverCount > 0 ? driverCount : ""}</strong> : ""}</span>
                    </div> : null) : (listWidth < 1500 ? <div className={`text-${i18n.language}`}>
                      <span id='t1'>{t(item.title)}{t(item.title) == "Fleet" ? <strong style={{ marginLeft: "5px" }} className="badge badge-danger">{driverCount > 0 ? driverCount : ""}</strong> : ""}</span>
                    </div> : null)}
                    <ReactTooltip place="left" />
                  </Link>
                  <div className="sub-menu-w">
                    <div className="sub-menu-header">${item.title}</div>
                    <div className="sub-menu-icon">
                      <i className={`os-icon os-icon-${item.icon}`}></i>
                    </div>
                    <div className="sub-menu-i">
                      {item.submenu?.map((submenu, i) =>
                        <ul key={i} className="sub-menu">
                          {submenu.map(subitem => (
                            showSubItem(subitem) && (
                              <li key={subitem.link} className={subitem.selected ? 'selected' : ''}>
                                <Link to={subitem.link} onClick={() => toggle(item, 'selected', true)}>
                                  {t(subitem.title)}
                                  {subitem.badge && <strong className="badge badge-danger">{subitem.badge}</strong>}
                                  {t(subitem.title) === "Drivers" ? <strong style={{ marginLeft: "5px" }} className="badge badge-danger">{driverCount > 0 ? driverCount : ""}</strong> : ""}
                                </Link>
                              </li>
                            )
                          )
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                </li>
              )
            ))}
          </ul>}
        {/* <div className="input-group" style={{flexWrap:"nowrap"}}> */}
        {/* <div className="form-outline"> */}
        {/* <input type="search" value={search} onChange={Search} id="form1" placeholder=" Search Anything...." className="form-control" /> */}
        {/* <label className="form-label" >Search</label> */}
        {/* </div> */}
        {/* <button onClick={getSearchData} type="button" className="btn btn-primary"> */}
        {/* <i className="fas fa-search"></i> */}
        {/* </button> */}
        {/* </div> */}
        <div className="row r9" style={{ marginRight: "10px", width: "14rem" }}>
          <Select
            placeholder={t('entities.search')}
            // backspaceRemovesValue={  }
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              zIndex: 5,
              colors: {
                ...theme.colors,
                primary25: '#1b55e2',
                primary: 'white',
                neutral0: "#293145",
                // neutral90:"red",
                neutral80: "white",
                neutral20: "#1b55e2",
                neutral50: "white",
              },
            })}
            isClearable
            isSearchable
            // hideSelectedOptions={ multiple }
            // value={ selectedOption }
            isDisabled={false}
            isLoading={false}
            // isMulti={ multiple }
            options={options}
            // closeMenuOnSelect={false }
            escapeClearsValue={true}
            // autoFocus={ autoFocus }
            noOptionsMessage={() => "No items to show"}
            // tabIndex={ tabIndex?.toString() }
            onChange={(value) => selectedContent(value as any)}
            onInputChange={(e) => Search(e)}
          />
        </div>
      </div>
    </div>
  );
}

export const Header = withRouter(HeaderComponent);
