import { GoogleMap } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormInput } from "../../../components/form/input/input.component";
import { ContentWrapper, ViewContent } from "../../../components/view/content.component";
import { ViewContentItem, ViewContentItems } from "../../../components/view/item.component";
import { Address } from "../../../entities/address.entity";
import { Booking, BookingStatus } from "../../../entities/booking.entity";
import { BookingAudit } from "../../../entities/bookingAudit.entity";
import { Driver, Gender } from "../../../entities/driver.entity";
import { GPS } from "../../../entities/gps.entity";
import { Passenger } from "../../../entities/passenger.entity";
import { Session } from "../../../entities/session.entity";
import { DiscountType, Trip, TripStatus } from "../../../entities/trip.entity";
import { Vehicle } from "../../../entities/vehicle.entity";
import { Zone } from "../../../entities/zone.entity";
import car from "../../../img/CAR.png";
import pointA from '../../../img/Point_A.png';
import pointB from '../../../img/Point_B.png';
import greyPointA from '../../../img/greyPointA.png';
import greyPointB from '../../../img/greyPointB.png';
import FemaleImg from "../../../img/female.png";
import MaleImg from "../../../img/male.png";
import { Api, Endpoint } from "../../../services/api.service";
import { getCurrencyFormatting, getDateFormatting, getGeneralTimeFormat, getGeneralZoneDistanceTitle, getGeneralZoneDistanceValue } from "../../../util/distance";
import { getStorageUrl } from "../../../util/file.util";
import { ProfilePicSize } from "../../driver/list/list.component";
import { Device } from "../../../entities/device.entity";
import { ReportTable } from "../../../components/table/report.component"
import { AuthService } from "../../../services/auth.service";
import { Currency } from "../../../entities/currency.entity";
import { Settings } from "../../../entities/settings.entity";
import { SettingsService } from "../../../services/settings.service";
import { toSubUnit } from "../../../util/currency";
import { decodePolyLine } from "../../../util/decodePolyline";

export interface ShiftSummaryProps {
  session: Session
  timezone?: string
  toSubUnit?: any
  sessionPaymentData?: any
  lastSeen: Date

}

export interface ShiftTripsProps {
  trips: Trip[]
  timezone?: string
}
export interface ShiftTableProps {
  session: Session
  timezone?: string
  toSubUnit?: any
  sessionPaymentData?: any
  tripCountData: any
  completeSessionData: any

}

export interface ShiftPaymentProps {
  session: Session
  timezone?: string
  sessionPaymentData: any
  toSubUnit: any
}

type MapRender =
  { type: "Destination", pickupAddress: Address | null, dropOffAddress: Address | null } |
  { type: "Ongoing", driverId: string, gps: any } |
  { type: "Completed", gps: any, pickup: Address | null, dropOff: Address | null } |
  { type: "SessionPath", gps: any, pickup: Address | null, dropOff: Address | null } |

  { type: "None", setUserLocation: boolean } |
  { type: "Clear" } |
  { type: "Vehicle", latitude: number, longitude: number, heading: number }


let code = "";
let recenterNeeded = false;
let mapCenter: google.maps.LatLng | undefined = undefined;
let carMarker: google.maps.Marker | undefined = undefined;
let trackLine: google.maps.Polyline | undefined = undefined;
let markerPickup: google.maps.Marker | undefined = undefined;
let markerDropOff: google.maps.Marker | undefined = undefined;
let trackMap: google.maps.Map<HTMLElement> | undefined = undefined;
let trackMapBounds: google.maps.LatLngBounds | undefined = undefined;
let trackRenderer: google.maps.DirectionsRenderer | undefined = undefined;
let sessionCode: string;
let vehicleId: any;

const BLUE = "#047bf8";
const GREY = "#808080";
const ORANGE = "#FFA500"
export function TrackShift(props: any) {
  const formProps = useFormContext();
  const [inputCode, setInputCode] = useState<string>();
  const [plate, setPlate] = useState<string>();
  const [shiftCode, setShiftCode] = useState<string>();
  const [driverCode, setDriverCode] = useState<string>();
  const [trips,setTrips] = useState<Trip[]>()
  const [booking, setBooking] = useState<Booking>();
  const [session, setSession] = useState<Session | undefined>();
  const [sessionPaymentCCData, setSessionPaymentCCData] = useState<any>();
  const [sessionPaymentCashData, setSessionPaymentCashData] = useState<any>();
  const [bookedTripsByCash, setBookedTripsByCash] = useState<any>();
  const [bookedTripsByCC, setBookedTripsByCC] = useState<any>();


  const [passenger, setPassenger] = useState<Passenger>();
  const [bookingAudit, setBookingAudit] = useState<BookingAudit[]>([]);
  const [trip, setTrip] = useState<Trip>();
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [driver, setDriver] = useState<Driver | undefined>();
  const [symbol, setSymbol] = useState<string>();
  const [subUnit, setSubUnit] = useState<number>(100);
  const [generalTimezone, setGeneralTimezone] = useState<string>();
  const { t } = useTranslation('main');
  const history = useHistory();
  const params: any = useParams();
  const [zone, setZone] = useState<Zone>()
  const [currency, setCurrency] = useState<Currency>()
  const [tripCount, setTripCount] = useState();
  const [completeSessionResponse, setCompleteSessionResponse] = useState<any>()
  const [lastSeen, setLastSeen] = useState<Date>()


  async function getDirections(pickup: google.maps.LatLng, dropoff: google.maps.LatLng) {
    return new Promise<google.maps.DirectionsResult>((resolve, reject) => {
      const directionService = new google.maps.DirectionsService();
      directionService.route({ destination: dropoff, origin: pickup, travelMode: google.maps.TravelMode.DRIVING }, (result, status) => {
        const { DirectionsStatus } = google.maps;
        switch (status) {
          case DirectionsStatus.INVALID_REQUEST:
            reject("Invalid Maps Request");
            break;
          case DirectionsStatus.MAX_WAYPOINTS_EXCEEDED:
            reject("Unable to process to many way points");
            break;
          case DirectionsStatus.NOT_FOUND:
            reject("Unable to process provided locations, please check if locations exists");
            break;
          case DirectionsStatus.OK:
            resolve(result);
            break;
          case DirectionsStatus.OVER_QUERY_LIMIT:
            reject("Too many requests within the allowed time period");
            break;
          case DirectionsStatus.REQUEST_DENIED:
            reject("You are not allowed to use the directions service");
            break;
          case DirectionsStatus.UNKNOWN_ERROR:
            reject("Directions request could not be processed due to a server error. The request may succeed if you try again.")
            break;
          case DirectionsStatus.ZERO_RESULTS:
            reject('No route could be found between the origin and destination.');
            break;
        }
      });
    });
  }
  function showToast(message: string) {
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  async function renderMap(data: MapRender) {
    function update(this: { driver: string }, location: any) {
      console.log("location update = ", { driver: this.driver, location });
      if (location.driverId == this.driver) {
        const position = new google.maps.LatLng(location.latitude, location.longitude);
        carMarker && carMarker.setPosition(position);
        trackLine && trackLine.getPath().push(position);
        trackMapBounds && trackMapBounds.extend(position);
        !recenterNeeded && trackMap && trackMap.fitBounds(trackMapBounds!);
        !recenterNeeded && trackMap && trackMap.setHeading(location.heading);
        mapCenter = position;
      }


      // using last seen by vehicle id 
      if (location.vehicleId == vehicleId) {
        setLastSeen(location.createTime)
      }

    }
    switch (data.type) {
      case "Destination":
        if (data.pickupAddress && data.dropOffAddress) {
          try {
            const directions = await getDirections(
              new google.maps.LatLng(data.pickupAddress.lat, data.pickupAddress.lng),
              new google.maps.LatLng(data.dropOffAddress.lat, data.dropOffAddress.lng)
            );

            const route = directions.routes[0].bounds;
            trackRenderer = new google.maps.DirectionsRenderer({ map: trackMap! });
            trackRenderer.setDirections(directions);
            trackMap!.fitBounds(route, 10);
            mapCenter = trackMap!.getCenter();
          }
          catch (e: any) {
            showToast(e.message || e);
          }
        }
        else if (data.pickupAddress) {
          const point = new google.maps.LatLng(data.pickupAddress.lat, data.pickupAddress.lng)
          markerPickup = new google.maps.Marker({
            icon: pointA,
            position: point,
            map: trackMap
          });
          const bounds = new google.maps.LatLngBounds(point);
          trackMap!.fitBounds(bounds);
          mapCenter = trackMap!.getCenter();
          showToast('Dropoff Address was not provided with this booking!');
        }
        else { showToast('Pickup and Dropoff Address were not provided'); renderMap({ type: "None", setUserLocation: true }); return; }
        break;
      case "Ongoing":
        if (trackMap) {
          trackMapBounds = new google.maps.LatLngBounds();
          const pathTravelled = data.gps.map((item: any) => {
            const point = new google.maps.LatLng(item[0], item[1]); trackMapBounds!.extend(point); return point;
          });

          let position = undefined;
          if (data.gps?.length > 0) {
            const last = data.gps[data.gps.length - 1];
            position = new google.maps.LatLng(last[0], last[1]);
            mapCenter = position;
          }
          carMarker = new google.maps.Marker({ icon: car, map: trackMap, position: position });
          trackLine = new google.maps.Polyline({ strokeColor: ORANGE, strokeWeight: 2, map: trackMap, path: pathTravelled });
          trackMap!.fitBounds(trackMapBounds);

          Api.on('gps_latest_location', update.bind({ driver: data.driverId }));
          Api.post(Endpoint.DASHBOARD_TRACK_SESSION_GPS, { drivers: [data.driverId] });
        }
        break;
      case "Completed":

        Api.off('gps_latest_location', update);
        Api.delete(Endpoint.DASHBOARD_TRACK_SESSION_GPS, { passenger: '' });
        if (data.gps?.length > 0) {

          markerPickup = new google.maps.Marker({
            icon: pointA, position: new google.maps.LatLng(data.gps[0][0], data.gps[0][1]), map: trackMap!
          });

          const lastIndex = data.gps.length - 1;
          markerDropOff = new google.maps.Marker({
            icon: pointB, position: new google.maps.LatLng(data.gps[lastIndex][0], data.gps[lastIndex][1]), map: trackMap!
          });
          trackMapBounds = new google.maps.LatLngBounds();
          const positions = data.gps.map((item: any) => {
            const point = new google.maps.LatLng(item[0], item[1])
            trackMapBounds!.extend(point);
            return point;
          });

          trackLine = new google.maps.Polyline({
            path: positions, strokeColor: BLUE, strokeWeight: 2,
            draggable: false, clickable: false, editable: false, visible: true, map: trackMap!
          });

          trackMap!.fitBounds(trackMapBounds);
          mapCenter = trackMap!.getCenter();
        }
        else {
          showToast("Not enough gps points for completed route!");

        }
        break;

      case "SessionPath":
        Api.off('gps_latest_location', update);
        Api.delete(Endpoint.DASHBOARD_TRACK_SESSION_GPS, { passenger: '' });
        if (data.gps && data.gps?.length > 0) {
          markerPickup = new google.maps.Marker({
            icon: greyPointA, position: new google.maps.LatLng(parseFloat(data.gps[0][0]), parseFloat(data.gps[0][1])), map: trackMap!
          });

          const lastIndex = data.gps.length - 1;
          markerDropOff = new google.maps.Marker({
            icon: greyPointB, position: new google.maps.LatLng(parseFloat(data.gps[lastIndex][0]), parseFloat(data.gps[lastIndex][1])), map: trackMap!
          });

          console.log("markup", markerDropOff, markerPickup, data.gps[0][0])
          trackMapBounds = new google.maps.LatLngBounds();
          const positions = data.gps.map((item: any) => {
            const point = new google.maps.LatLng(item[0], item[1])
            trackMapBounds!.extend(point);
            return point;
          });

          trackLine = new google.maps.Polyline({
            path: positions, strokeColor: GREY, strokeWeight: 2,
            draggable: false, clickable: false, editable: false, visible: true, map: trackMap!
          });

          trackMap!.fitBounds(trackMapBounds);
          mapCenter = trackMap!.getCenter();
        }
        else {
          showToast("Not enough gps points for session route!");

        }
        break;

      case "Vehicle":
        carMarker = new google.maps.Marker({
          icon: car, position: new google.maps.LatLng(data.latitude, data.longitude), map: trackMap!
        });

        mapCenter = carMarker.getPosition()!;
        trackMap!.setCenter(carMarker.getPosition()!);
        trackMap!.setZoom(16);
        break;
      case "Clear":
        if (mapCenter) { mapCenter = undefined; }
        if (trackMapBounds) { trackMapBounds = undefined; }
        if (trackLine) { trackLine.setMap(null); trackLine = undefined; }
        if (carMarker) { carMarker.setMap(null); carMarker = undefined; }
        if (markerPickup) { markerPickup.setMap(null); markerPickup = undefined; }
        if (markerDropOff) { markerDropOff.setMap(null); markerDropOff = undefined; }
        if (trackRenderer) { trackRenderer.setMap(null); trackRenderer = undefined; }
        break;
      default:
        if (mapCenter) { mapCenter = undefined };
        if (trackMapBounds) { trackMapBounds = undefined; }
        if (trackLine) { trackLine.setMap(null); trackLine = undefined; }
        if (carMarker) { carMarker.setMap(null); carMarker = undefined; }
        if (markerPickup) { markerPickup.setMap(null); markerPickup = undefined; }
        if (markerDropOff) { markerDropOff.setMap(null); markerDropOff = undefined; }
        if (trackRenderer) { trackRenderer.setMap(null); trackRenderer = undefined; }

        setBooking(undefined); setBookingAudit([]);
        setPassenger(undefined); setTrip(undefined); setDriver(undefined);
        setVehicle(undefined); setSubUnit(100); setSymbol(undefined);

        Api.off('gps_latest_location', update);
        Api.delete(Endpoint.DASHBOARD_TRACK_SESSION_GPS, { passenger: '' });

        if (data.setUserLocation) navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
          mapCenter = new google.maps.LatLng(latitude, longitude);
          trackMap!.setOptions({
            center: mapCenter,
            zoom: 16
          });
        }, (err) => {
          showToast(err.message);
        });
    }
  }


  async function onCodeReceived(code: string) {
    try {
      if ((/[A-Z]{3}-\d{4}/g).test(code) == false) {
        throw new Error('Invalid Code Pattern, Your code can only use the format ABC-0000');
      }

      // renderMap({ type: "None", setUserLocation: true });
      trackMap = new google.maps.Map(document.getElementById('googleMaps@tracker')!);
      const response = await Api.get<any, any>(Endpoint.GPS_SHIFT, { sessionCode: code });
      console.log("on shift code submit", response)
      sessionCode = response.session.code
      vehicleId = (response.session.vehicle as Vehicle).id
      // decode Session PolyLine
      const decodedSessionPolyline = decodePolyLine(response.sessionPolyLine)
      // creating polyline for session route
      renderMap({ type: "SessionPath", gps: decodedSessionPolyline || [], pickup: null, dropOff: null })

      setDriverCode(undefined)
      setSession(response.session)
      setSessionPaymentCCData(response.sessionPaymentCCData)
      setSessionPaymentCashData(response.sessionPaymentCashData)
      setCompleteSessionResponse(response)
      setTrips(response.trips)


      //for all trips
      await response.trips.map((trip: any) => {
        if (trip.gpsArchive) {
          if ((trip.status == TripStatus.Enroute) || (trip.status == TripStatus.Metering) || (trip.status == TripStatus.Arrived) || (trip.status == TripStatus.DriverEnroute)) {
            renderMap({ type: "Ongoing", driverId: response.session.driver.id, gps: decodePolyLine(trip.gpsArchive) || [] })
          }
          else {
            renderMap({ type: "Completed", gps: decodePolyLine(trip.gpsArchive) || [], pickup: null, dropOff: null })
          }
        }
      })


      return true;
    }
    catch (err: any) {
      console.log("Err while getting track response = ", err);
      showToast(err.message || err);
      renderMap({ type: "None", setUserLocation: true });
      return false;
    }
  }
  function createRecenterButton(remove: Function) {
    const div = document.createElement("div");
    div.setAttribute("style", "margin: 10px; height: 40px; width: 40px; justify-content: center; align-items: center; border-radius: 2px; box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;");

    const button = document.createElement("button");
    button.setAttribute("style", "width: 100%; height: 100%; border-radius: 2px; border: none; background-color: white");

    const image = document.createElement("img");
    image.setAttribute("src", "https://cdn.iconscout.com/icon/free/png-256/compass-2451562-2082565.png");
    image.setAttribute("style", "width: 100%; height: 100%; object-fit: contain");

    button.onclick = function () {
      if (mapCenter) {
        trackMap!.setCenter(mapCenter);
        remove();
      }
    }
    button.append(image);
    div.append(button);
    return div;
  }

  useEffect(() => {
    // set zone for currency and general timezone
    getZone()
    trackMap = new google.maps.Map(document.getElementById('googleMaps@tracker')!);
    google.maps.event.addListenerOnce(trackMap, "idle", async function () {
      console.log("Map Loaded and is IDLE");
      trackMap!.addListener("dragend", () => {
        if (recenterNeeded == false) {
          recenterNeeded = true;
          trackMap!.controls[google.maps.ControlPosition.RIGHT_CENTER].push(createRecenterButton(() => {
            trackMap!.controls[google.maps.ControlPosition.RIGHT_CENTER].pop();
            recenterNeeded = false;
          }));
        }
      });
      code = params.code;
      if (code && code !== "") { if (await onCodeReceived(code)) setShiftCode(code) }
      else showToast('No code found to track, please enter valid code');
    });
    Api.on('session_update', (data) => {
      if (sessionCode == data.code) {
        onCodeReceived(data.code)
        trackMap = new google.maps.Map(document.getElementById('googleMaps@tracker')!);
      }
    }
    );

    Api.on('trip_update', (data) => {
      console.log("trip update", data)
      const { session } = data
      if (session.code == sessionCode) {

        setTimeout(() => {
          onCodeReceived(sessionCode)
        }, 3000);
      }
    });

    return () => {
      // BTListener.bind({ code: "", type: "Both" })(false);
      markerPickup = undefined; trackMap = undefined; trackRenderer = undefined;
      trackLine = undefined; trackMapBounds = undefined;
    }

  }, []);




  async function getZone() {
    const zone = await Api.get<any, any>(Endpoint.ZONE_SELECTED, {})
    setGeneralTimezone(zone.generalTimeZone)
    setZone(zone)
    setCurrency(zone.generalCurrency)
  }

  

  const onCodeSubmit = async () => {
    try {
      if (driverCode) {
        if (driverCode == code && typeof symbol !== "undefined") {
          showToast('Showing details for the same code!');
        }
        else {

          trackMap = new google.maps.Map(document.getElementById('googleMaps@tracker')!);
          const response = await Api.get<any, any>(Endpoint.GPS_THROUGH_DRIVER_CODE, { driverCode: driverCode });
          sessionCode = response.session.code
          vehicleId = (response.session.vehicle as Vehicle).id
          console.log("on shift code submit", response)
          // decode Session PolyLine
          const decodedSessionPolyline = decodePolyLine(response.sessionPolyLine)
          // creating polyline for session route
          renderMap({ type: "SessionPath", gps: decodedSessionPolyline || [], pickup: null, dropOff: null })
          setShiftCode(undefined);
          setSessionPaymentCCData(response.sessionPaymentCCData)
          setSessionPaymentCashData(response.sessionPaymentCashData)

          setCompleteSessionResponse(response)
          response.trips.map((trip: any) => {
            if (trip.gpsArchive && trip.gpsArchive?.length > 0) {
              if ((trip.status == TripStatus.Enroute) || (trip.status == TripStatus.Metering) || (trip.status == TripStatus.Arrived) || (trip.status == TripStatus.DriverEnroute)) {
                renderMap({ type: "Ongoing", driverId: response.session.driver.id, gps: decodePolyLine(trip.gpsArchive) || [] })
              }
              else {
                renderMap({ type: "Completed", gps: decodePolyLine(trip.gpsArchive) || [], pickup: null, dropOff: null })
              }
            }
          })
          setSession(response.session)

        }
      }
    }
    catch (error: any) {
      console.log("error in onCodeSubmit", error)
      showToast("CANNOT FIND ANY ACTIVE SHIFT");
      setSession(undefined)
    }
  };
  const onShiftCodeSubmit = async () => {
    if (shiftCode) {
      try {

        trackMap = new google.maps.Map(document.getElementById('googleMaps@tracker')!);
        const response = await Api.get<any, any>(Endpoint.GPS_SHIFT, { sessionCode: shiftCode });
        console.log("on shift code submit", response)
        sessionCode = response.session.code
        vehicleId = (response.session.vehicle as Vehicle).id
        setDriverCode(undefined)
        setSession(response.session)
        setSessionPaymentCCData(response.sessionPaymentCCData)
        setSessionPaymentCashData(response.sessionPaymentCashData)
        setCompleteSessionResponse(response)
        setTrips(response.trips)

        // decode Session PolyLine
        const decodedSessionPolyline = decodePolyLine(response.sessionPolyLine)
        // creating polyline for session route
        renderMap({ type: "SessionPath", gps: decodedSessionPolyline || [], pickup: null, dropOff: null })
        response.trips.map((trip: any) => {
          if (trip.gpsArchive && trip.gpsArchive?.length > 0) {
            if ((trip.status == TripStatus.Enroute) || (trip.status == TripStatus.Metering) || (trip.status == TripStatus.Arrived) || (trip.status == TripStatus.DriverEnroute)) {
              renderMap({ type: "Ongoing", driverId: response.session.driver.id, gps: decodePolyLine(trip.gpsArchive) || [] })
            }
            else {
              renderMap({ type: "Completed", gps: decodePolyLine(trip.gpsArchive) || [], pickup: null, dropOff: null })
            }
          }
        })

      }
      catch (err: any) {
        showToast(err.message || err);
        setSession(undefined)
      }

    }
    else showToast('Please add shift code to track!');

  }

  return (
    <ContentWrapper className="container-fluid">
      <div className="row">
        <FormInput
          label={""}
          name="Track"
          type="text"
          className="col-sm-6 mb-0 ml-3"
          tabIndex={10}
          value={plate}
          placeholder={t('mainMenu.track.enterShiftCode')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setShiftCode(e.target.value) }}
          {...formProps}
        />
        <button
          style={{ height: "100%", marginTop: "23px", padding: "8px" }}
          className="btn btn-primary text-bold col-sm-1"
          onClick={onShiftCodeSubmit}
        >
          {t('mainMenu.track.track')}

        </button>
        <FormInput
          label={""}
          name="code"
          type="text"
          className="col-sm-6 ml-3"
          tabIndex={12}
          value={inputCode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setDriverCode(e.target.value) }}
          placeholder={t('mainMenu.track.enterDriverCode')}
          {...formProps}
        />
        <button
          style={{ height: "100%", marginTop: "23px", padding: "8px" }}
          className="btn btn-primary text-bold col-sm-1"
          onClick={onCodeSubmit}
        >
          {t('mainMenu.track.track')}
        </button>
      </div>
      <div className="row">
        <div className="col-md-8">
          <GoogleMap
            id="googleMaps@tracker"
            mapContainerStyle={{
              marginLeft: 20,
              height: 645,
              width: "98%",
              padding: 8,
              marginBottom: 10,
            }}
          ></GoogleMap>

        </div>
        <div className="col-md-4">



          {session ? <ShiftSummary lastSeen={lastSeen as Date} toSubUnit={toSubUnit} session={session as Session} timezone={generalTimezone as string} /> : ""}
        </div>
      </div>

      <div style={{ marginTop: "20px" }}>

        {session && completeSessionResponse && <ShiftTable completeSessionData={completeSessionResponse} tripCountData={tripCount} sessionPaymentData={{ sessionPaymentCCData, sessionPaymentCashData }} session={session} timezone={generalTimezone} toSubUnit={toSubUnit} />}

      </div>

      <div className="row" style={{ marginTop: "20px" }}>
        <div className="col-md-6">
          {session ? <ShiftPaymentDetails toSubUnit={toSubUnit} sessionPaymentData={{ sessionPaymentCCData, sessionPaymentCashData }} session={session as Session} timezone={generalTimezone as string} /> : ""}

        </div>
        <div className="col-md-6">
          {console.log("trips",trips)}
          {trips && trips.length > 0 ? <ShiftTrips trips={trips} timezone={generalTimezone as string} /> : ""}

        </div>

      </div>

      <ToastContainer theme="dark" />
    </ContentWrapper>
  );
}

const ShiftSummary = ({ session, timezone, lastSeen }: ShiftSummaryProps) => {

  return (
    <React.Fragment>
      <ViewContent title="Summary">
        <ViewContentItems>
          <ViewContentItem title="Code">{session.code}</ViewContentItem>
          <ViewContentItem title="Status">{session.endTime ?
            <span className="badge badge-danger">Ended</span>
            :
            <span className="badge badge-success">Active</span>
          }</ViewContentItem>
          <ViewContentItem title="Driver">{(session.driver as Driver).code}</ViewContentItem>
          <ViewContentItem title="Vehicle">{(session.vehicle as Vehicle).plate}</ViewContentItem>
          <ViewContentItem title="Device">{(session.device as Device).code}</ViewContentItem>
          <ViewContentItem title="Start Time">{getDateFormatting(new Date(session.createTime), timezone)}</ViewContentItem>
          <ViewContentItem title="End Time">{session.endTime ? getDateFormatting(new Date(session?.endTime as Date), timezone) : ""}</ViewContentItem>
          <ViewContentItem title="Shift time">{session.sessionTotalTime == null ? `${new Date(0 * 1000)?.toISOString().substr(11, 8)}` : `${new Date(session.sessionTotalTime * 1000)?.toISOString().substr(11, 8)}`}</ViewContentItem>
          {lastSeen ? <ViewContentItem title="Last Seen">{getDateFormatting(new Date(lastSeen as Date), timezone)}</ViewContentItem> : ""}
        </ViewContentItems>
      </ViewContent>
    </React.Fragment>
  )

}


const ShiftPaymentDetails = ({ session, timezone, sessionPaymentData, toSubUnit }: ShiftPaymentProps) => {
  const { sessionPaymentCCData, sessionPaymentCashData } = sessionPaymentData

  function sumValues(values: any) {
    const result = values.reduce((accumulator: any, current: any) => accumulator + current);
    console.log("result", result, values)
    return result
  }

  return (
    <React.Fragment>
      <ViewContent title="Shift Payment Details">
        <ViewContentItems>
          <ViewContentItem title="Amount">{toSubUnit(parseInt(session.totalNet_Revenue || 0),session.zone.generalCurrency)}</ViewContentItem>
          <ViewContentItem title="Status">{session.paymentStatus}</ViewContentItem>
          <ViewContentItem title="Application Fee">
            {sessionPaymentCCData?.length > 0 ?
              <>
                Booking Charges CC &nbsp; + &nbsp; Levy CC &nbsp; + &nbsp;  CC Charge
                <br></br>
                {toSubUnit(parseInt(sessionPaymentCCData[0].totalbookingcharge || 0),session.zone.generalCurrency)} &nbsp; + &nbsp; {toSubUnit(parseInt(sessionPaymentCCData[0].totallevy || 0),session.zone.generalCurrency)} &nbsp; + &nbsp;  {toSubUnit(parseInt(sessionPaymentCCData[0].totalcccharge || 0),session.zone.generalCurrency)}
                <br></br>
                {toSubUnit(sumValues([parseInt(sessionPaymentCCData[0].totalbookingcharge) || 0, parseInt(sessionPaymentCCData[0].totallevy) || 0, parseInt(sessionPaymentCCData[0].totalcccharge) || 0]),session.zone.generalCurrency)}
              </>
              : toSubUnit(0,session.zone.generalCurrency)

            }


          </ViewContentItem>
          <ViewContentItem title="Commission">
            {sessionPaymentCashData?.length > 0 ?
              <>
                Booking Charges Cash &nbsp; + &nbsp; Levy Cash &nbsp;
                <br></br>
                {toSubUnit(parseInt(sessionPaymentCashData[0].totalbookingcharge || 0),session.zone.generalCurrency)} &nbsp; + &nbsp; {toSubUnit(parseInt(sessionPaymentCashData[0].totallevy || 0),session.zone.generalCurrency)} &nbsp;
                <br></br>
                {toSubUnit(sumValues([parseInt(sessionPaymentCashData[0].totalbookingcharge || 0), parseInt(sessionPaymentCashData[0].totallevy || 0)]),session.zone.generalCurrency)}
              </>
              : toSubUnit(0,session.zone.generalCurrency)

            }

          </ViewContentItem>

        </ViewContentItems>
      </ViewContent>
    </React.Fragment>
  )

}

const ShiftTable = ({ session, timezone, toSubUnit, completeSessionData }: ShiftTableProps) => {
  // const {sessionPaymentCCData, sessionPaymentCashData} = sessionPaymentData  
  const { sessionPaymentCCData, sessionPaymentCashData, dispatchTripCount, AppTripCount, QRTripCount, hailCashTripCount, hailCCTripCount } = completeSessionData
console.log("sessionPaymentCashData",sessionPaymentCashData)
  function sumValues(values: any) {
    const result = values.reduce((accumulator: any, current: any) => accumulator + current);
    return result
  }


  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: "20px" }}>
        <div className="col-md-3">

          <ViewContent title="Shift Details">
            <ViewContentItems>

              {/* <ViewContentItem title="Shift End Time">{dateFormat(session?.endTime) || 0}</ViewContentItem> */}
              <ViewContentItem title="QR Connected Trips">{QRTripCount || 0}</ViewContentItem>
              <ViewContentItem title="App Booked Trips">
                {AppTripCount || 0}


              </ViewContentItem>
              <ViewContentItem title="Dispatch Booked Trips">
                {dispatchTripCount || 0}

              </ViewContentItem>

              <ViewContentItem title="Hail Trips paid by Cash">
                {hailCashTripCount || 0}
              </ViewContentItem>
              <ViewContentItem title="Hail Trips paid by CC">
                {hailCCTripCount || 0}

              </ViewContentItem>

              <ViewContentItem title="Booked Trips paid by Cash:">
                {completeSessionData ? parseInt(completeSessionData.BookedTripsByCash || 0) : 0}

              </ViewContentItem>

              <ViewContentItem title="Booked Trips paid by CC">
                {completeSessionData? parseInt(completeSessionData.BookedTripsByCC || 0) : "0"}

              </ViewContentItem>

              <ViewContentItem title="Booking Charges Cash:">
                {sessionPaymentCashData?.length > 0 ? toSubUnit(parseInt(sessionPaymentCashData[0].totalbookingcharge || 0),session.zone.generalCurrency) : toSubUnit(0,session.zone.generalCurrency)}

              </ViewContentItem>

              <ViewContentItem title="CC Commission">
                {sessionPaymentCCData?.length > 0 ?
                  <>
                    Booking Charges CC &nbsp; + &nbsp; Levy CC &nbsp; + &nbsp;  CC Charge
                    <br></br>
                    {toSubUnit(parseInt(sessionPaymentCCData[0].totalbookingcharge || 0),session.zone.generalCurrency)} &nbsp; + &nbsp; {toSubUnit(parseInt(sessionPaymentCCData[0].totallevy || 0),session.zone.generalCurrency)} &nbsp; + &nbsp;  {toSubUnit(parseInt(sessionPaymentCCData[0].totalcccharge || 0),session.zone.generalCurrency)}
                    <br></br>
                    {toSubUnit(sumValues([parseInt(sessionPaymentCCData[0].totalbookingcharge) || 0, parseInt(sessionPaymentCCData[0].totallevy) || 0, parseInt(sessionPaymentCCData[0].totalcccharge) || 0]),session.zone.generalCurrency)}
                  </>
                  : toSubUnit(0,session.zone.generalCurrency)

                }


              </ViewContentItem>

            </ViewContentItems>
          </ViewContent>
        </div>
        <div className="col-md-3">
          <ViewContent title="Shift Details">
            <ViewContentItems>

              <ViewContentItem title="Total Tips">{session?.totalCharges_Tip == null
                ? toSubUnit(0 ,session.zone.generalCurrency)
                : `${toSubUnit(session.totalCharges_Tip,session.zone.generalCurrency)}`}</ViewContentItem>
              <ViewContentItem title="Credit Card Payments">{session?.totalCharges_CC == null
                ? toSubUnit(0 ,session.zone.generalCurrency)
                : `${toSubUnit(session.totalCharges_CC,session.zone.generalCurrency)}`}</ViewContentItem>
              <ViewContentItem title="Transaction Fee Deduction">
                {session?.totalTransaction_Fee_Deduction == null
                  ? toSubUnit(0,session.zone.generalCurrency)
                  : `${toSubUnit(
                    session.totalTransaction_Fee_Deduction
                  ,session.zone.generalCurrency)}`}


              </ViewContentItem>
              <ViewContentItem title="Tolls">
                {session?.totalCharges_Tolls == null
                  ? toSubUnit(0,session.zone.generalCurrency)
                  : `${toSubUnit(session.totalCharges_Tolls,session.zone.generalCurrency)}`}

              </ViewContentItem>

              <ViewContentItem title="Extras">
                {session?.totalCharges_Extras == null
                  ? toSubUnit(0,session.zone.generalCurrency)
                  : `${toSubUnit(session.totalCharges_Extras,session.zone.generalCurrency)} `}
              </ViewContentItem>
              <ViewContentItem title="Fares">
                {session?.totalCharges_Fares == null
                  ? toSubUnit(0,session.zone.generalCurrency)
                  : `${toSubUnit(session.totalCharges_Fares,session.zone.generalCurrency)}`}

              </ViewContentItem>

              <ViewContentItem title="Fuel">
                {session?.fuel == null && session.endTime == null
                  ? toSubUnit(0,session.zone.generalCurrency)
                  : `${toSubUnit(session.fuel,session.zone.generalCurrency)}`}

              </ViewContentItem>

              <ViewContentItem title="Wash">
                {session?.wash == null && session.endTime == null
                  ? toSubUnit(0,session.zone.generalCurrency)
                  : `${toSubUnit(session.wash,session.zone.generalCurrency)}`}

              </ViewContentItem>

              <ViewContentItem title="Vacant Tolls">
                {session?.vacantTolls == null && session.endTime == null
                  ? toSubUnit(0,session.zone.generalCurrency)
                  : `${toSubUnit(session.vacantTolls,session.zone.generalCurrency)}`}

              </ViewContentItem>


            </ViewContentItems>
          </ViewContent>
        </div>
        <div className="col-md-3">
          <ViewContent title="Shift Details">
            <ViewContentItems>

              <ViewContentItem title="Booking Charges CC">{sessionPaymentCCData?.length > 0 ? toSubUnit(parseInt(sessionPaymentCCData[0].totalbookingcharge || 0),session.zone.generalCurrency) : toSubUnit(0,session.zone.generalCurrency)}</ViewContentItem>
              <ViewContentItem title="Levy CC">{sessionPaymentCCData?.length > 0 ? toSubUnit(parseInt(sessionPaymentCCData[0].totallevy || 0),session.zone.generalCurrency) : toSubUnit(0,session.zone.generalCurrency)}</ViewContentItem>
              <ViewContentItem title="CC Charge">
                {sessionPaymentCCData?.length > 0 ? toSubUnit(parseInt(sessionPaymentCCData[0].totalcccharge || 0),session.zone.generalCurrency) : toSubUnit(0,session.zone.generalCurrency)}


              </ViewContentItem>
              <ViewContentItem title="Passenger Levy Deduction">
                {session?.totalPassenger_Levy_Deduction == null
                  ? toSubUnit(0,session.zone.generalCurrency)
                  : `${toSubUnit(session.totalPassenger_Levy_Deduction,session.zone.generalCurrency)}`}

              </ViewContentItem>

              <ViewContentItem title="Total Booking Charge">
                {session?.totalBooking_Fee_Deduction == null
                  ? toSubUnit(0,session.zone.generalCurrency)
                  : `${toSubUnit(session.totalBooking_Fee_Deduction,session.zone.generalCurrency)}`}
              </ViewContentItem>
              <ViewContentItem title="Net Bank Payment">

                <>
                  Total CC &nbsp; - &nbsp; CC Fee &nbsp; - &nbsp; Total Booking Charge &nbsp;  - &nbsp; Levy &nbsp;
                  <br></br>
                  <br></br>
                  {toSubUnit(session.totalCharges_CC || 0,session.zone.generalCurrency)}&nbsp; - &nbsp;{toSubUnit(parseInt(sessionPaymentCCData[0]?.totalcccharge || 0),session.zone.generalCurrency)} &nbsp; - &nbsp;
                {session?.totalBooking_Fee_Deduction == null
                  ? toSubUnit(0,session.zone.generalCurrency)
                  : `${toSubUnit(session.totalBooking_Fee_Deduction,session.zone.generalCurrency)}`} &nbsp;  - &nbsp;{toSubUnit(session.totalPassenger_Levy_Deduction,session.zone.generalCurrency)} &nbsp;
                  <br></br>
                  <br></br>

                  {session?.totalNet_Bank == null
                    ? toSubUnit(0,session.zone.generalCurrency)
                    : `${toSubUnit(session.totalNet_Bank,session.zone.generalCurrency)}`}
                </>



              </ViewContentItem>



              <ViewContentItem title="Cash Payments">
                {session?.totalCharges_Cash == null
                  ? toSubUnit(0,session.zone.generalCurrency)
                  : `${toSubUnit(session.totalCharges_Cash,session.zone.generalCurrency)}`}

              </ViewContentItem>

              <ViewContentItem title="Cash Commission">
                {sessionPaymentCashData?.length > 0 ?
                  <>
                    Booking Charges Cash &nbsp; + &nbsp; Levy Cash &nbsp;
                    <br></br>
                    {toSubUnit(parseInt(sessionPaymentCashData[0].totalbookingcharge || 0),session.zone.generalCurrency)} &nbsp; + &nbsp; {toSubUnit(parseInt(sessionPaymentCashData[0].totallevy || 0),session.zone.generalCurrency)} &nbsp;
                    <br></br>
                    {toSubUnit(sumValues([parseInt(sessionPaymentCashData[0].totalbookingcharge || 0), parseInt(sessionPaymentCashData[0].totallevy || 0)]),session.zone.generalCurrency)}
                  </>
                  : toSubUnit(0,session.zone.generalCurrency)

                }


              </ViewContentItem>

            </ViewContentItems>
          </ViewContent>
        </div>


        <div className="col-md-3">
          <ViewContent title="Shift Details">
            <ViewContentItems>

              <ViewContentItem title="Total Offline Time">{session?.offlineMinutes == null ? `${new Date(0 * 1000)?.toISOString().substr(11, 8)}` : `${new Date(session?.offlineMinutes * 1000)?.toISOString().substr(11, 8)}`}</ViewContentItem>
              <ViewContentItem title="Credit Card Payments">{session?.totalCharges_CC == null

                ? toSubUnit(0,session.zone.generalCurrency)
                : `${toSubUnit(session.totalCharges_CC, session.zone.generalCurrency)}`}</ViewContentItem>
              <ViewContentItem title="Total Travelled Distance">
                {session?.totalRideDistance == null
                  ? `${(0 as number).toFixed(3)} ` + `${getGeneralZoneDistanceTitle()}`
                  : `${(session?.totalRideDistance / getGeneralZoneDistanceValue()).toFixed(3)} ${getGeneralZoneDistanceTitle()}`}


              </ViewContentItem>
              <ViewContentItem title="Total Trip Distance">
                {session?.distance == null
                  ? `${(0 as number).toFixed(3)} ` + `${getGeneralZoneDistanceTitle()}`
                  : `${(session?.distance / (getGeneralZoneDistanceValue())).toFixed(3)} ${getGeneralZoneDistanceTitle()}`}
              </ViewContentItem>

              <ViewContentItem title="Booking Counts">
                {session.bookingCount || 0}
              </ViewContentItem>
              <ViewContentItem title="Hail Counts">
                {session.tripCount || 0}

              </ViewContentItem>

              <ViewContentItem title="Trip Counts">
                {(session.tripCount || 0) + (session.bookingCount || 0)}
              </ViewContentItem>

              <ViewContentItem title="Net Revenue">

                <>
                  Total Fares &nbsp; - &nbsp; Tolls &nbsp; - &nbsp; Extras &nbsp;  - &nbsp; Tips &nbsp; - &nbsp; Fuel &nbsp; - &nbsp; Wash &nbsp; - &nbsp; Vacant Tolls &nbsp;  - &nbsp;CC Fee &nbsp;  - &nbsp;Booking &nbsp;  - &nbsp; Levy &nbsp;
                  <br></br>
                  <br></br>
                  {toSubUnit(session.totalCharges_Fares || 0,session.zone.generalCurrency)}&nbsp; - &nbsp;  {toSubUnit(session.totalCharges_Tolls || 0,session.zone.generalCurrency)} &nbsp; - &nbsp; {toSubUnit(session.totalCharges_Extras || 0,session.zone.generalCurrency)} &nbsp;  - &nbsp; {toSubUnit(session.totalCharges_Tip || 0,session.zone.generalCurrency)} &nbsp; - &nbsp; {toSubUnit(session.fuel || 0,session.zone.generalCurrency)} &nbsp; - &nbsp; {toSubUnit(session.wash || 0,session.zone.generalCurrency)} &nbsp; - &nbsp; {toSubUnit(session.vacantTolls || 0,session.zone.generalCurrency)} &nbsp;  - &nbsp;{toSubUnit(parseInt(sessionPaymentCCData[0]?.totalcccharge || 0),session.zone.generalCurrency)} &nbsp;  - &nbsp;{toSubUnit(parseInt(sessionPaymentCCData[0]?.totalbookingcharge || 0),session.zone.generalCurrency)} &nbsp;  - &nbsp;{toSubUnit(session.totalPassenger_Levy_Deduction,session.zone.generalCurrency)} &nbsp;
                  <br></br>
                  <br></br>

                  {(session?.totalNet_Revenue == null)
                    ? toSubUnit(0,session.zone.generalCurrency)
                    : `${toSubUnit(session.totalNet_Revenue,session.zone.generalCurrency)}`}
                </>




              </ViewContentItem>

            </ViewContentItems>
          </ViewContent>
        </div>
      </div>




    </React.Fragment>
  )

}

const ShiftTrips = ({trips,timezone}:ShiftTripsProps) =>{
  return(
    <div style={{ marginTop: "10px" }}>
                  <ViewContent title="Shift Trips">
                    <div
                      style={{
                        maxHeight: "300px",
                        overflowY: "scroll",
                        overflowX: "hidden",
                      }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Trip Code</th>
                            <th scope="col">Start Time</th>
                            
                          </tr>
                        </thead>
                        {trips
                          ? trips?.map((trip: any, i: number) => {
                            return (
                              <tbody key={i}>
                                <tr>
                                 
                                  <td>
                                    <span className="badge badge-success">
                                      {trip.code}
                                    </span>
                                  </td>
                                  
                                  <td>
                                    <span>
                                      {trip.startTime && getDateFormatting(new Date(trip.startTime), timezone)}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })
                          : ""}
                      </table>
                    </div>
                  </ViewContent>
                </div>
  )
}