import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormSection } from "../../../../components/form/section.component";
import { Settings } from "../../../../entities/settings.entity";

interface Props {
  settings:Settings|undefined
}

export function DriverPostLicense({settings}:Props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  
  return (
    <FormSection
      title={ t("driver.licenseSection.title") }
      subtitle={ t("driver.licenseSection.subTitle") }
    >
      <div className="row">
        <FormInput
          label={ t("driver.licenseSection.licenseNumber") }
          name="licenseNumber"
          type="text"
          validation={{required:settings?.driverRequirementLicenseNumber?true:false}}
          className="col-md-4 col-sm-12"
          tabIndex={0}
          { ...formProps }
        />
        <FormInput
          label={ t("driver.licenseSection.licenseExpiration") }
          name="licenseExpiryDate"
          type="date"
          validation={{required:settings?.driverRequirementLicenseExpiryDate?true:false}}
          className="col-md-4 col-sm-12"
          tabIndex={0}
          { ...formProps }
        />
      </div>
    </FormSection>
  );

}
