import { Autocomplete, DirectionsRenderer, GoogleMap, Marker, TrafficLayer } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { add, formatDuration, intervalToDuration } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import Modal from 'react-responsive-modal';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { ViewContent } from '../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../components/view/item.component';
import { Address } from '../../entities/address.entity';
import { Area } from '../../entities/area.entity';
import { Booking, BookingRate, BookingStatus, BookingType } from '../../entities/booking.entity';
import { Currency } from '../../entities/currency.entity';
import { Driver } from '../../entities/driver.entity';
import { Feature } from '../../entities/feature.entity';
import { Group } from '../../entities/group.entity';
import { Passenger } from '../../entities/passenger.entity';
import { Service } from '../../entities/service.entity';
import { Zone } from '../../entities/zone.entity';
import car from '../../img/car.svg';
import { IPaginationOptions, IPaginationResponse } from '../../interfaces/paginate.interface';
import { Api, ApiType, Endpoint } from '../../services/api.service';
import { getGeneralZoneDistanceTitle, getGeneralZoneDistanceValue } from '../../util/distance';
import { EstimateFareRequest, FindDriverInAreaRequest } from './dto/request';
const MAP_LIBS: Libraries = ['places'];

let directionsService: google.maps.DirectionsService;
let serviceId: string | undefined = undefined;
let findInAreaInterval: NodeJS.Timeout | undefined = undefined;
let gpsRecent: any;
interface Gps { GpsNearBy: Area[] }
export const Dispatch = (props: any) => {
    let history = useHistory();
    let pickupAutocompleteRef = useRef<Autocomplete>(null);
    let dropoffAutocompleteRef = useRef<Autocomplete>(null);
    const [dropOffMarker, setDropOffMarker] = useState<boolean>(false);
    const [pickupMarker, setPickupMarker] = useState<boolean>(false);
    const [generalCurrency, setGeneralCurrency] = useState<Currency>();
    const [markerPickup, setMarkerPickup] = useState<google.maps.Marker>()
    const [markerDropOff, setMarkerDropOff] = useState<google.maps.Marker>()
    const [pickupPlaceFormatted, setPickupPlaceFormatted] = useState<Address>();
    const [dropoffPlaceFormatted, setDropoffPlaceFormatted] = useState<Address>();
    const [center, setCenter] = useState<google.maps.LatLng>(new google.maps.LatLng(-33.8688, 151.2093));
    const [zoom, setZoom] = useState(10);
    const [map, setMap] = useState<google.maps.Map>()
    const [pickupPlace, setPickupPlace] = useState<google.maps.places.PlaceResult>();
    const [dropoffPlace, setDropoffPlace] = useState<google.maps.places.PlaceResult>();
    const [directions, setDirections] = useState<google.maps.DirectionsResult>();
    const [features, setFeatures] = useState<any[]>();
    const [distance, setDistance] = useState<number>();
    const [duration, setDuration] = useState<number>();
    const [trafficDuration, setTrafficDuration] = useState<number>();
    const [estimate, setEstimate] = useState<number>(0);
    const [serviceOptions, setServiceOptions] = useState<any[]>([]);
    const [featureOptions, setFeatureOptions] = useState<any[]>([]);
    const [findArea, setFindArea] = useState<Gps>();
    const [service, setService] = useState<any>();
    const [point, setPoint] = useState<google.maps.Point>();
    const [size, setSize] = useState<google.maps.Size>();
    const [sendData, setSendData] = useState<boolean>(true);
    const [pickupLatLng, setPickupLatLng] = useState<google.maps.LatLng>();
    const [dropoffLatLng, setDropoffLatLng] = useState<google.maps.LatLng>();
    const [pickupText, setPickupText] = useState<string>();
    const [DropoffText, setDropoffText] = useState<string>();
    const [phone, setPhone] = useState<string>('')
    const [firstName, setFirstName] = useState<string>()
    const [instructions, setInstructions] = useState<string>()
    const [lastName, setLastName] = useState<string>()
    const [passengerId, setPassengerId] = useState<string>()
    const [disabled, setDisabled] = useState<boolean>()
    const [passenger, setPassenger] = useState<Passenger>()
    const [zoneCurrent, setZoneCurrent] = useState<Zone>()
    const [recentAddress, setRecentAddress] = useState<any>();
    const [preferredGroup, setPrefferedGroup] = useState<Group>();
    const [passengerGroups, setPassengerGroups] = useState<Group[]>();
    const inputRef = useRef(null);
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const [modalData, setModalData] = useState();
    const { t } = useTranslation('main')
    const transalationForms = useTranslation('forms')
    const onLoad = React.useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map)
        setTimeout(() => {
            // Sydney
            // const DEFAULT_CENTER = new google.maps.LatLng(-33.8688, 151.2093);
            const DEFAULT_POINT = new google.maps.Point(32, 32);
            const DEFAULT_SIZE = new google.maps.Size(64, 64);
            // setCenter(DEFAULT_CENTER);
            setPoint(DEFAULT_POINT)
            setSize(DEFAULT_SIZE)
            setZoom(12);
        }, 100);

        directionsService = new google.maps.DirectionsService();
        pickupAutocompleteRef.current?.state.autocomplete?.addListener('place_changed', () => onAddressSelect('pickup'));
        dropoffAutocompleteRef.current?.state.autocomplete?.addListener('place_changed', () => onAddressSelect('dropoff'));
    }, []);
    const onUnmount = React.useCallback(() => {
        setMap(undefined)
    }, []);
    const success = (position: any) => {
        const currentPosition = { lat: position.coords.latitude, lng: position.coords.longitude }
        const d = new google.maps.LatLng(currentPosition.lat, currentPosition.lng)
        setCenter(d);
    };
    const onSelectingRecentAddress = async (item: any, type: 'dropoff' | 'pickup') => {

        new google.maps.places.PlacesService(map as google.maps.Map).findPlaceFromQuery({
            query: item.address.text,
            fields: ['all']
        }, (result) => {
            const place = result[0]
            console.log("result 101", place)
            if (type == 'pickup') {
                setPickupText(item.address.text)
                findInArea(place);
                getZoneByLocation(place);
                setPickupPlaceFormatted({
                    lat: place.geometry?.location.lat() as number,
                    lng: place.geometry?.location.lng() as number,
                    text: place.formatted_address || "",
                    // area: area.long_name, 
                    placeId: place.place_id
                });
                setPickupPlace(place)
                setPickupMarker(false);
                setPickupText(place.formatted_address);
            }
            else {
                setDropoffText(item.address.text)
                setDropoffPlaceFormatted({
                    lat: place.geometry?.location.lat() as number,
                    lng: place.geometry?.location.lng() as number,
                    text: place.formatted_address || "",
                    // area: area.long_name, 
                    placeId: place.place_id
                });
                setDropoffPlace(place)
                setDropOffMarker(false)
                setDropoffText(place.formatted_address);
            }


        })






    }
    const onAddressSelect = async (type: 'dropoff' | 'pickup') => {
        const ref = type === 'dropoff' ? dropoffAutocompleteRef : pickupAutocompleteRef;
        console.log("pickupref", pickupAutocompleteRef)
        const areaTypes = ['locality', 'sublocality_level_1', 'sublocality', 'political'];
        const autocomplete: google.maps.places.Autocomplete | null | undefined | any = ref.current?.state.autocomplete;
        console.log("autocomplete data", autocomplete.getPlace())
        if (!autocomplete) { return; }
        var city, state, country;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        const area = await autocomplete.getPlace().address_components.find((component: any) => {
            for (let areaType of areaTypes) {
                if (component.types.includes(areaType)) {
                    return true;
                }
            }
            return false;
        });

        autocomplete.getPlace().address_components?.map((component: any) => {
            if ((component.types.indexOf("locality") > -1)) {
                city = component.long_name;
            }

            if ((component.types.indexOf("postal_town") > -1)) {
                city = component.long_name;
            }

            if (component.types.indexOf("administrative_area_level_1") > -1) {
                state = component.short_name;
            }

            if (component.types.indexOf("country") > -1) {
                country = component.long_name;
            }
        });

        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        if (!place.geometry) { alert('Insufficient Address Information...'); return; }
        if (type == "dropoff") {
            console.log("DROPOFF SELECTED = ", place.formatted_address);
            setDropoffPlaceFormatted({
                lat: place.geometry.location.lat(), lng: place.geometry.location.lng(),
                text: place.formatted_address || "", area: area.long_name, placeId: "",
                city: city,
                state,
                country
            });
            setDropoffPlace(place)
            setDropOffMarker(false)
            setDropoffText(place.formatted_address);
        }
        else {
            console.log("PICKUP SELECTED = ", place.formatted_address);
            findInArea(place);
            getZoneByLocation(place);
            setPickupPlaceFormatted({
                lat: place.geometry?.location.lat(), lng: place.geometry?.location.lng(),
                text: place.formatted_address || "", area: area.long_name, placeId: "",
                city: city,
                state,
                country
            });
            setPickupPlace(place)
            setPickupMarker(false);
            setPickupText(place.formatted_address);
        }
    }
    const findInArea = async (place: google.maps.places.PlaceResult) => {
        try {
            const findRequest = async () => {
                if (!place.geometry) return;
                const gpsData = {
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng(),
                    serviceId: serviceId!
                }
                const response = await Api.get<Gps, FindDriverInAreaRequest>(Endpoint.DRIVER_FIND_AREA, gpsData);
                console.log("findInArea response",response)
                setFindArea(response);
                gpsRecent = response
                let drivers: any = [];
                response.GpsNearBy?.map((item) => {

                    drivers.push(item.driver.id)

                })
                const subscribeGps: any = await Api.post<any, any>(Endpoint.DASHBOARD_TRACK_SESSION_GPS, { drivers });
                setSendData(false);

            }
            findRequest();
            // findInAreaInterval = setInterval(findRequest, 30000);
        }
        catch (err) {
            console.log("FIND IN AREA FAILING DUE TO = ", err);
        }
    }
    const handleLatest = async (payload: any) => {
        console.log("Before Inside payload: ", payload, gpsRecent);
        if (gpsRecent?.GpsNearBy.length > 0) {
            var foundIndex = gpsRecent?.GpsNearBy?.findIndex((x: any) => (x.driver.id == payload.driverId && x.vehicle.id == payload.vehicleId));
            const drivers: any = gpsRecent.GpsNearBy.filter((y: any) => y.driver.id !== payload.driverId && y.vehicle.id !== payload.vehicleId);
            const GpsNearBy: any = [
                ...drivers,
                {
                    driver: gpsRecent.GpsNearBy[foundIndex].driver,
                    vehicle: gpsRecent.GpsNearBy[foundIndex].vehicle,
                    lat: payload.latitude,
                    lng: payload.longitude,
                    heading: payload.heading

                }

            ];

            // setFindArea(areaData)
            gpsRecent = { GpsNearBy: GpsNearBy }
            setFindArea((prev) => {
                const newValue = gpsRecent
                return newValue
            })
        }
    }
    useEffect(() => {
        Api.on("gps_latest_location", handleLatest);
        return () => {
            Api.off('gps_latest_location', handleLatest);
            const subscribeGps: any = Api.delete<any, any>(Endpoint.DASHBOARD_TRACK_SESSION_GPS, { passenger: `` });
        }
    }, []);
    const refresh = async () => {
        try {
            const data = await Api.get<Service[], IPaginationOptions>(Endpoint.SERVICE_PUBLIC_LIST, {}, ApiType.Rest);
            const { items } = await Api.get<IPaginationResponse<Feature>, IPaginationOptions>(Endpoint.FEATURE_LIST);
            console.log('Feature List: ', items);
            const featureList = items.map((item) => { return { value: item.id, label: item.title, } })
            const serviceList = data.map((item) => { return { value: item.id, label: item.title, } })
            setFeatureOptions(featureList);
            setServiceOptions(serviceList);
            for (let i = 0; i < data.length; i++) {
                if (data[i].isDefault == true) {
                    serviceId = data[i].id;
                    setService({ value: data[i].id, label: data[i].title })
                }
            }
        } catch (err) {
            console.log('Failed to load Service list', err);
        }
    }
    const onFeatureChange = (e: any) => {
        console.log('Feature Values: ', e)
        setFeatures(e);
    }
    const loadEstimate = async () => {
        console.log('Traffic Duration:', trafficDuration)
        console.log('lat:', pickupPlace?.geometry?.location.lat());
        console.log('lng:', pickupPlace?.geometry?.location.lng());
        console.log('distance:', distance);
        console.log('duration:', duration);
        console.log('serviceId:', serviceId);
        const response: any = await Api.post<number | undefined, EstimateFareRequest>(Endpoint.FARE_ESTIMATE, {
            lat: pickupMarker === true ? pickupLatLng?.lat() : pickupPlace?.geometry?.location.lat(),
            lng: pickupMarker === true ? pickupLatLng?.lng() : pickupPlace?.geometry?.location.lng(),
            distance: distance,
            duration: duration,
            traffic: trafficDuration,
            serviceId: serviceId,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }, ApiType.Rest);

        console.log('Estimate: ', response)
        setEstimate(response);
    }
    useEffect(() => {
        if (!distance || !duration || !serviceId === undefined) {
            console.log(`incomplete ${pickupPlace} ${distance} ${duration} ${trafficDuration} ${serviceId}`)
            return;
        }
        // console.log('pickupPlace:', pickupPlace.geometry?.location.lat().toString())
        console.log('complete')
        console.log('serviceeeee', service);
        loadEstimate();
    }, [pickupPlace, distance, duration, trafficDuration, serviceId, service]);
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success);
        refresh();
        return () => {
            if (findInAreaInterval) clearInterval(findInAreaInterval);
            findInAreaInterval = undefined;
        }
    }, []);
    const onServiceChange = (e: any) => {
        console.log('Service Values: ', e)
        setService(e || undefined);
        serviceId = e?.value || undefined;
    }
    const getZoneByLocation = async (pickupLocation: google.maps.places.PlaceResult) => {
        try {
            if (!pickupLocation.geometry) return;
            setPickupText(pickupLocation.formatted_address)
            const data = await Api.post<Zone, { lat: number, lng: number }>(Endpoint.FARE_GET_ZONE, {
                lat: pickupLocation.geometry.location.lat(),
                lng: pickupLocation.geometry.location.lng()
            });
            console.log('Zone Data:', data);
            if (!data?.generalCurrency) { alert("Out Of Zone") }
            else {
                setGeneralCurrency(data.generalCurrency);
                setZoneCurrent(data)
            }
        }
        catch (err) {
            console.log('Error Inside Zone:', err);
        }
    }

    useEffect(() => {
        if (!pickupPlace || !dropoffPlace) {
            if (pickupPlace && pickupPlace.geometry) {
                setCenter(pickupPlace.geometry.location);
                setZoom(18);
            }
            if (dropoffPlace && dropoffPlace.geometry) {
                setCenter(dropoffPlace.geometry.location);
                setZoom(18);
            }
        }
        // Set departure time to 10 minues from now
        const departureTime = add(new Date(), { minutes: 10, hours: 5 });
        if (pickupPlace?.geometry?.location || dropoffPlace?.geometry?.location || pickupMarker || dropOffMarker)
            directionsService?.route({
                origin: pickupMarker === true ? pickupLatLng : pickupPlace?.geometry?.location,
                destination: dropOffMarker === true ? dropoffLatLng : dropoffPlace?.geometry?.location,
                travelMode: google.maps.TravelMode.DRIVING,
                durationInTraffic: true,
                drivingOptions: { departureTime }
            }, (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                    const route = result.routes[0].legs[0];
                    if (!route) { return }
                    setDistance(route.distance.value);
                    setDuration(route.duration_in_traffic ? route.duration_in_traffic.value : route.duration.value);
                    const trafficTime = route.duration_in_traffic ? route.duration_in_traffic.value - route.duration.value : 0;
                    console.log('traffice Time,', trafficTime);
                    if (trafficTime > 0) setTrafficDuration(trafficTime);
                    else setTrafficDuration(0);
                }
            });
    }, [pickupPlace, dropoffPlace, serviceId, features, pickupMarker, dropOffMarker, pickupLatLng, dropoffLatLng]);

    const distanceText = (): string => {
        if (!distance) {
            return 'N/A';
        }
        return `${roundTo(distance / getGeneralZoneDistanceValue())} ` + `${getGeneralZoneDistanceTitle()}`;
    }
    const durationText = (): string => {
        if (!duration) {
            return 'N/A';
        }
        const epoch = new Date(0)
        const secondsAfterEpoch = new Date(duration * 1000)
        const durationObj = intervalToDuration({
            start: epoch,
            end: secondsAfterEpoch
        })
        return formatDuration(durationObj, { format: ['hours', 'minutes'] });
    }
    const trafficText = (): string => {
        if (!trafficDuration) {
            return 'N/A';
        }
        const epoch = new Date(0)
        const secondsAfterEpoch = new Date(trafficDuration * 1000)
        const durationObj = intervalToDuration({
            start: epoch,
            end: secondsAfterEpoch
        })
        return formatDuration(durationObj, { format: ['hours', 'minutes'] });
    }
    const estimateText = (): string => {
        console.log('final estimate: ', estimate)
        if (!estimate || isNaN(estimate)) {
            return '...';
        }
        return estimate.toFixed(2).toString();
    }
    const roundTo = (val: number): number => {
        return Math.round((val + Number.EPSILON) * 100) / 100
    }
    const onHandleClick = async (e: any) => {
        console.log(e);
        e.preventDefault();
        if (!serviceId) { alert('Please Select Service First!'); return; }
        if (!zoneCurrent) { alert('Unable to find zone, Please make sure you selected correct location'); return; }
        console.log('pax', passengerId);
        const feature = features?.map((item) => item.value);
        try {
            let passengerIdData = passengerId || await onCreatePassenger();
            console.log("Passenger Id = ", passengerIdData);
            if (!passengerIdData) { alert('Please check your passenger details.'); return; }
            console.log({
                pickupMarker, pickupLatLng, pickupPlaceFormatted, zoneCurrent: !!zoneCurrent
            });
            //@ts-ignore
            const pickupAddress: Address = !(pickupMarker && pickupLatLng)
                ? {
                    lat: pickupPlaceFormatted?.lat, lng: pickupPlaceFormatted?.lng, text: pickupPlaceFormatted?.text, area: pickupPlaceFormatted?.area, city: pickupPlaceFormatted?.city,
                    state: pickupPlaceFormatted?.state,
                    country: pickupPlaceFormatted?.country

                }
                : {
                    lat: pickupLatLng?.lat(), lng: pickupLatLng?.lng(), text: pickupText, area: pickupPlaceFormatted?.area, city: pickupPlaceFormatted?.city,
                    state: pickupPlaceFormatted?.state,
                    country: pickupPlaceFormatted?.country
                }
            //@ts-ignore
            const dropoffAddress: Address = !(dropOffMarker && dropoffLatLng)
                ? {
                    lat: dropoffPlaceFormatted?.lat, lng: dropoffPlaceFormatted?.lng, text: dropoffPlaceFormatted?.text, area: dropoffPlaceFormatted?.area, city: dropoffPlaceFormatted?.city,
                    state: dropoffPlaceFormatted?.state,
                    country: dropoffPlaceFormatted?.country
                }
                : {
                    lat: dropoffLatLng.lat(), lng: dropoffLatLng.lng(), text: DropoffText || "", area: dropoffPlaceFormatted?.area, city: dropoffPlaceFormatted?.city,
                    state: dropoffPlaceFormatted?.state,
                    country: dropoffPlaceFormatted?.country
                };
            console.log({
                pickupAddress, dropoffAddress, event: "post:booking"
            });
            const estimateValue = estimate * ((generalCurrency as Currency).subunits)
            console.log("estimate values",estimateValue,estimate,generalCurrency)
            //@ts-ignore

            const data = await Api.post<Booking, Booking>(Endpoint.Booking, {
                peerId: '123e4567-e89b-12d3-a456-426614174000',
                 distance: distance || 0, duration: durationText(),
                estimate: estimateValue, dropOffAddress: dropoffAddress, pickupAddress: pickupAddress,   passenger: passengerIdData, service: serviceId,
                features: feature as any, status: BookingStatus.Pickup, type: BookingType.Dispatch, rate: BookingRate.Meter,
                zone: (zoneCurrent as Zone).id as string, preferredGroup: preferredGroup ? preferredGroup.id : undefined,
                instruction: instructions
            });
            history.push(`/security/track/${data.code}`);
            setPassengerId(undefined);
            return undefined;
        } catch (error) {
            alert('Something went wrong.')
            console.log('booking error:', error);
            return undefined;
        }
    }
    const onClick = (e: any) => {
        placePickupMarker(e.latLng, map);
    }
    const onDblClick = (e: any) => {
        placeDropoffMarker(e.latLng, map);
    }
    const codeLatLngDropOff = (lat: number, lng: number) => {
        let city: any;
        var latlng = new google.maps.LatLng(lat, lng);
        setDropoffLatLng(latlng)
        new google.maps.Geocoder().geocode({ location: latlng }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                console.log(results)
                if (results[1]) {
                    //formatted address
                    // alert(results[0].formatted_address)
                    setDropoffText(results[0].formatted_address)
                    //find country name
                    for (var i = 0; i < results[0].address_components.length; i++) {
                        for (var b = 0; b < results[0].address_components[i].types.length; b++) {

                            //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
                            if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
                                //this is the object you are looking for
                                city = results[0].address_components[i];
                                break;
                            }
                        }
                    }
                }
            } else {
                alert("Geocoder failed due to: " + status);
            }
        });
    }
    const codeLatLngPickup = (lat: number, lng: number) => {
        let city: any;
        var latlng = new google.maps.LatLng(lat, lng);
        setPickupLatLng(latlng)
        new google.maps.Geocoder().geocode({ location: latlng }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                console.log(results)
                if (results[1]) {
                    setPickupText(results[0].formatted_address)
                    for (var i = 0; i < results[0].address_components.length; i++) {
                        for (var b = 0; b < results[0].address_components[i].types.length; b++) {
                            if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
                                city = results[0].address_components[i];
                                break;
                            }
                        }
                    }
                }
            } else {
                alert("Geocoder failed due to: " + status);
            }
        });
        setPickupText(city)
    }
    const placePickupMarker = (location: any, map: google.maps.Map<Element> | undefined) => {
        if (pickupMarker === false) {
            setMarkerPickup(new google.maps.Marker({
                position: location,
                map: map
            }));
            map?.panTo(location)
            setPickupMarker(true)
            codeLatLngPickup(location.lat(), location.lng());
        }
    }
    const placeDropoffMarker = (location: any, map: google.maps.Map<Element> | undefined) => {
        if (dropOffMarker === false) {
            setMarkerDropOff(new google.maps.Marker({
                position: location,
                map: map
            }));
            map?.panTo(location)
            setDropOffMarker(true)
            codeLatLngDropOff(location.lat(), location.lng())
        }
    }
    const getByPhoneNumber = useCallback(async () => {
        try {
            if (phone.length > 6) {
                const { passenger, passengerRecentAddresses } = await Api.get<{ passenger: Passenger, passengerRecentAddresses: Address }, { phoneNumber: string | undefined }>(Endpoint.PASSENGER_BY_PHONE_NUMBER, { phoneNumber: phone })
                console.log("PASSENGER FOUND = ", passenger, passengerRecentAddresses);
                setFirstName(passenger.firstName);
                setLastName(passenger.lastName);
                setPassengerId(passenger.id);
                setPassengerGroups(passenger.groups.map((group) => Object.assign(group, { label: group.title })));
                setRecentAddress(passengerRecentAddresses);
                setDisabled(true);
            }
        }
        catch (Err) {
            setDisabled(false);
            setPassenger(undefined);
            console.log('Passenger Not exist');
        }
    }, [phone, disabled, passenger])

    useEffect(() => {
        getByPhoneNumber()
    }, [getByPhoneNumber]);

    const onCreatePassenger = async () => {
        try {
            if (firstName && lastName && phone) {
                const response = await Api.post<Passenger, any>(Endpoint.PASSENGER, { firstName, lastName, phone })
                console.log('Inside Create Passenger', response)
                if (response) {
                    setPassengerId(response.id)
                    return response.id
                }
            }
            return undefined;
        } catch (error) {
            console.log('Passenger Creation Error:', error)
        }
    }

    const handleModal = (type: "pickup" | "dropoff") => {
        let list: any;
        switch (type) {
            case "pickup":
                list = <ul className="gradient-list">
                    {recentAddress && recentAddress.map((item: any) => <li ><a href="#" onClick={() => { onCloseModal(); onSelectingRecentAddress(item, 'pickup') }}>{item.address.text}</a></li>)}
                </ul>
                break
            case "dropoff":
                list = <ul className="gradient-list">
                    {
                        recentAddress && recentAddress.map((item: any) => <li ><a href="#" onClick={() => { onCloseModal(); onSelectingRecentAddress(item, 'dropoff') }}>{item.address.text}</a></li>)
                    }
                </ul>
                break
        }
        onOpenModal()
        setModalData(list)

    }
    return (
        <div className="content-box">
            <div className="row" style={{ marginTop: 5 }}>
                <div className="col-md-4">
                    <ViewContent title={'Booking'}>
                        <ViewContentItems >
                            <div className="row" style={{ marginBottom: '-100px' }}>
                                <div className="col-md-12 form-group" style={{ marginBottom: '0' }} >
                                    <form id="register-form" className="register-form register" method='post' onSubmit={onHandleClick}>
                                        <ViewContentItem title={transalationForms.t('common.phone')}>
                                            <PhoneInput
                                                value={phone}
                                                onChange={setPhone}
                                                placeholder="Awaiting incoming calls"
                                                id="phone"
                                            />
                                            {/* <input
                                                    autoFocus
                                                    className="form-control"
                                                    value={phone}
                                                    onChange={(e: any) => { setPhone(e.target.value) }}
                                                    required
                                                    placeholder="Awaiting incoming calls"
                                                    type="text"
                                                /> */}
                                        </ViewContentItem>
                                        <ViewContentItem title={transalationForms.t('common.firstName')}>
                                            <input
                                                id="firstName"
                                                className="form-control"
                                                value={firstName}
                                                onChange={(e: any) => { setFirstName(e.target.value) }}
                                                required
                                                placeholder={transalationForms.t('common.firstName')}
                                                type="text"
                                            />
                                        </ViewContentItem>
                                        <ViewContentItem title={transalationForms.t('common.lastName')}>
                                            <input
                                                id="lastName"
                                                className="form-control"
                                                value={lastName}
                                                onChange={(e: any) => { setLastName(e.target.value) }}
                                                required
                                                placeholder={transalationForms.t('common.lastName')}
                                                type="text"
                                            />
                                        </ViewContentItem>
                                        {recentAddress ? <ViewContentItem className={"labelInline"} title={t("Recent Pickup Address")}>
                                            <i style={{ marginLeft: "10px" }} onClick={() => handleModal('pickup')} className="os-icon os-icon-plus-circle"></i>
                                        </ViewContentItem> : ""}
                                        <ViewContentItem title={`${t('mainMenu.dispatch.pickupPlace')}`}>
                                            <Autocomplete
                                                ref={pickupAutocompleteRef}
                                                fields={['geometry', "address_component", "formatted_address"]}
                                            >
                                                <input ref={inputRef} className="form-control pick1" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }} value={pickupText} onChange={(e: any) => { setPickupText(e.target.value) }} required placeholder={t('mainMenu.dispatch.pickupPlace')} type="text" />
                                            </Autocomplete>

                                        </ViewContentItem>
                                        {recentAddress ? <ViewContentItem className={"labelInline"} title={t("Recent Dropoff Address")}>
                                            <i style={{ marginLeft: "10px" }} onClick={() => handleModal('dropoff')} className="os-icon os-icon-plus-circle"></i>
                                        </ViewContentItem> : ""}
                                        <ViewContentItem title={`${t('mainMenu.dispatch.dropoffPlace')}`}>
                                            <Autocomplete
                                                ref={dropoffAutocompleteRef}
                                                fields={['geometry', "address_component", "formatted_address"]}
                                            >
                                                <input className="form-control" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }} value={DropoffText} onChange={(e: any) => { setDropoffText(e.target.value) }} placeholder={t('mainMenu.dispatch.dropoffPlace')} type="text" />
                                            </Autocomplete>
                                        </ViewContentItem>


                                        <Modal open={open} onClose={onCloseModal} center>
                                            <h2 style={{ marginTop: '20px', textAlign: "center" }}>{t("Choose Your Address")}</h2>
                                            {modalData}
                                        </Modal>
                                        
                                        {passengerGroups && (
                                            <ViewContentItem title={transalationForms.t('dispatch.preferredGroup')}>
                                                <Select
                                                    placeholder={transalationForms.t('dispatch.preferredGroup')}
                                                    backspaceRemovesValue={true}
                                                    isClearable
                                                    styles={{
                                                        control: styles => ({
                                                            ...styles,
                                                        }),
                                                        option: styles => ({
                                                            ...styles,
                                                        }),
                                                        container: styles => ({
                                                            ...styles,
                                                            width: 'auto'
                                                        })
                                                    }}
                                                    isSearchable
                                                    hideSelectedOptions={true}
                                                    value={preferredGroup}
                                                    options={passengerGroups}
                                                    closeMenuOnSelect={!false}
                                                    escapeClearsValue={false}
                                                    onChange={(value) => setPrefferedGroup(value as any)}
                                                />
                                            </ViewContentItem>
                                        )}

                                        <ViewContentItem title={transalationForms.t('common.service')}>
                                            <Select
                                                placeholder={transalationForms.t('common.service')}
                                                backspaceRemovesValue={true}
                                                isClearable
                                                styles={{
                                                    control: styles => ({
                                                        ...styles,
                                                    }),
                                                    option: styles => ({
                                                        ...styles,
                                                    }),
                                                    container: styles => ({
                                                        ...styles,
                                                        width: 'auto'
                                                    })
                                                }}
                                                isSearchable
                                                hideSelectedOptions={true}
                                                value={service}
                                                options={serviceOptions}
                                                closeMenuOnSelect={!false}
                                                escapeClearsValue={false}
                                                onChange={(value) => onServiceChange(value as any)}
                                            />
                                        </ViewContentItem>
                                        <ViewContentItem title={transalationForms.t('common.features')}>
                                            <Select
                                                placeholder={transalationForms.t('common.features')}
                                                backspaceRemovesValue={true}
                                                isClearable
                                                styles={{
                                                    control: styles => ({
                                                        ...styles,
                                                    }),
                                                    option: styles => ({
                                                        ...styles,
                                                    }),
                                                    container: styles => ({
                                                        ...styles,
                                                        width: 'auto'
                                                    })
                                                }}
                                                isSearchable
                                                hideSelectedOptions={true}
                                                value={features}
                                                isMulti={true}
                                                options={featureOptions}
                                                closeMenuOnSelect={!true}
                                                escapeClearsValue={false}
                                                onChange={(value) => onFeatureChange(value as any)}
                                            />
                                        </ViewContentItem>
                                        <ViewContentItem title={transalationForms.t('common.specialInstruction')}>
                                            <input
                                                id="Special Instructions"
                                                className="form-control"
                                                value={instructions}
                                                onChange={(e: any) => { setInstructions(e.target.value) }}
                                                placeholder={transalationForms.t('common.specialInstruction')}
                                                type="text"
                                            />
                                        </ViewContentItem>
                                        <ViewContentItem title={''}>
                                            <div style={{ textAlign: 'center' }}>
                                                <button type="submit" style={{ background: 'black', color: 'white', margin: '10px', width: '160px', height: '40px', textAlign: 'center' }} className="btn btn-primary-outline">
                                                    {t('mainMenu.dashboard.bookings')}
                                                </button>
                                                <div style={{ background: 'red', color: 'white', width: '160px', height: '40px', textAlign: 'center', paddingTop: '10px' }} className="btn btn-primary-outline">
                                                    {t('mainMenu.dashboard.hangup')}
                                                </div>
                                            </div>
                                        </ViewContentItem>
                                    </form>
                                </div>
                            </div>
                        </ViewContentItems>
                    </ViewContent>
                    <ViewContent title=''></ViewContent>
                </div>
                <div className="col-md-8">
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: 530 }}
                        center={center}
                        zoom={zoom}
                        onLoad={onLoad}
                        onClick={onClick}
                        onDblClick={onDblClick}
                        onUnmount={onUnmount}
                    >
                        {gpsRecent && gpsRecent.GpsNearBy?.length > 0 ? gpsRecent.GpsNearBy?.map((area: Area) => {
                            return <Marker key={area.lat} position={{ lat: area.lat, lng: area.lng }} zIndex={1000000000} icon={{
                                url: car,
                                scaledSize: size,
                                anchor: point,
                                rotation: area.heading
                            }} visible={true} />
                        }) : null
                        }
                        {!directions && pickupPlace && pickupPlace.geometry && <Marker position={pickupPlace.geometry.location} />}
                        {!directions && dropoffPlace && dropoffPlace.geometry && <Marker position={dropoffPlace.geometry.location} />}
                        {directions && <DirectionsRenderer options={{ directions }} />}
                        {directions && <TrafficLayer />}
                    </GoogleMap>
                    {distance && (
                        <div className={'estimate show'}>
                            <div className="estimate-box hero">
                                <p>{generalCurrency?.symbol}{estimateText()}</p>
                            </div>
                            <div className="estimate-box">
                                <h4>Distance</h4>
                                <p>{distanceText()} </p>
                            </div>
                            <div className="estimate-box">
                                <h4>Duration</h4>
                                <p>{durationText()}</p>
                            </div>
                            <div className="estimate-box">
                                <h4>Traffic</h4>
                                <p>{trafficText()}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}