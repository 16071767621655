import { LoadScript } from "@react-google-maps/api";
import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CallModal } from "./components/call/call.modal";
import CallButton from './components/emergency/call.button';
import { Header } from "./components/header/header.component";
import { CallContextProvider } from "./context/call.context";
import { EmergencyContextProvider } from "./context/emergency-request.context";
import { Role, RoleAssignment } from "./entities/role.entity";
import { User } from "./entities/user.entity";
import firebase from "./firebase";
import { ApiLogsAclCode } from "./pages/apiLogs/acl";
import { ApiLogsModule } from "./pages/apiLogs/modules";
import { ArticleAclCode } from "./pages/article/acl";
import { ArticleModule } from "./pages/article/module";
import { AuditAclCode } from "./pages/audit/acl";
import { AuditModule } from "./pages/audit/module";
import { PrivateRoute } from "./pages/auth/private-route.component";
import { AuthRoute, AuthRouteMap } from "./pages/auth/routes";
import { CallAclCode } from "./pages/call/acl";
import { CallModule } from "./pages/call/module";
import { CorporateBookingAclCode } from "./pages/corporate/acl";
import { CorporateBookingModule } from "./pages/corporate/module";
import { CustomTollsAclCode } from "./pages/custom-tolls/acl";
import { CustomTollsModule } from "./pages/custom-tolls/module";
import { DashboardAclCode } from "./pages/dashboard/acl";
import { DashboardModule } from "./pages/dashboard/module";
import { DashboardRoute } from "./pages/dashboard/routes";
import { DriverAclCode } from "./pages/driver/acl";
import { DriverModule } from "./pages/driver/module";
import { EmergencyModule } from "./pages/emergency/module";
import { FareAclCode } from "./pages/fare/acl";
import { FareModule } from "./pages/fare/module";
import { FeatureAclCode } from "./pages/feature/acl";
import { FeatureModule } from "./pages/feature/module";
import { FeatureRouteMap } from "./pages/feature/routes";
import { FleetAclCode } from "./pages/fleet/acl";
import { FleetModule } from "./pages/fleet/module";
import { GroupAclCode } from "./pages/group/acl";
import { GroupModule } from "./pages/group/module";
import { HotspotAclCode } from "./pages/hotspot/acl";
import { HotspotModule } from "./pages/hotspot/module";
import { InspectionAclCode } from "./pages/inspection/acl";
import { InspectionModule } from "./pages/inspection/module";
import { NetworkAclCode } from "./pages/network/acl";
import { NetworkModule } from "./pages/network/module";
import { OperatorAclCode } from "./pages/operator/acl";
import { OperatorModule } from "./pages/operator/module";
import { PassengerAclCode } from "./pages/passenger/acl";
import { PassengerModule } from "./pages/passenger/module";
import { ReportAclCode } from "./pages/report/acl";
import { ReportModule } from "./pages/report/module";
import { RequestCategoryAclCode } from "./pages/request-category/acl";
import { RequestCategoryModule } from "./pages/request-category/module";
import { RequestAclCode } from "./pages/request/acl";
import { RequestModule } from "./pages/request/module";
import { SecurityAclCode } from "./pages/security/acl";
import { SecurityModule } from "./pages/security/module";
import { ServiceAclCode } from "./pages/service/acl";
import { ServiceModule } from "./pages/service/module";
import { ServiceRouteMap } from "./pages/service/routes";
import { SettingsAclCode } from "./pages/settings/acl";
import { SettingsModule } from "./pages/settings/module";
import { SubscriberAclCode } from "./pages/subscribers/acl";
import { SubscriberModule } from "./pages/subscribers/module";
import { DispatchAclCode } from "./pages/support/acl";
import { DispatchModule } from "./pages/support/module";
import { UserAclCode } from "./pages/user/acl";
import { UserModule } from "./pages/user/module";
import { VehicleAclCode } from "./pages/vehicle/acl";
import { VehicleModule } from "./pages/vehicle/module";
import { ZoneAclCode } from "./pages/zone/acl";
import { ZoneModule } from "./pages/zone/module";
import "./scss/main.scss";
import { AclService } from "./services/acl.service";
import { Api, Endpoint } from "./services/api.service";
import { AuthService } from "./services/auth.service";
import "./services/i18n";
import i18n from "./services/i18n";
import * as serviceWorker from "./serviceWorker";
const favicon = require(`./img/${process.env.REACT_APP_FAVICON}`);
interface TwilioClientIdRequest {
  twilioClientId: string,
  id?: string
  firebaseToken: string,
}
enum BrowserStatus {
  Online = "online",
  Offline = "offline",
  OnCall = "on-call"
}
AclService.setModuleActions<ArticleAclCode>(ArticleModule.name, ArticleModule.acl);
AclService.setModuleActions<ApiLogsAclCode>(ApiLogsModule.name, ApiLogsModule.acl);
AclService.setModuleActions<AuditAclCode>(AuditModule.name, AuditModule.acl);
AclService.setModuleActions<CallAclCode>(CallModule.name, CallModule.acl);
AclService.setModuleActions<CorporateBookingAclCode>(CorporateBookingModule.name, CorporateBookingModule.acl);
AclService.setModuleActions<DashboardAclCode>(DashboardModule.name, DashboardModule.acl);
AclService.setModuleActions<DispatchAclCode>(DispatchModule.name, DispatchModule.acl);
AclService.setModuleActions<DriverAclCode>(DriverModule.name, DriverModule.acl);
AclService.setModuleActions<FareAclCode>(FareModule.name, FareModule.acl);
AclService.setModuleActions<FeatureAclCode>(FeatureModule.name, FeatureModule.acl);
AclService.setModuleActions<FleetAclCode>(FleetModule.name, FleetModule.acl);
AclService.setModuleActions<GroupAclCode>(GroupModule.name, GroupModule.acl);
AclService.setModuleActions<HotspotAclCode>(HotspotModule.name, HotspotModule.acl);
AclService.setModuleActions<InspectionAclCode>(InspectionModule.name, InspectionModule.acl);
AclService.setModuleActions<NetworkAclCode>(NetworkModule.name, NetworkModule.acl);
AclService.setModuleActions<OperatorAclCode>(OperatorModule.name, OperatorModule.acl);
AclService.setModuleActions<PassengerAclCode>(PassengerModule.name, PassengerModule.acl);
AclService.setModuleActions<ReportAclCode>(ReportModule.name, ReportModule.acl);
AclService.setModuleActions<RequestAclCode>(RequestModule.name, RequestModule.acl);
AclService.setModuleActions<RequestCategoryAclCode>(RequestCategoryModule.name, RequestCategoryModule.acl);
AclService.setModuleActions<SecurityAclCode>(SecurityModule.name, SecurityModule.acl);
AclService.setModuleActions<ServiceAclCode>(ServiceModule.name, ServiceModule.acl);
AclService.setModuleActions<SettingsAclCode>(SettingsModule.name, SettingsModule.acl);
AclService.setModuleActions<SubscriberAclCode>(SubscriberModule.name, SubscriberModule.acl);
AclService.setModuleActions<UserAclCode>(UserModule.name, UserModule.acl);
AclService.setModuleActions<VehicleAclCode>(VehicleModule.name, VehicleModule.acl);
AclService.setModuleActions<ZoneAclCode>(ZoneModule.name, ZoneModule.acl);
AclService.setModuleActions<CustomTollsAclCode>(CustomTollsModule.name, CustomTollsModule.acl);

const routeMap: RouteProps[] = [
  ArticleModule.routes,
  ApiLogsModule.routes,
  CustomTollsModule.routes,
  AuditModule.routes,
  CallModule.routes,
  CorporateBookingModule.routes,
  DashboardModule.routes,
  DispatchModule.routes,
  DriverModule.routes,
  EmergencyModule.routes,
  FareModule.routes,
  FeatureRouteMap,
  FleetModule.routes,
  GroupModule.routes,
  HotspotModule.routes,
  InspectionModule.routes,
  NetworkModule.routes,
  OperatorModule.routes,
  PassengerModule.routes,
  ReportModule.routes,
  RequestCategoryModule.routes,
  RequestModule.routes,
  SecurityModule.routes,
  ServiceRouteMap,
  SettingsModule.routes,
  SubscriberModule.routes,
  UserModule.routes,
  VehicleModule.routes,
  ZoneModule.routes,
].reduce((a, v) => a.concat(v), []);

export default function App() {
  const [con, setConn] = useState();
  const [callData, setCallData] = useState();
  const [callModal, setCallModal] = useState(false);
  const [loading, setLoading] = useState<boolean>();
  const [user, setUser] = useState<User | undefined>(undefined);

  const messaging = firebase.messaging();
  useEffect(() => {
    let userData = AuthService.getUser()
    userData = userData?.user?.id ? userData.user : userData
    const assignData = userData ? [...((userData?.role as Role).assignments as RoleAssignment[])] : []
    console.log("route map", routeMap, assignData)

    async function init() {
      setLoading(true);
      const status = await Api.init();
      if (status == "initialized") {
        // auto-detect language from browser
        let userLang: string | string[] = navigator.language; //en-US,fr,es 
        userLang = userLang.split("-")
        userLang = userLang[0]
        i18n.changeLanguage(userLang);
        const data = await Api.post<any, any>(Endpoint.SETTINGS_UPDATE_LANGUAGE, { language: userLang });
        const user = AuthService.getUser();
        if (user) { tokenCreation(user); setUser(user) };
      }

      setLoading(false);
    }
    init();
  }, []);

  // useEffect(() => {
  //   messaging.onMessage((payload) => {
  //     // alert(`${payload.data.title} ${payload.data.data}`);
  //     // history.push(`/emergency/${payload.data.id}`)
  //   });
  // }, []);

  const tokenCreation = async (user: User) => {
    let firebaseToken = "";
    try {
      // msg
      await messaging.getToken().then((payload: any) => {
        firebaseToken = payload;
      })
    }
    catch (err) {
      // console.log("err in firebase", err);
      alert("Please allow permissions to your browser notification")
    }
    const res = await Api.get<any, any>(Endpoint.PASSENGER_CALL_TOKEN, {})
    // console.log('Tojeeenene', res);
    const data = user;
    // console.log("User data", data?.user.id);
    if (data?.user?.id) {
      const res1 = await Api.post<any, TwilioClientIdRequest>(Endpoint.ADDTWILIOCLIENTID, { id: data?.user?.id, twilioClientId: res.identity, firebaseToken: firebaseToken })
    } const Device = require('twilio-client').Device;
    const device = Device.setup(res.token, { debug: true });
    device.on("ready", function (device: any) {
      console.log("Twilio.Device Ready!");
    });
    device.on("error", function (error: any) {
      console.log("Twilio.Device Error: " + error.message);
    });
    device.on("connect", function (conn: any) {
      console.log('Successfully established call ! ');
    });
    device.on("disconnect", function (conn: any) {
      console.log("Call ended.");
      setCallModal(false);
    });
    device.on('incoming', function (conn: any) {
      console.log("Incoming Call...!!!!", conn)
      setCallModal(true);
      setConn(conn);
    });
    return device;
  }

  // useEffect(() => {
  //   const device = tokenCreation();
  //   Api.on('call_alert', async (payload: any) => {
  //     setCallData(payload);
  //   });
  // }, [callData]);

  return (
    loading == false ?
      <div className="auth-container all-wrapper with-side-panel solid-bg-all">
        <Helmet>
          <title>{`${process.env.REACT_APP_NAME} Admin`}</title>
          <link rel='icon' href={favicon} />
        </Helmet>
        <EmergencyContextProvider >
          <CallContextProvider >
            <div className="layout-w">
              {/* Only show header when authenticated */}
              {loading && <div>Loading...</div>}
              {AuthService.isLoggedIn() && !loading && <Header />}
              <div className="content-w">
                <Switch>
                  {/* Private routes require authentication */}
                  <Route exact path="/">
                    <Redirect to={(!loading && AuthService.isLoggedIn()) ? DashboardRoute.Overview : AuthRoute.Login} />
                  </Route>
                  {routeMap.map((routeProps) => (
                    <PrivateRoute key={routeProps.path as string} {...routeProps} loading={loading} />
                  ))}
                  {/* <Route path="/recording">
                  {loading ? <div>Loading...</div> : <RecordingAPI/>}
                </Route> */}
                  {/* Authentication routes - public */}
                  {AuthRouteMap.map((routeProps) => (
                    <Route key={routeProps.path as string} {...routeProps} />
                  ))}
                  <Route path="*">
                    <Redirect to={(!loading && AuthService.isLoggedIn()) ? DashboardRoute.Overview : AuthRoute.Login} />
                  </Route>
                </Switch>
                {callModal == true && <CallButton connection={con} callData={callData} />}
              </div>
              <CallModal />
            </div>
          </CallContextProvider>
        </EmergencyContextProvider>
        <ToastContainer theme="dark" />
      </div> : <div>loading...</div>
  );
}

const AppRouter = withRouter(App);

ReactDOM.render(
  <Suspense fallback="loading">
    <Router>
      <link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/react-draft-wysiwyg@1.12.3/dist/react-draft-wysiwyg.css' />
      <LoadScript
        googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}
        libraries={["places", "drawing"]}
        onError={({ message, name }) => {
          alert(message || "Google Maps unable to load, please check internet connection!");
        }}
      >
        <AppRouter />
      </LoadScript>
    </Router>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
