import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Network } from "../../../entities/network.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';
import { NetworkPost } from "../post/post.component";
import { SelectedItemContent } from "./content.component";

const groupFilters: GroupFilter[] = [
  { title: 'All', icon: 'phone-21' },
  { title: "deleted", icon: "trash" },

];

export function NetworkList(props: any) {

  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('');
  const [allCount, setAllCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [networks, setNetworks] = useState<Network[] | undefined>(undefined);
  const [selectedNetwork, setSelectedNetwork] = useState<Network>();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [size, setSize] = useState<number>()
  const [deletedCount, setDeletedCount] = useState<number>(0);


  const { t } = useTranslation('list');


  useEffect(() => {
    setSelectedNetwork(props.location.state)
  }, [props.location.state])

  const refresh = async (e?:boolean) => {
    let group : string | undefined= activeGroupFilter?.title;
    group = group === 'all' ? undefined : group;
    let isDeleted = false;
    console.log('This is Network before:',group);
    if (group == "deleted") {
       group = undefined;
       isDeleted = true;
       console.log('This is Group Inside:',group);
    }
    const { items, totalItems } = await Api.get<IPaginationResponse<Network>, IPaginationOptions>(Endpoint.NETWORK_LIST, { page: 0, limit: 20, group, search, isDeleted });

    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items) {
      if (props.location.state != null) {
        setSelectedNetwork(props.location.state);
        setNetworks(items);
      }
      else {
        setNetworks(items);
      }

    } 
    const ITEMS = await Api.get<IPaginationResponse<Network>, IPaginationOptions>(Endpoint.NETWORK_LIST, { limit: 10000000 });
    const response = await Api.get<any,any>(Endpoint.NETWORK_DELETED_COUNT);
    console.log("response count",response,totalItems)
    setDeletedCount(response);
    setAllCount(ITEMS.totalItems)
  // on edit updating states without refreshing
    if (e) {
      setTimeout(async () => {
        const item = items.filter((item: any) => item.id == selectedNetwork?.id)
        setSelectedNetwork(item[0]);
      }, 1000)
    }
    if (items.length > 0 && !selectedNetwork) {
      setSelectedNetwork(items[0]);
    }

   
  }

  useEffect(() => { refresh(); }, [search, activeGroupFilter, selectedNetwork]);

  const fetchMoreData = async () => {
    setLimit(limit + 10);
  };

  const deleteNetwork = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.NETWORK, { id: selectedNetwork?.id });
    setSelectedNetwork(undefined);
    refresh();
  }

  const toListItems = (network?: Network): ListItem<Network> | undefined => {
    if (!network) {
      return;
    }

    return {
      id: network.id as string, 
      title: network.title,
      status: ListItemStatus.Green,
      deleted:network.deletedTime,
      ref: network
    }
  }

  const addNetwork = () => {
    setFormMode(FormMode.Adding);
  }

  const editNetwork = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (networks) {
      const currentNetworkIndex = networks.findIndex(network => !!selectedNetwork && network.id === selectedNetwork.id);
      if (currentNetworkIndex + 1 < networks.length && currentNetworkIndex !== -1) {
        setSelectedNetwork(networks[currentNetworkIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (networks) {
      const currentNetworkIndex = networks.findIndex(network => !!selectedNetwork && network.id === selectedNetwork.id);
      if (currentNetworkIndex - 1 > -1 && currentNetworkIndex !== -1) {
        setSelectedNetwork(networks[currentNetworkIndex - 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedNetwork(undefined);
    setActiveGroupFilter(target);
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  let history = useHistory();

  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: 'All', icon: 'phone-21', count: allCount },
                  { title: "deleted", icon: "trash",count:deletedCount },

                ]}
                activeGroupFilter={activeGroupFilter}
                onGroupSelect={selectGroup}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={networks?.map(toListItems) as ListItem<Network>[]}
                activeItem={toListItems(selectedNetwork)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
            fetchMoreData={fetchMoreData}
                onRefresh={() => { setNetworks(undefined); refresh(); }}
                onClick={(item: ListItem<Network>) => { setSelectedNetwork(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                networks !== undefined ? (
                  selectedNetwork ? (
                    <View
                      componentName={"network"}
                      showAdd={true}
                      showAudit={true}
                      showEdit={true} 
                      showDelete={selectedNetwork.deletedTime?false:true}
                      showNext={true}
                      showPrev={true}
                      onAdd={addNetwork}
                      onEdit={editNetwork}
                      onDelete={deleteNetwork}
                      onNext={onNext}
                      onPrev={onPrev}
                      onAudit={() => checkAudit(selectedNetwork.id)}
                    >
                      <SelectedItemContent
                        selectedNetwork={selectedNetwork}
                        onRefresh={refresh}
                        setSelectedNetwork={setSelectedNetwork}
                      />
                    </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button onClick={addNetwork} className="btn btn-primary text-bold">{t("network.addNetwork")}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t("network.wait")}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedNetwork ? (
                  <NetworkPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedNetwork.id}
                    onAfterSave={() => refresh(true)}
                  />
                ) : (
                  <NetworkPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    onAfterSave={() => refresh(true)}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
            groupFilters={[
              { title: 'All', icon: 'phone-21', count: allCount },
              { title: "deleted", icon: "trash",count:deletedCount },

            ]}
            activeGroupFilter={activeGroupFilter}
            onGroupSelect={selectGroup}
          />

          <ListItems
            items={networks?.map(toListItems) as ListItem<Network>[]}
            activeItem={toListItems(selectedNetwork)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setNetworks(undefined); refresh(); }}
            onClick={(item: ListItem<Network>) => { setSelectedNetwork(item.ref); }}
          />

          {formMode === undefined ? (
            networks !== undefined ? (
              selectedNetwork ? (
                <View
                  componentName={"network"}
                  showAdd={true}
                  showAudit={true}
                  showEdit={true}
                  showDelete={selectedNetwork.deletedTime?false:true}
                  showNext={true}
                  showPrev={true}
                  onAdd={addNetwork}
                  onEdit={editNetwork}
                  onDelete={deleteNetwork}
                  onNext={onNext}
                  onPrev={onPrev}
                  onAudit={() => checkAudit(selectedNetwork.id)}
                >
                  <SelectedItemContent
                    selectedNetwork={selectedNetwork}
                    onRefresh={refresh}
                    setSelectedNetwork={setSelectedNetwork}
                  />
                </View>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addNetwork} className="btn btn-primary text-bold">{t("network.addNetwork")}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t("network.wait")}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedNetwork ? (
              <NetworkPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedNetwork.id}
                onAfterSave={() => refresh(true)}
              />
            ) : (
              <NetworkPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh(true)}
              />
            )
          )}
        </List>
      }
    </div>
  );

}
