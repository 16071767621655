import { GoogleMap, Marker } from "@react-google-maps/api";
import { EditorState } from 'draft-js';
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormAddressInput } from "../../../../components/form/input/address.component";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormSection } from "../../../../components/form/section.component";

export function HotspotPostBasic(props: any) {
  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  const [valueData, setValueData] = useState<boolean>(false);
  const [hotspotDate, setHotspotDate] = useState<string>();
  
  const [date, setDate] = useState<boolean>(false);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: -33.847927,
    lng: 150.651776,
  });
  const [zoom, setZoom] = useState(20);
  const [markerCoordrinates,setMarkerCoordinates] = useState<google.maps.LatLng>();
  
  const [description, setDescription] = useState<any>();
  const { control } = formProps;
  const [defaultLocation,setDefaultLocation] = useState();
  const [updatedCoordinates,setUpdatedCoordinates] = useState<any>();
  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty(),
  );

  // useEffect(()=>{

  //   console.log("form data",control.getValues())
  //   const formData = control.getValues()
  //   if(formData.id)
  //   {
  //     console.log("form")
  //     setCenter({lat:formData.address.lat,lng:formData.address.lng})
  //     setMarkerCoordinates({lat:formData.address.lat,lng:formData.address.lng})

  //   }

  // },[])

 
  useEffect(()=>{

    onLoad()
    

  },[updatedCoordinates])


  const onLoad = ()=>{

   
    if(updatedCoordinates){
      setCenter({
        lat: updatedCoordinates.geometry?.location.lat(),
        lng:updatedCoordinates.geometry?.location.lng()
      })

    }

    else{
      console.log("center",center)
      const formData = control.getValues()
        const coordinates = new google.maps.LatLng(formData.address.lat,formData.address.lng)     
        setCenter({lat:coordinates.lat(),lng:coordinates.lng()})
        setMarkerCoordinates(new google.maps.LatLng(formData.address.lat,formData.address.lng))
        setZoom(10)

    }

  }
  
  return (
    <FormSection
      title={t("hotspot.title")}
      subtitle={t("hotspot.basicSection.subTitle")}
    >
      <div className="row">
        <FormInput
          label={t("hotspot.basicSection.title")}
          name="title"
          type="text"
          className="col-md-6"
          validation={{ required: true }}
          tabIndex={1}
          autoFocus
          {...formProps}
        />
            <FormAddressInput
          label={t('hotspot.basicSection.address')}
          name="address"
          className="col-sm-6"
          validation={{ required: false }}
          tabIndex={8}
          updatedCoordinates={setUpdatedCoordinates}
          // setValue={}
          
         
          {...formProps}
        />

            <GoogleMap
              mapContainerStyle={{ height: '391px', width: '100%' }}
              center={center}
              zoom={zoom}
              onLoad={onLoad}
              // onUnmount={onUnmount}
            >
               {updatedCoordinates?<Marker
                            position={
                              new google.maps.LatLng(updatedCoordinates.geometry.location.lat(), updatedCoordinates.geometry.location.lng())
                            }
                            // draggable={true}
                            // onDragEnd={(e:any)=>{
                            //   updateLocation(e)
                            // }}
                          />:<Marker
                            position={markerCoordrinates as google.maps.LatLng}
                        />}

              
            </GoogleMap>
      
      </div>

    </FormSection>
  );

}
