import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { Currency } from '../../../entities/currency.entity';
import { Operator } from '../../../entities/operator.entity';
import { Settings } from '../../../entities/settings.entity';
import { Api, Endpoint } from '../../../services/api.service';
import { SettingsService } from '../../../services/settings.service';

interface Props {
  selectedOperator: Operator
  onRefresh?: () => void,
  setSelectedOperator?:(operator:undefined|Operator)=>void
}

interface OperatorStatisticsResponse {
  tripCount: number
  revenue: number
  distance: number
  totalTime: number
  tripPercentage: number
  revenuePercentage: number
  distancePercentage: number
  prevTripCount: number
  prevRevenue: number
  prevDistance: number
  prevTotalTime: number
  timePercentage: number
}

interface Time {
  time: string;
  id: string | undefined
}

export function SelectedItemContent({
  selectedOperator,
  onRefresh,
  setSelectedOperator
}: Props) {

  const { t } = useTranslation(['forms', 'dashboard']);

  const [operator, setOperator] = useState<Operator>();
  const [open, setOpen] = useState(false); 
  const [symbol, setSymbol] = useState<string | null>('')
  const [time, setTime] = useState<string>('today');
  const [subUnit, setSubUnit] = useState<number>(100)
  const [state, setState] = useState<OperatorStatisticsResponse>(undefined!);


  useEffect(() => {
    const settings: Settings | undefined = SettingsService.getSetting();
    const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;
    const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
    setSymbol(currency)
    setSubUnit(unit)
  }, [])

  const refreshStats = useCallback(async () => {
    const response = await Api.get<OperatorStatisticsResponse, Time>(Endpoint.OPERATOR_STATISTICS, { time: time, id: operator?.id })
    console.log('stats:', response)
    setState(response)
  }, [time, operator])

  useEffect(() => {
    refreshStats();
  }, [refreshStats])
  

    useEffect(() => {
        const settings: Settings | undefined = SettingsService.getSetting();
        const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;
        setSymbol(currency)
    }, [])

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const stats = [
    {
      "title": "Rides",
      "value": "0",
      "percentage": "0",
      "trend": "neutral",
    },
    {
      "title": "Earnings",
      "value": "0",
      "percentage": "0",
      "trend": "neutral",
    },
    {
      "title": "Earnings per Ride",
      "value": "0",
      "percentage": "0",
      "trend": "neutral",
    },
    {
      "title": "Shift Time",
      "value": "0 Hrs",
      "percentage": "0",
      "trend": "neutral",
    },
  ]

  const getLocaleDateOfConsent = (operator: Operator | undefined): string => {
    return operator && operator.createTime ? new Date(operator.createTime).toLocaleDateString() : '';
  }

  const onclickRecover=async (selectedOperator:Operator)=>{  
    console.log('Recover This Operator:',selectedOperator);  
const response = await Api.post<Operator,{id:string}>(Endpoint.OPERATOR_RECOVER, { id:selectedOperator.id as string });
console.log('Recover This Operator Response:',response)
if (onRefresh && setSelectedOperator) {
  setSelectedOperator(undefined);
  onRefresh();
}
}
const onclickActivate=async (selectedOperator:Operator)=>{  
  console.log('Activate This Operator:',selectedOperator);  
const response = await Api.post<Operator,{id:string}>(Endpoint.OPERATOR_ACTIVATE, { id:selectedOperator.id as string });
console.log('Activate This Operator Response:',response)
if (onRefresh && setSelectedOperator) {
setSelectedOperator(undefined);
onRefresh();
}
}
  const init = useCallback(async () => {
    setOperator(selectedOperator);
    const operator = await Api.get<Operator, { id: string }>(Endpoint.OPERATOR, { id: selectedOperator.id as string });
    console.log(operator);
    setOperator(operator);
  }, [selectedOperator]);

  useEffect(() => {
    init();
  }, [selectedOperator])

  const changeTime = (e: any) => {
    setTime(e.target.value)
  }

  return (
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={selectedOperator.title}
      >
        <ViewContentItems>
          {selectedOperator.deletedTime ? <div className="alert alert-success borderless">
            <h5 className="alert-heading">Operator Deleted</h5>
            <p>Do you want to recover this operator account?</p>
            <div className="alert-btn">
              <a className="btn btn-white-gold" href={'#href'} onClick={() => onclickRecover(selectedOperator)}>
                <i className="os-icon os-icon-ui-92"></i>
                <span>{'Recover Account'}</span>
              </a>
            </div>
          </div> : null
          }        
          {selectedOperator.status=='blocked' ? <div className="alert alert-danger borderless">
          <h5 className="alert-heading">Operator Blocked</h5>
          <p>Do you want to activate this operator account?</p>
          <div className="alert-btn">
            <a className="btn btn-white-gold" href={'#href'} onClick={() => onclickActivate(selectedOperator)}>
              <i className="os-icon os-icon-ui-92"></i>
              <span>{'Activate Account'}</span>
            </a>
          </div>
        </div> : null
        }
          <ViewContentItem title={t('operator.basicSection.title')}>
            {selectedOperator.title}
          </ViewContentItem>

          <ViewContentItem title={t('operator.basicSection.contactPerson')}>
            {selectedOperator.contactPerson}
          </ViewContentItem>

          <ViewContentItem title={t('operator.basicSection.contactPhone')}>
            {selectedOperator.contactPhone}
          </ViewContentItem>

          <ViewContentItem title={t('operator.basicSection.contactEmail')}>
            {selectedOperator.contactEmail}
          </ViewContentItem>
          <ViewContentItem title={t('operator.basicSection.contactAddress')}>
            {selectedOperator.contactAddress?.text}
          </ViewContentItem>
          <ViewContentItem title="Consent">
            {operator?.consent ? <div style={{ color: "#047bf8" }} onClick={onOpenModal}>Version {operator?.consent.operatorVersion} ({getLocaleDateOfConsent(operator)})</div> : null}
            <Modal open={open} onClose={onCloseModal} center>
              <h2 style={{ marginTop: '20px' }}>Operator Agreement</h2>
              <p>{operator?.consent?.operatorAgreement}</p>
              <h2 style={{ marginTop: '5px' }}>Operator Use Of Information</h2>
              <p>{operator?.consent?.operatorUseOfInformation}</p>
            </Modal>
          </ViewContentItem>
          <ViewContentItem title={t('operator.basicSection.businessRegistration')}>
            {selectedOperator.businessRegistration}
          </ViewContentItem>
          <ViewContentItem title={t('operator.basicSection.accreditionNumber')}>
            {selectedOperator.accreditionNumber}
          </ViewContentItem>
          <ViewContentItem title={t(`${process.env.REACT_APP_ROUTING_LABEL}`)}>
            {selectedOperator.bankSwiftCode}
          </ViewContentItem>
          <ViewContentItem title={t('operator.basicSection.bankName')}>
            {selectedOperator.bankName}
          </ViewContentItem>
          <ViewContentItem title={t('operator.basicSection.bankAccountNumber')}>
            {selectedOperator.bankAccountNumber}
          </ViewContentItem>
          <ViewContentItem title={t('operator.basicSection.bankAccountTitle')}>
            {selectedOperator.bankAccountTitle}
          </ViewContentItem>
        </ViewContentItems>
      </ViewContent>

      <div className="col-md-6">
        <div className="element-actions">
          <form className="form-inline justify-content-sm-end">
            <select value={time} onChange={changeTime} className="form-control form-control-sm rounded">
              <option value="today">{t('dashboard:dropdown.today')}</option>
              <option value="yesterday">{t('dashboard:dropdown.yesterday')}</option>
              <option value="this-week">{t('dashboard:dropdown.thisWeek')}</option>
              <option value="last-week">{t('dashboard:dropdown.lastWeek')}</option>
              <option value="this-month">{t('dashboard:dropdown.thisMonth')}</option>
              <option value="last-month">{t('dashboard:dropdown.lastMonth')}</option>
              <option value="this-year">{t('dashboard:dropdown.thisYear')}</option>
            </select>
          </form>
        </div>
        <div className="row">
          {/* {stats.map((stat: any, i: number) => ( */}
          <div className="col-sm-12 col-md-6">
            <a className="element-box el-tablo" href="#stat">
              <div className="label">Rides</div>
              <div className="value">{state?.tripCount ? state.tripCount : '0'}</div>
              <div className={`trending trending-${state?.tripCount >= state?.prevTripCount ? 'up' : 'down'}-basic`}>
                <span>${state?.tripPercentage === null ? '0' : Math.floor(state?.tripPercentage)}%</span>
                <i className={`os-icon os-icon-arrow-${state?.tripCount >= state?.prevTripCount ? 'up2' : 'down'}`}></i>
              </div>
            </a>
          </div>
          <div className="col-sm-12 col-md-6">
            <a className="element-box el-tablo" href="#stat">
              <div className="label">Earnings</div>
              <div className="value">{symbol}{state?.revenue ? state.revenue / subUnit : '0'}</div>
              <div className={`trending trending-${state?.revenue >= state?.prevRevenue ? 'up' : 'down'}-basic`}>
                <span>${state?.revenuePercentage === null ? '0' : Math.floor(state?.revenuePercentage)}%</span>
                <i className={`os-icon os-icon-arrow-${state?.revenue >= state?.prevRevenue ? 'up2' : 'down'}`}></i>
              </div>
            </a>
          </div>
          <div className="col-sm-12 col-md-6">
            <a className="element-box el-tablo" href="#stat">
              <div className="label">Distance</div>
              <div className="value">{state?.distance ? state.distance : '0'}</div>
              <div className={`trending trending-${state?.distance >= state?.prevDistance ? 'up' : 'down'}-basic`}>
                <span>${state?.distancePercentage === null ? '0' : Math.floor(state?.distancePercentage)}%</span>
                <i className={`os-icon os-icon-arrow-${state?.distance >= state?.prevDistance ? 'up2' : 'down'}`}></i>
              </div>
            </a>
          </div>
          {/* <div className="col-sm-12 col-md-6">
            <a className="element-box el-tablo" href="#stat">
              <div className="label">Shift Time</div>
              <div className="value">{state?.totalTime ? Math.floor(state.totalTime) : '0'}s</div>
              <div className={`trending trending-${state?.totalTime >= state?.prevTotalTime ? 'up' : 'down'}-basic`}>
                <span>${state?.timePercentage === null ? '0' : Math.floor(state?.timePercentage)}%</span>
                <i className={`os-icon os-icon-arrow-${state?.totalTime >= state?.prevTotalTime ? 'up2' : 'down'}`}></i>
              </div>
            </a>
          </div> */}
        </div>
      </div>
    </ContentWrapper>
  );
}