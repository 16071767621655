import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter, StatusFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Operator, OperatorStatus } from "../../../entities/operator.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';
import { OperatorPost } from "../post/post.component";
import { SelectedItemContent } from "./content.component";

const groupFilters: GroupFilter[] = [
  { title: "all", icon: "phone-21" },
  { title: "active", icon: "user-check" },
  { title: "blocked", icon: "user-x" },
  { title: "deleted", icon: "trash" },

];

const statusFilters: StatusFilter[] = [
  { title: "Online", status: ListItemStatus.Green },
  { title: "On-Trip", status: ListItemStatus.Blue },
  { title: "Offline", status: ListItemStatus.Red },

];

export function OperatorList(props: any) {

  const { y: pageYOffset } = useWindowScroll();

  const [changeStatus, setChangeStatus] = useState<OperatorStatus | undefined>(undefined)
  const [search, setSearch] = useState<string>('');
  const [allCount, setAllCount] = useState<number>(0);
  const [activeCount, setActiveCount] = useState<number>(0);
  const [blockedCount, setBlockedCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [operators, setOperators] = useState<Operator[] | undefined>(undefined);
  const [selectedOperator, setSelectedOperator] = useState<Operator>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [activeStatusFilter, setActiveStatusFilter] = useState<
    StatusFilter | undefined
  >(undefined);
  const [size, setSize] = useState<number>()
  const [deletedCount, setDeletedCount] = useState<number>(0);


  const { t } = useTranslation(['main', 'list']);


  useEffect(() => {
    setSelectedOperator(props.location.state)
  }, [props.location.state])

  const refresh = async (e?:boolean) => {
    let group: string | undefined = activeGroupFilter?.title;
    group = group === 'all' ? undefined : group;
    const status = activeStatusFilter?.title;
    let isDeleted = false;
    console.log('This is Group before:', group);
    if (group == "deleted") {
      group = undefined;
      isDeleted = true;
      console.log('This is Group Inside:', group);
    }
    const { items, totalItems } = await Api.get<IPaginationResponse<Operator>, IPaginationOptions>(Endpoint.OPERATOR_LIST, { page: 0, limit, search, group, status, isDeleted });

    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items) {
      if (props.location.state != null) {
        setSelectedOperator(props.location.state);
        setOperators(items);
      }
      else {
        setOperators(items);
      }
    }

    const ITEMS = await Api.get<
      IPaginationResponse<Operator>,
      IPaginationOptions
    >(Endpoint.OPERATOR_LIST, { limit: 10000000 });

    const response = await Api.get<any, any>(Endpoint.OPERATOR_DELETED_COUNT);
    setDeletedCount(response);
    setAllCount(ITEMS.totalItems)
    setActiveCount(ITEMS.items.filter(item => item.status === 'active').length)
    setBlockedCount(ITEMS.items.filter(item => item.status === 'blocked').length)

    if (selectedOperator?.status === OperatorStatus.Blocked) {
      setChangeStatus(OperatorStatus.Active)
    } else if (selectedOperator?.status === OperatorStatus.Active) {
      setChangeStatus(OperatorStatus.Blocked)
    } else {
      setChangeStatus(undefined)
    }

    if (items.length > 0 && !selectedOperator) {
      setSelectedOperator(items[0]);
    }

     // on edit updating states without refreshing
     if (e) {
      setTimeout(async () => {
        const item = items.filter((item: any) => item.id == selectedOperator?.id)
        setSelectedOperator(item[0]);
      }, 500)
    }
  }

  useEffect(() => { refresh(); }, [search, limit, activeGroupFilter, activeStatusFilter, selectedOperator]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const deleteOperator = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.OPERATOR, { id: selectedOperator?.id });
    setSelectedOperator(undefined);
    refresh();
  }

  const toListItems = (operator?: Operator): ListItem<Operator> | undefined => {
    if (!operator) {
      return;
    }

    return {
      id: operator.id as string,
      title: operator.title,
      status: ListItemStatus.Green,
      deleted: operator.deletedTime,
      ref: operator
    }
  }

  const addOperator = () => {
    setFormMode(FormMode.Adding);
  }

  const editOperator = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (operators) {
      const currentOperatorIndex = operators.findIndex(operator => !!selectedOperator && operator.id === selectedOperator.id);
      if (currentOperatorIndex + 1 < operators.length && currentOperatorIndex !== -1) {
        setSelectedOperator(operators[currentOperatorIndex + 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedOperator(undefined);
    setActiveGroupFilter(target);
  }

  const selectStatus = (target: StatusFilter | undefined) => {
    setSelectedOperator(undefined);
    setActiveStatusFilter(
      target?.title !== activeStatusFilter?.title ? target : undefined
    );
  };

  const onPrev = () => {
    if (operators) {
      const currentOperatorIndex = operators.findIndex(operator => !!selectedOperator && operator.id === selectedOperator.id);
      if (currentOperatorIndex - 1 > -1 && currentOperatorIndex !== -1) {
        setSelectedOperator(operators[currentOperatorIndex - 1]);
      }
    }
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  const statusChange = async () => {
    if (selectedOperator?.status === OperatorStatus.Active) {
      const { data } : any = await Api.post(Endpoint.OPERATOR_DEACTIVATE, { id: selectedOperator.id })
      setSelectedOperator(data);
      // refresh();
    } else if (selectedOperator?.status === OperatorStatus.Blocked) {
      const { data } : any = await Api.post(Endpoint.OPERATOR_ACTIVATE, { id: selectedOperator.id });
      setSelectedOperator(data);
      // refresh();
    }
  }

  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: "all", icon: "phone-21", count: allCount },
                  { title: "active", icon: "user-check", count: activeCount },
                  { title: "blocked", icon: "user-x", count: blockedCount },
                  { title: "deleted", icon: "trash", count: deletedCount },

                ]}
                statusFilters={statusFilters}
                activeGroupFilter={activeGroupFilter}
                activeStatusFilter={activeStatusFilter}
                onGroupSelect={selectGroup}
                onStatusSelect={selectStatus}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={operators?.map(toListItems) as ListItem<Operator>[]}
                activeItem={toListItems(selectedOperator)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setOperators(undefined); refresh(); }}
                onClick={(item: ListItem<Operator>) => { setSelectedOperator(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                operators !== undefined ? (
                  selectedOperator ? (
                    <View
                      componentName={"operator"}
                      changeOperatorStatus={changeStatus}
                      showAdd={true}
                      showAudit={true}
                      showEdit={true}
                      showDelete={selectedOperator.deletedTime ? false : true}
                      showNext={true}
                      showPrev={true}
                      showStateChange={true}
                      onAdd={addOperator}
                      onEdit={editOperator}
                      onDelete={deleteOperator}
                      onStateChange={statusChange}
                      onPrev={onPrev}
                      onNext={onNext}
                      onAudit={() => checkAudit(selectedOperator.id)}
                    >
                      <SelectedItemContent
                        selectedOperator={selectedOperator}
                        onRefresh={refresh}
                        setSelectedOperator={setSelectedOperator}
                      />
                    </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button onClick={addOperator} className="btn btn-primary text-bold">{t("list:operator.addOperator")}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t("list:operator.wait")}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedOperator ? (
                  <OperatorPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedOperator.id}
                    onAfterSave={() => refresh(true)}
                  />
                ) : (
                  <OperatorPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    onAfterSave={() => refresh(true)}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
            groupFilters={[
              { title: "all", icon: "phone-21", count: allCount },
              { title: "active", icon: "user-check", count: activeCount },
              { title: "blocked", icon: "user-x", count: blockedCount },
              { title: "deleted", icon: "trash", count: deletedCount },
            ]}
            statusFilters={statusFilters}
            activeGroupFilter={activeGroupFilter}
            activeStatusFilter={activeStatusFilter}
            onGroupSelect={selectGroup}
            onStatusSelect={selectStatus}
          />

          <ListItems
            items={operators?.map(toListItems) as ListItem<Operator>[]}
            activeItem={toListItems(selectedOperator)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setOperators(undefined); refresh(); }}
            onClick={(item: ListItem<Operator>) => { setSelectedOperator(item.ref); }}
          />

          {formMode === undefined ? (
            operators !== undefined ? (
              selectedOperator ? (
                <View
                  componentName={"operator"}
                  changeOperatorStatus={changeStatus}
                  showAdd={true}
                  showAudit={true}
                  showEdit={true}
                  showDelete={selectedOperator.deletedTime ? false : true}
                  showNext={true}
                  showPrev={true}
                  showStateChange={true}
                  onAdd={addOperator}
                  onEdit={editOperator}
                  onDelete={deleteOperator}
                  onStateChange={statusChange}
                  onPrev={onPrev}
                  onNext={onNext}
                  onAudit={() => checkAudit(selectedOperator.id)}
                >
                  <SelectedItemContent
                    selectedOperator={selectedOperator}
                    onRefresh={refresh}
                    setSelectedOperator={setSelectedOperator}
                  />
                </View>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addOperator} className="btn btn-primary text-bold">{t("list:operator.addOperator")}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t("list:operator.wait")}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedOperator ? (
              <OperatorPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedOperator.id}
                onAfterSave={() => refresh(true)}
              />
            ) : (
              <OperatorPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh(true)}
              />
            )
          )}
        </List>
      }
    </div>
  );

}
