import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Player } from 'video-react';
import EmergencyFunctions from '../../../../components/emergency/emergency.functions';
import { ContentWrapper, ViewContent } from '../../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../../components/view/item.component';
import { Booking } from '../../../../entities/booking.entity';
import { Driver } from '../../../../entities/driver.entity';
import { Emergency, EmergencyStatus } from '../../../../entities/emergency.entity';
import { getStorageUrl } from '../../../../util/file.util';
import { ProfilePicSize } from './list.component';

interface Props {
    selectedEmergency: Emergency;
    getProfilePic: (driver: Driver, size: ProfilePicSize) => string;
    refresh: () => void;
    handlerEmergency: any;
    checkConnection: any;
}

export default function SelectedItemContent({ selectedEmergency, getProfilePic, refresh, handlerEmergency, checkConnection }: Props) {
    const { t } = useTranslation('list');
    const { push: navigateTo } = useHistory();
    const [peerMessage, setPeerMessage] = useState("Unknown");
    const [showActivity, setShowActivity] = useState(false);
    const [videoUrl, setUrl] = useState<string>();
    const [booking, setBooking] = useState<Booking>()

    const getRecordingUrl = (emergency: Emergency): string | undefined => {
        console.log("Emergency: ", emergency)
        //service?.profileFiles?.id && 
        if (emergency?.recordingFile && emergency?.id) {
            return `${getStorageUrl()}/emergency/${emergency.id}/recording/${emergency?.recordingFile?.id
                }/${`recording`}.${emergency?.recordingFile?.extension}`;
        } else {
            return undefined;
        }
    };

    async function updateStatus() {
        await EmergencyFunctions.updateStatus(EmergencyStatus.Completed, selectedEmergency);
        handlerEmergency(undefined);
        refresh();
    }
    useEffect(() => {
        console.log('This is Emergency Data:', selectedEmergency)
        const res = getRecordingUrl(selectedEmergency)
        console.log('This is Emergency video url:', res);
        setUrl(res);
    }, [])

    useEffect(() => {
        selectedEmergency.trip ? setBooking(selectedEmergency?.trip.booking as Booking) : setBooking(undefined)
    }, [booking])

    const connectCall = async () => {
        setPeerMessage("Checking...");
        switch (peerMessage) {
            case "Unknown":
                checkConnection(setPeerMessage, selectedEmergency.peerId, setShowActivity);
                break;
            case "Connection Available":
                EmergencyFunctions.ActivateRequest(selectedEmergency);
                await EmergencyFunctions.updateStatus(EmergencyStatus.Taken);
                navigateTo("/taxis/emergency");
                break;
            case "No Connection Available":
                setPeerMessage("Checking...");
                checkConnection(setPeerMessage, selectedEmergency.peerId, setShowActivity);
                break;
        }
    }

    useEffect(() => {
        setPeerMessage("Unknown");
        return () => {
            setPeerMessage("Unknown");
        }
    }, [refresh]);


    return (
        <ContentWrapper className="row">
            <ViewContent
                className="col-md-6"
                title={`${selectedEmergency?.driver ? selectedEmergency?.driver?.firstName.toUpperCase() : selectedEmergency?.passenger?.firstName} | ${selectedEmergency?.driver?.phone ? selectedEmergency?.driver?.phone : selectedEmergency?.passenger?.phone}`}
                subtitle={`${selectedEmergency?.driver ? selectedEmergency?.driver?.email : selectedEmergency?.passenger?.email} | ${selectedEmergency?.driver ? selectedEmergency?.driver?.gender?.toUpperCase() : selectedEmergency?.passenger?.gender?.toUpperCase()}`}
                imgSrc={getProfilePic(selectedEmergency.driver, ProfilePicSize.Single)}
            >
                <ViewContentItems>
                    <ViewContentItem title={t("task.dueTimestamp")}>
                        {(new Date(selectedEmergency.createTime).toLocaleString())}
                    </ViewContentItem>

                    <ViewContentItem title={t("emergency.level")}>
                        {(selectedEmergency.level.toUpperCase())}
                    </ViewContentItem>
                    {selectedEmergency.status === EmergencyStatus.Action && (
                        <ViewContentItem title={t('emergency.actionAssigned')}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {selectedEmergency.action}
                                <div onClick={updateStatus} className="text-right" style={{ flex: 1, alignSelf: 'end', marginTop: -10 }}>
                                    <button className="btn btn-primary text-bold">
                                        {t("emergency.actionCompleted")}
                                    </button>
                                </div>
                            </div>
                        </ViewContentItem>
                    )}
                    {(selectedEmergency.action && selectedEmergency.status === EmergencyStatus.Completed) && (
                        <ViewContentItem title={t('emergency.actionPerformed')}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {selectedEmergency.action}
                            </div>
                        </ViewContentItem>
                    )}
                    {selectedEmergency.status === EmergencyStatus.New && (
                        <ViewContentItem title={t("emergency.connection")}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div >
                                    {peerMessage}
                                </div>
                                <div style={{ flex: 1, alignSelf: 'end', marginTop: -10 }}>
                                    {peerMessage === "Unknown" && (
                                        <div onClick={connectCall} className="text-right" >
                                            <button className="btn btn-primary text-bold">
                                                {t("emergency.call.Unknown")}
                                            </button>
                                        </div>
                                    )}
                                    {peerMessage === "Connection Available" && (
                                        <div onClick={connectCall} className="text-right" >
                                            <button className="btn btn-primary text-bold">
                                                {t("emergency.call.Active")}
                                            </button>
                                        </div>
                                    )}
                                    {peerMessage === "No Connection Available" && (
                                        <div onClick={connectCall} className="text-right" >
                                            <button className="btn btn-primary text-bold">
                                                {t("emergency.call.InActive")}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ViewContentItem>
                    )}
                    {selectedEmergency?.vehicle ?
                        <ViewContentItem title={t('emergency.vehicleDetails')}>
                            {selectedEmergency?.vehicle?.make + " " + selectedEmergency?.vehicle?.model} | {selectedEmergency?.vehicle?.plate} | {selectedEmergency?.vehicle?.color}
                        </ViewContentItem> : null
                    }
                    {selectedEmergency?.driver ?
                        <ViewContentItem title={t('emergency.driverDetails')}>
                            {selectedEmergency?.driver.firstName + " " + selectedEmergency?.driver.lastName} | {'Phone Number:' + " " + selectedEmergency?.driver.phone} | {"Code:" + " " + selectedEmergency?.driver?.code}
                        </ViewContentItem> : null
                    }

                    {selectedEmergency?.passenger ?
                        <ViewContentItem title={t('emergency.passengerDetails')}>
                            {selectedEmergency?.passenger.firstName + " " + selectedEmergency?.passenger.lastName} | {'Phone Number:' + " " + selectedEmergency?.passenger.phone} | {"Email:" + " " + selectedEmergency?.passenger?.email}
                        </ViewContentItem> : null
                    }
                    {videoUrl && <>
                        <Player>
                            <source src={videoUrl} />
                        </Player>
                        <div className='alert alert-info' style={{ cursor: 'pointer' }}>
                            <a style={{ display: 'flex', justifyContent: 'center', color: '#334152', fontWeight: 500 }} target={'_blank'} href={videoUrl} download>Download Recorded Video</a>

                        </div>
                    </>}
                    {/* {selectedEmergency?.trip ?
                        <ViewContentItem title={t('emergency.tripDetails')}>
                            {"from:"+" "+ booking?.pickupAddress.text + " | " +"To:"+" "+ booking?.dropOffAddress.text} | {'Code:' + " " + selectedEmergency?.trip.code}
                        </ViewContentItem> : null
                    } */}
                </ViewContentItems>
            </ViewContent>
        </ContentWrapper>
    );
}