import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormUpload, selectedUrl, UploadPreviewUrl } from "../../../../components/form/input/upload.component";
import { FormSection } from "../../../../components/form/section.component";
import { getUrl } from "../../../../components/form/util";
import { Driver, DriverFileType } from "../../../../entities/driver.entity";
import { IFile } from "../../../../entities/file.entity";
import { Settings } from "../../../../entities/settings.entity";
import { Api, Endpoint } from "../../../../services/api.service";
import { checkCCStatus } from "../../../../util/checkCCStatus";

interface id {
  id: string | undefined;
  picId: string
  fileType: DriverFileType
}
interface props{
  settings: Settings|undefined
}
export function DriverPostVerification({settings}:props) {
  const [citizen, setCitizen] = useState<boolean>(false);
  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  const { getValues,clearErrors } = formProps;
  const [ENABLE_CC, setENABLE_CC] = useState<boolean>();

  // As Form Props Changes, every function Inside Form Component i.e.
  // bank, emergency, feature, verification etc etc will re-render on every scroll or drag
  // when if rerenders, the functions called inside will be executed on every scroll or drag
  // please try to solve this issue, if you want to see the issue please uncomment the useEffect below

  useEffect(()=>{
    const enableCC = async ()=>{
      const CC = await checkCCStatus()
      setENABLE_CC(CC)
    }
    enableCC()
  },[])
  const getUrls = (type: DriverFileType): UploadPreviewUrl[] | undefined => {
    const driver: Driver = formProps.control.defaultValuesRef.current as Driver;
    if(driver.id){
    switch (type) {
      case DriverFileType.License:
        let LicenseId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/license/`).toString()
        LicenseId = LicenseId?.substring(1, 37);
        const licenseDocument = driver.licenseFiles?.filter(file => file.id != LicenseId)
        return licenseDocument ? licenseDocument.map(file => fileToUrl(driver, file)) : [];
      case DriverFileType.IdentityFront:
        let identityFrontId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/identity/`).toString()
        identityFrontId = identityFrontId?.substring(1, 37);
        const identityFrontDocument = driver.identityFiles?.filter(file => file.id != identityFrontId)
        return identityFrontDocument ? identityFrontDocument.map(file => fileToUrl(driver, identityFrontDocument[0])) : [];
        case DriverFileType.IdentityBack:
        let identityBackId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/identity/`).toString()
        identityBackId = identityBackId?.substring(1, 37);
        const identityBackDocument = driver.identityFiles?.filter(file => file.id != identityBackId)
        return identityBackDocument ? identityBackDocument.map(file => fileToUrl(driver, identityBackDocument[1])) : [];
      case DriverFileType.Police:
        let PoliceId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/police/`).toString()
        PoliceId = PoliceId?.substring(1, 37);
        const policeDocument = driver.policeFiles?.filter(file => file.id != PoliceId)
        return policeDocument ? policeDocument.map(file => fileToUrl(driver, file)) : [];
      case DriverFileType.History:
        let HistoryId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/history/`).toString()
        HistoryId = HistoryId?.substring(1, 37);
        const historyDocument = driver.historyFiles?.filter(file => file.id != HistoryId)
        return historyDocument ? historyDocument.map(file => fileToUrl(driver, file)) : [];
      case DriverFileType.Vevo:
        let VevoId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/vevo/`).toString()
        VevoId = VevoId?.substring(1, 37);
        const vevoDocument = driver.vevoFiles?.filter(file => file.id != VevoId)
        return vevoDocument ? vevoDocument.map(file => fileToUrl(driver, file)) : [];
      case DriverFileType.Medicare:
        let MedicareId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/medicare/`).toString()
        MedicareId = MedicareId?.substring(1, 37);
        const medicareDocument = driver.medicareCardFiles?.filter(file => file.id != MedicareId)
        return medicareDocument ? medicareDocument.map(file => fileToUrl(driver, file)) : [];
      case DriverFileType.Proficiency:
        let ProficiencyId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/proficiency/`).toString()
        ProficiencyId = ProficiencyId?.substring(1, 37);
        const proficiencyDocument = driver.englishLanguageProficiencyFiles?.filter(file => file.id != ProficiencyId)
        return proficiencyDocument ? proficiencyDocument.map(file => fileToUrl(driver, file)) : [];
    }}
    return;
  }
  const fileToUrl = (driver: Driver, file: IFile): UploadPreviewUrl => {
    if(file&&driver)
    return {
      thumb: getUrl(`/driver/${driver.id}/${file.type}/${file.id}/admin_single.${file.extension}`),
      full: getUrl(`/driver/${driver.id}/${file.type}/${file.id}/admin_large.${file.extension}`),
    }
    return {
      thumb: ``,
      full: ``,
    }
  }
  const FormIdentityFrontOnclick = async (e: any) => {
    try{
    const form: Driver = getValues() as Driver;
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/identity/`).toString()
    identityId = identityId?.substring(1, 37);
    if (form.identityFiles && form.identityFiles.length > 0 && identityId) {
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.IdentityFront });
    }
  }
  catch(error){
    console.log("error in file")
  }
  }
  const FormIdentityBackOnclick = async (e: any) => {
    try{
    const form: Driver = getValues() as Driver;
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/identity/`).toString()
    identityId = identityId?.substring(1, 37);
    if (form.identityFiles && form.identityFiles.length > 0 && identityId) {
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.IdentityBack });
    }
  }
  catch(error){
    console.log("error in identity back",error)
  }
  }
  const FormVevoOnclick = async (e: any) => {
    try{
    const form: Driver = getValues() as Driver;
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/vevo/`).toString()
    identityId = identityId?.substring(1, 37);
    if (form.vevoFiles && form.vevoFiles.length > 0 && identityId) {
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.Vevo });
    }
  }
  catch(error){
    console.log("error in vevo",error)
  }
  }
  const FormLicenseOnclick = async (e?: any) => {
   try{
    const form: Driver = getValues() as Driver;
    console.log("selected url",selectedUrl) 
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/license/`).toString()
    identityId = identityId?.substring(1, 37);
    console.log("selected url",identityId)    
    if (form.licenseFiles && form.licenseFiles.length > 0 && identityId) {
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.License });
 
    }
  }
  catch(error){
    console.log("error in license",error)
  }
  }
  const FormMedicareOnclick = async (e: any) => {
    try{
    const form: Driver = getValues() as Driver;
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/medicare/`).toString()
    identityId = identityId?.substring(1, 37);
    if (identityId) {
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.Medicare });
    }
  }
  catch(error){
    console.log("error",error)
  }
  }
  const FormProficiencyOnclick = async (e: any) => {
    try{
    const form: Driver = getValues() as Driver;
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/proficiency/`).toString()
    identityId = identityId?.substring(1, 37);
    if (identityId) {
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.Proficiency });
    }
  }
  catch(error){
    console.log("error",error)
  }
  }
  const FormPoliceOnclick = async (e: any) => {
    try{
    const form: Driver = getValues() as Driver;
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/police/`).toString()
    identityId = identityId?.substring(1, 37);
    if (form.policeFiles && form.policeFiles.length > 0 && identityId) {
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.Police });
    }
  }
  catch(error){
    console.log("error",error)
  }
  }
  const FormHistoryOnclick = async (e: any) => {
    try{
    const form: Driver = getValues() as Driver;
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/history/`).toString()
    identityId = identityId?.substring(1, 37);
    if (form.historyFiles && form.historyFiles.length > 0 && identityId) {
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.History });
    }
  }
  catch(error){
    console.log("error",error)
  }
  }

  return (
    <FormSection
      title={t("driver.verificationSection.title")}
      subtitle={t("driver.verificationSection.subTitle")}
    >
      <div style={{ display: 'flex', flexDirection: 'row', height: '25vh',margin:'10px' }}>
        <div style={{ flex: 0.5 }}>
          <FormUpload
            urls={getUrls(DriverFileType.IdentityFront)}
            label={t("driver.verificationSection.poiLabelFront")}
            name="newIdentityFrontFile"
            originalName="identityFrontFile"
            description={t("driver.verificationSection.poiDescription")}
            FormEditingOnclick={FormIdentityFrontOnclick}
            multiple={false}
            validation={{required:ENABLE_CC}}
            tabIndex={21}
            disableDelete={true}
            {...formProps}
          />
        </div>
        <div style={{ flex: 0.5 }}>
          <FormUpload
            urls={getUrls(DriverFileType.IdentityBack)}
            label={t("driver.verificationSection.poiLabelBack")}
            name="newIdentityBackFile"
            originalName="identityBackFile"
            description={t("driver.verificationSection.poiDescription")}
            FormEditingOnclick={FormIdentityBackOnclick}
            multiple={false}
            validation={{required:ENABLE_CC}}
            tabIndex={22}
            disableDelete={true}

            {...formProps}
          />
        </div>
      </div>
      <div>
        <label className="register-radio">Driver is a not a citizen
          <input
            name="citizen"
            type="checkbox"
            onChange={() => setCitizen(!citizen)}
          />
        </label>
      </div>
      {citizen === true ?
        <FormUpload
          urls={getUrls(DriverFileType.Vevo)}
          label={t("driver.verificationSection.vevoLabel")}
          name="newVevoFiles"
          originalName="vevoFiles"
          description={t("driver.verificationSection.vevoDescription")}
          multiple={true}
          FormEditingOnclick={FormVevoOnclick}
          validation={{required:settings?.driverRequirementVevoFiles?true:false}}
          tabIndex={23}
          {...formProps}
        /> : null}
      <FormUpload
        urls={getUrls(DriverFileType.License)}
        label={t("driver.licenseSection.licenseLabel")}
        name="newLicenseFiles"
        originalName="licenseFiles"
        description={t("driver.licenseSection.licenseDescription")}
        multiple={true}
        FormEditingOnclick={FormLicenseOnclick}
        validation={{required:settings?.driverRequirementLicenseFiles?true:false}}
        tabIndex={24}
        {...formProps}
      />
      {citizen === false ?<FormUpload
        urls={getUrls(DriverFileType.Medicare)}
        label={t("driver.verificationSection.medicareLabel")}
        name="newMedicareFiles"
        originalName="medicareFiles"
        description={t("driver.verificationSection.medicareDescription")}
        multiple={true}
        FormEditingOnclick={FormMedicareOnclick}
        validation={{required:settings?.driverRequirementMedicareCardFiles?true:false}}
        tabIndex={25}
        {...formProps}
      />:null}
      <FormUpload
        urls={getUrls(DriverFileType.Proficiency)}
        label={t("driver.verificationSection.proficiencyLabel")}
        name="newProficiencyFiles"
        originalName="proficiencyFiles"
        description={t("driver.verificationSection.proficiencyDescription")}
        multiple={true}
        FormEditingOnclick={FormProficiencyOnclick}
        validation={{required:settings?.driverRequirementEnglishLanguageProficiencyFiles?true:false}}
        tabIndex={26}
        {...formProps}
      />
      <FormUpload
        urls={getUrls(DriverFileType.Police)}
        label={t("driver.verificationSection.policeCheckLabel")}
        name="newPoliceFiles"
        originalName="policeFiles"
        description={t("driver.verificationSection.policeCheckDescription")}
        multiple={true}
        FormEditingOnclick={FormPoliceOnclick}
        validation={{required:settings?.driverRequirementPoliceFiles?true:false}}
        tabIndex={27}
        {...formProps}
      />
      <FormUpload
        urls={getUrls(DriverFileType.History)}
        label={t("driver.verificationSection.drivingHistoryLabel")}
        name="newHistoryFiles"
        originalName="historyFiles"
        description={t("driver.verificationSection.drivingHistoryDescription")}
        multiple={true}
        FormEditingOnclick={FormHistoryOnclick}
        validation={{required:settings?.driverRequirementHistoryFiles?true:false}}
        tabIndex={28}
        {...formProps}
      />
    </FormSection>
  );

}
