import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormSelect } from "../../../../components/form/input/select.component";
import { FormSection } from "../../../../components/form/section.component";
import { Driver } from "../../../../entities/driver.entity";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../../services/api.service";

let membersDummy: Driver[] = [];
interface props{
  setLeadDriver:any;
  setGroupMembers:any;
  leadDriver:any;
  groupMembers:any

}
export function GroupPostBasic({setGroupMembers,setLeadDriver,leadDriver,groupMembers}:props) {
  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [leadDriverCode, setLeadDriverCode] = useState<string>('');
  const [groupMembersCode, setGroupMembersCode] = useState<string>();
  const [members, setMembers] = useState<Driver[]>([]);
  const [description, setDescription] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorMembersMessage, setErrorMembersMessage] = useState<string>();

  const membersWatch = formProps.watch('members');
  const leaderDriverWatch = formProps.watch('leadDriver');

  const refresh = useCallback(async () => {
    setDrivers([]);
    const { items } = await Api.get<IPaginationResponse<Driver>, IPaginationOptions>(Endpoint.DRIVER_LIST, { page: 0, limit: 100 });
    setDrivers(items);
    setMembers(items);
  }, []);

  useEffect(() => {
    setLeadDriver(leaderDriverWatch)
    setGroupMembers(membersWatch?membersWatch:[])
  }, [membersWatch, leaderDriverWatch])

  useEffect(() => {
    formProps.setValue('leadDriver',leadDriver)
    formProps.setValue('members',groupMembers)
    console.log("FormpProps:",formProps.getValues())
  }, [leadDriver, groupMembers])

  useEffect(() => { refresh(); }, [refresh]);

  const onClick = async (e: any) => {
    e.preventDefault();
    setErrorMessage(undefined);
    console.log("This is Leader Driver Code:", leadDriverCode)
    try {
      if (leadDriverCode) {
        const response = await Api.get<Driver, { code: string }>(Endpoint.DRIVERBYCODE, { code: leadDriverCode.toUpperCase() });
        console.log("This is response Driver Code:", response)
        setLeadDriver(response);
      }
      else { setErrorMessage("Lead Driver Required") }
    } catch (error: any) {
      console.log("This is response Driver Code Error:", error)
      setErrorMessage(error)
    }
  }


  const onLeadDriverRemove = async (e: any) => {
    setLeadDriver(undefined);
    console.log("This is lead driver:", leadDriver);
  }

  const onGroupMemberRemove = async (e: any) => {
    console.log("This is lead groupMembers:", groupMembers);
    setGroupMembers(groupMembers.filter((item:any) => item.id !== e.id))
    console.log("This is lead driver:", leadDriver);
  }

  const onClickGroupMember = async (e: any) => {
    e.preventDefault();
    setErrorMembersMessage(undefined);
    console.log("This is Leader Driver Code:", groupMembersCode)
    try {
      if (groupMembersCode) {
        const response = await Api.get<Driver, { code: string }>(Endpoint.DRIVERBYCODE, { code: groupMembersCode });
        console.log("This is response Driver Code:", response)
        setGroupMembers([...groupMembers, response]);
      }
      else { setErrorMembersMessage("Group Members Required") }
    } catch (error: any) {
      console.log("This is response Driver Code Error:", error)
      setErrorMembersMessage(error)
    }
  }

  return (
    <FormSection
      title={t("group.basicSection.title")}
      subtitle={t("group.basicSection.subTitle")}
    >
      <div className="row">
        <FormInput
          label={t("group.basicSection.title")}
          name="title"
          type="text"
          className="col-md-6"
          validation={{ required: true }}
          tabIndex={1}
          autoFocus
          {...formProps}
        />
      </div>

      <div className="row">
        <FormInput
          label={t("group.basicSection.subTitle")}
          name="description"
          type="text"
          className="col-md-6"
          validation={{ required: true }}
          tabIndex={2}
          {...formProps}
        />
      </div>

      {!leadDriver ? <div className="row">
        {/* <FormSelect<Driver>
          label={t("group.basicSection.driver")}
          name="leadDriver"
          className="col-sm-6"
          showLabel={false}
          validation={{ required: true }}
          items={leadDriver ? [leadDriver]: drivers}
          labelField="firstName"
          multiple={false}
          onChange={(value) => {
            setLeadDriver(value as Driver);
          }}
          tabIndex={3}
          {...formProps}
        /> */}
        <div className="form-group col-sm-6">
          <input
            value={leadDriverCode}
            onChange={(e) => setLeadDriverCode(e.target.value)}
            name={"leadDriver"}
            placeholder={'Group Leader Code'}
            className="form-control"
            type={'text'}
            autoComplete="off"
            required={true}
          />
        </div>
        <div className="col-sm-6">
          <button className="btn btn-primary" style={{ padding: '10px 30px' }} onClick={onClick}> Add </button>
        </div>
        {errorMessage && <div className="help-block form-text with-errors form-control-feedback" style={{ marginLeft: '15px' }}>
          <ul className="list-unstyled"><li>{errorMessage}</li></ul>
        </div>}
      </div> : <div>
        Lead Driver
        <div style={{ margin: '10px' }}>
          <span style={{ color: 'hsl(0deg 0% 20%)', padding: '5px', backgroundColor: 'hsl(0deg 0% 90%)' }}>{leadDriver.firstName} {leadDriver.lastName}
            <div className="close-sign" style={{ display: 'initial' }} onClick={() => onLeadDriverRemove(leadDriver)}><svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </div></span>
        </div>
      </div>}
      <div className="row">
        {/* <FormSelect<Driver>
          label={t("group.basicSection.driver")}
          name="leadDriver"
          className="col-sm-6"
          showLabel={false}
          validation={{ required: true }}
          items={leadDriver ? [leadDriver] : drivers}
          labelField="firstName"
          multiple={false}
          onChange={(value) => {
            setLeadDriver(value as Driver);
          }}
          tabIndex={3}
          {...formProps}
        /> */}
        <div className="form-group col-sm-6">
          <input
            // value={value}
            onChange={(e) => setGroupMembersCode(e.target.value)}
            name={"data"}
            placeholder={'Group Member Code'}
            className="form-control"
            type={'text'}
            autoComplete="off"
          />
        </div>
        <div className="col-sm-6">
          <button className="btn btn-primary" style={{ padding: '10px 30px' }} onClick={onClickGroupMember}> Add </button>
        </div>
        {errorMembersMessage && <div className="help-block form-text with-errors form-control-feedback" style={{ marginLeft: '15px' }}>
          <ul className="list-unstyled"><li>{errorMembersMessage}</li></ul>
        </div>}
      </div>
      {groupMembers && groupMembers.map((item:any) => {
        return <div className="row" style={{ margin: '10px' }}>
          <span style={{ color: 'hsl(0deg 0% 20%)', padding: '5px', backgroundColor: 'hsl(0deg 0% 90%)' }}>{item.firstName} {item.lastName}
            <div className="close-sign" style={{ display: 'initial' }} onClick={() => onGroupMemberRemove(item)}><svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </div></span>
        </div>
      })}
      {/* <FormSelect<Driver>
          label={t("group.basicSection.members")}
          name="members"
          className="col-sm-6"
          showLabel={false}
          validation={{ required: true }}
          items={members}
          multiple={true}
          labelField="firstName"
          onChange={(value) => {
            console.log("This is Value", value)
          }}
          tabIndex={4}
          {...formProps}
        /> */}
    </FormSection>
  );

}
