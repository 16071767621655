import { debounce } from "lodash";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Column, useFilters, usePagination, useSortBy, useTable } from "react-table";
import { FormInput } from "../../../components/form/input/input.component";
import { Currency } from "../../../entities/currency.entity";
import { Session } from "../../../entities/session.entity";
import { Settings } from "../../../entities/settings.entity";
import { ShiftCheckList } from "../../../entities/shiftChecklist";
import { Zone } from "../../../entities/zone.entity";
import { Api, Endpoint } from "../../../services/api.service";
import { AuthService } from "../../../services/auth.service";
import { SettingsService } from "../../../services/settings.service";
import { toSubUnit } from "../../../util/currency";
import { dateFormate } from "../../../util/date.util";
import { getGeneralZoneDistanceTitle, getGeneralZoneDistanceValue } from "../../../util/distance";

interface Sort {
  sortDirection: string | undefined;
  accessor: string | undefined;
}

export interface sessionReport {
  session: Session[];
  count: number;
}

export enum PaymentStatus {
  Paid = "paid",
  Pending = "pending",
  Disputed = "disputed",
}

enum Unit {
  Metric = "metric",
  Imperial = "imperial"
}

export function ShiftReport() {
  const [from, setFrom] = useState<string>('');
  const { t } = useTranslation('dashboard');
  const formProps = useFormContext();
  const [time, setTime] = useState<string>('today');
  const [to, setTo] = useState<string>('');
  const [transaction, setTransaction] = useState<any>("");
  const [pageCounts, setPageCounts] = useState<number>(1);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<Sort>({ sortDirection: undefined, accessor: undefined, });
  const [user, setUser] = useState(AuthService.getUser());
  const [columnData, setColumnData] = useState([]);
  const [rowData, setRowData] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [rowIndex, setRowIndex] = useState<any>();
  const [columnId, setColumnId] = useState<any>();
  const [checkValue, setCheckValue] = useState<any>();
  const [modal, setModal] = useState<boolean>(false);
  const [checklist, setChecklist] = useState<ShiftCheckList[]>([]);
  const [checklistModal, setChecklistModal] = useState<boolean>(false);
  const [msg, setMsg] = useState<any>();
  const [status, setStatus] = useState<PaymentStatus>();
  const [refreshStatus, setRefreshStatus] = useState<boolean>(false);
  const settings: Settings | undefined = SettingsService.getSetting();
  const [intervalId, setIntervalId] = useState<any>();
  const [leftScrollIntervalId, setLeftScrollIntervalId] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [transactionCSV, setTransactionCSV] = useState<any>("");
  const [zoneData,setZoneData] = useState<Zone>();
  const translationReport = useTranslation('main')

  const onChangeTo = (e: any) => {
    setTo(e.target.value)
  }

  const onChangeFrom = (e: any) => {
    setFrom(e.target.value)
  }

  const changeTime = (e: any) => {
    setTime(e.target.value)
  }

  const ref = useRef<any>(null);
  let selectedZone: Zone;
  let unit: any;
  useLayoutEffect(() => {
    getZone()
  },  [transaction,to,from,time])

  const getZone = async () => {

    const zone = await Api.get<any, any>(Endpoint.ZONE_SELECTED, {})
    selectedZone = zone
    unit = selectedZone?.generalCurrency ? (selectedZone?.generalCurrency as Currency).subunits : 100;
    console.log("zone selected",zone)
    setZoneData(zone)
  }

  const getFixed = (): any => {
    const settings: Settings | undefined = SettingsService.getSetting();
    // const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;

    const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
    // const result = value/unit
    const fixed = Math.round((Math.log(unit) / Math.log(10)));
    return fixed;
  }
  const onclick = async (sessionId: string) => {
    const checklistData = await Api.get<ShiftCheckList[], any>(Endpoint.TRANSACTION_GET_CHECKLIST, { id: sessionId });
    setChecklist(checklistData);
    setChecklistModal(true);
  }

  const history = useHistory();
  useEffect(() => {
    if (!columnData) {
      history.push("/dashboard/overview");
    }
  }, [columnData]);


  const scroll = (scrollOffset: any) => {
    ref.current.scrollLeft += scrollOffset;
  };


  const refres = useCallback(
    async (
      limit: number,
      offset: number,
      search?: string,
      direction?: string,
      column?: string
    ) => {  
      setLoading(true);
      if (column && search) {
        let number;
        const unit = selectedZone?.generalCurrency ? (selectedZone?.generalCurrency as Currency).subunits : 100;
        if ((column === 'TotalCCFee') || (column === "Fares") || (column === "Fuel") || (column === "Wash") || (column === "NetRevenue") || (column === "NetBankPayment") || (column === "TotalBookingFeeDeduction") || (column === "TotalTips") || (column === "CreditCardPayments") || (column === "CashPayments") || (column === "TransactionFeeDeduction") || (column === "PayableGST") || (column === "PassengerLevyDeduction") || (column === "VacantTolls") || (column === "StartCharge") || (column === 'TotalFare') || (column === 'GST') || (column === 'Extras') || (column === 'Levy') || (column === 'Tolls') || (column === 'BookingFee') || (column === 'DiscountValue') || (column === 'Tips') || (column === 'ccCharge') || (column === 'WaitTimeFare') || (column === 'DistanceFare')) {
          number = search.split(" ", 2)
          const cashValue = parseInt(number[1]) * unit
          search = `${number[0]} ${cashValue}`
          console.log("search data", search)
        }

        if ((column === 'TotalTravelledDistance') || (column === 'TotalTripDistance')) {
          number = search.split(" ")
          const distance = settings?.generalUnit == Unit.Metric ? parseFloat(number[1]) * 1000 : parseFloat(number[1]) * 1609.34
          search = `${number[0]} ${distance}`
        }
      }
      if (time === 'custome' && from && to) {
        const datetime = new Date();
        if (new Date(from).getFullYear() >= 2000 && new Date(from).getFullYear() <= datetime.getFullYear() && new Date(to).getFullYear() <= datetime.getFullYear()) {
          const trans = await Api.get<sessionReport, any>(
            Endpoint.TRANSACTION_SHIFT_LIST,
            { limit, offset, search, column, direction, time: time, to: to, from: from }
          );
          const currency = selectedZone?.generalCurrency ? (selectedZone?.generalCurrency as Currency).symbol : null;

          const temp = Math.ceil(trans?.count / pageSize)
          setPageCounts(temp)
          let ro: any = [];
          if (trans.session && trans.session.length > 0) {
            setRowData(trans.session);
            trans.session.map((key: any) => {
              ro.push({
                ShiftCode: key.code == null ? "" : <><Link to={`/security/track-shift/${key.code}`}>{key.code}</Link></>,
                TaxiPlate: key.vehicle.plate == null ? "" : key.vehicle.plate,
                DriverId: `${key.driver?.firstName} ${key.driver?.lastName}`,
                ShiftStartTime: dateFormate(key.createTime),
                ShiftEndTime: `${key.endTime == null ? 'Open' : dateFormate(key.endTime)
                  }`,
                TotalOfflineTime:
                  key?.offlineMinutes == null ? "0 Mins" : `${key?.offlineMinutes} Mins`,
                ShiftCheckList: <button onClick={() => onclick(key.id)} className="btn btn-primary">{translationReport.t('reports.shifts.View')}</button>,
                TotalTravelledDistance: key?.totalRideDistance == null
                  ? `${(0 as number).toFixed(3)} ` + `${getGeneralZoneDistanceTitle()}`
                  : `${(key?.totalRideDistance / getGeneralZoneDistanceValue()).toFixed(3)} ${getGeneralZoneDistanceTitle()}`,
                TotalTripDistance: key?.distance == null
                  ? `${(0 as number).toFixed(3)} ` + `${getGeneralZoneDistanceTitle()}`
                  : `${(key?.distance / (getGeneralZoneDistanceValue())).toFixed(3)} ${getGeneralZoneDistanceTitle()}`,
                BookingCounts: key?.bookingCount == null ? "0" : key?.bookingCount,
                HailCounts: key?.tripCount == null ? "0" : key?.tripCount,
                TripCounts: (key?.tripCount + key?.bookingCount) == null ? "0" : key?.tripCount + key?.bookingCount,
                Tolls:
                  key?.totalCharges_Tolls == null
                    ? `${toSubUnit(0)}`
                    : `${toSubUnit(key.totalCharges_Tolls, zoneData?.generalCurrency)}`,
                Extras:
                  key?.totalCharges_Extras == null
                    ? `${toSubUnit(0)}`
                    : `${toSubUnit(key.totalCharges_Extras, zoneData?.generalCurrency)} `,
                TotalCCFee:
                  key?.totalCharges_CCFee == null
                    ? `${toSubUnit(0)}`
                    : `${toSubUnit(key.totalCharges_CCFee, zoneData?.generalCurrency)}`,
                TotalFare:
                  key?.totalCharges_Fares == null
                    ? `${toSubUnit(0)}`
                    : `${toSubUnit(key?.totalCharges_Fares, zoneData?.generalCurrency)}`,
                Fares:
                  key?.totalCharges_Fares == null
                    ? `${toSubUnit(0)}`
                    : `${toSubUnit(key.totalCharges_Fares, zoneData?.generalCurrency)}`,
                Fuel:
                  (key?.fuel == null && key.endTime == null)
                    ? `N/A`
                    : `${toSubUnit(key.fuel, zoneData?.generalCurrency)}`,
                Wash:
                  (key?.wash == null && key.endTime == null)
                    ? `N/A`
                    : `${toSubUnit(key.wash, zoneData?.generalCurrency)}`,
                VacantTolls:
                  (key?.vacantTolls == null && key.endTime == null)
                    ? `N/A`
                    : `${toSubUnit(key.vacantTolls, zoneData?.generalCurrency)}`,
                TotalTips:
                  key?.totalCharges_Tip == null
                    ? `${toSubUnit(0)}`
                    : `${toSubUnit(key.totalCharges_Tip, zoneData?.generalCurrency)}`,
                CreditCardPayments:
                  key?.totalCharges_CC == null
                    ? `${toSubUnit(0)}`
                    : `${toSubUnit(key.totalCharges_CC, zoneData?.generalCurrency)}`,
                CashPayments:
                  key?.totalCharges_Cash == null
                    ? `${toSubUnit(0)}`
                    : `${toSubUnit(key.totalCharges_Cash, zoneData?.generalCurrency)}`,
                TransactionFeeDeduction:
                  key?.totalTransaction_Fee_Deduction == null
                    ? `${toSubUnit(0)}`
                    : ` ${toSubUnit(
                      key.totalTransaction_Fee_Deduction, zoneData?.generalCurrency
                    )}`,
                PassengerLevyDeduction:
                  key?.totalPassenger_Levy_Deduction == null
                    ? ` 0`
                    : ` ${toSubUnit(key.totalPassenger_Levy_Deduction, zoneData?.generalCurrency)}`,
                TotalBookingFeeDeduction:
                  key?.totalBooking_Fee_Deduction == null
                    ? ` 0`
                    : ` ${toSubUnit(key.totalBooking_Fee_Deduction, zoneData?.generalCurrency)}`,
                NetBankPayment:
                  key?.totalNet_Bank == null
                    ? ` 0`
                    : ` ${toSubUnit(key.totalNet_Bank, zoneData?.generalCurrency)}`,
                NetRevenue:
                  (key?.totalNet_Revenue == null || key.endTime == null)
                    ? `N/A`
                    : ` ${toSubUnit(key.totalNet_Revenue, zoneData?.generalCurrency)}`,
                PaymentStatus: key?.paymentStatus,
                PayableGST:
                  key?.totalPayable_GST == null
                    ? ` 0`
                    : ` ${toSubUnit(key.totalPayable_GST, zoneData?.generalCurrency)}`,
              });
            });
          }
          setTransaction(ro);
          setLoading(false)
        }
      } else if (time === 'today' || time === 'yesterday' || time === 'this-week' || time === 'last-week' || time === 'this-month' || time === 'last-month' || time === 'this-year') {
        console.log("new date", new Date())
        const trans = await Api.get<sessionReport, any>(
          Endpoint.TRANSACTION_SHIFT_LIST,
          { limit, offset, search, column, direction, time: time, from: new Date().toISOString() }
        );
        const currency = selectedZone?.generalCurrency ? (selectedZone?.generalCurrency as Currency).symbol : null;
        console.log("trans:", trans);
        console.log("Settings:", settings);
        const temp = Math.ceil(trans?.count / pageSize)
        setPageCounts(temp)
        let ro: any = [];
        if (trans.session && trans.session.length > 0) {
          setRowData(trans.session);
          trans.session.map((key: any) => {
            ro.push({
              ShiftCode: key.code == null ? "" : <><Link to={`/security/track-shift/${key.code}`}>{key.code}</Link></>,
              TaxiPlate: key.vehicle.plate == null ? "" : key.vehicle.plate,
              DriverId: `${key.driver?.firstName} ${key.driver?.lastName}`,
              ShiftStartTime: dateFormate(key.createTime),
              ShiftEndTime: `${key.endTime == null ? 'Open' : dateFormate(key.endTime)
                }`,
              TotalOfflineTime:
                key?.offlineMinutes == null ? "0 Mins" : `${key?.offlineMinutes} Mins`,
              ShiftCheckList: <button onClick={() => onclick(key.id)} className="btn btn-primary">{translationReport.t('reports.shifts.View')}</button>,
              TotalTravelledDistance: key?.totalRideDistance == null
                ? `${(0 as number).toFixed(3)} ` + `${getGeneralZoneDistanceTitle()}`
                : `${(key?.totalRideDistance / getGeneralZoneDistanceValue()).toFixed(3)} ${getGeneralZoneDistanceTitle()}`,
              TotalTripDistance: key?.distance == null
                ? `${(0 as number).toFixed(3)} ` + `${getGeneralZoneDistanceTitle()}`
                : `${(key?.distance / (getGeneralZoneDistanceValue())).toFixed(3)} ${getGeneralZoneDistanceTitle()}`,
              BookingCounts: key?.bookingCount == null ? "0" : key?.bookingCount,
              HailCounts: key?.tripCount == null ? "0" : key?.tripCount,
              TripCounts: (key?.tripCount + key?.bookingCount) == null ? "0" : key?.tripCount + key?.bookingCount,
              Tolls:
                key?.totalCharges_Tolls == null
                  ? `${toSubUnit(0)}`
                  : ` ${toSubUnit(key.totalCharges_Tolls, zoneData?.generalCurrency)}`,
              Extras:
                key?.totalCharges_Extras == null
                  ? `${toSubUnit(0)}`
                  : ` ${toSubUnit(key.totalCharges_Extras, zoneData?.generalCurrency)} `,
              TotalCCFee:
                key?.totalCharges_CCFee == null
                  ? `${toSubUnit(0)}`
                  : ` ${toSubUnit(key.totalCharges_CCFee, zoneData?.generalCurrency)}`,
              TotalFare:
                key?.totalCharges_Fares == null
                  ? `${toSubUnit(0)}`
                  : ` ${toSubUnit(key?.totalCharges_Fares, zoneData?.generalCurrency)}`,
              Fares:
                key?.totalCharges_Fares == null
                  ? `${toSubUnit(0)}`
                  : ` ${toSubUnit(key.totalCharges_Fares, zoneData?.generalCurrency)}`,
              Fuel:
                (key?.fuel == null && key.endTime == null)
                  ? `N/A`
                  : ` ${toSubUnit(key.fuel, zoneData?.generalCurrency)}`,
              Wash:
                (key?.wash == null && key.endTime == null)
                  ? `N/A`
                  : ` ${toSubUnit(key.wash, zoneData?.generalCurrency)}`,
              VacantTolls:
                (key?.vacantTolls == null && key.endTime == null)
                  ? `N/A`
                  : ` ${toSubUnit(key.vacantTolls, zoneData?.generalCurrency)}`,
              TotalTips:
                key?.totalCharges_Tip == null
                  ? `${toSubUnit(0)}`
                  : ` ${toSubUnit(key.totalCharges_Tip, zoneData?.generalCurrency)}`,
              CreditCardPayments:
                key?.totalCharges_CC == null
                  ? `${toSubUnit(0)}`
                  : ` ${toSubUnit(key.totalCharges_CC, zoneData?.generalCurrency)}`,
              CashPayments:
                key?.totalCharges_Cash == null
                  ? `${toSubUnit(0)}`
                  : ` ${toSubUnit(key.totalCharges_Cash, zoneData?.generalCurrency)}`,
              TransactionFeeDeduction:
                key?.totalTransaction_Fee_Deduction == null
                  ? `${toSubUnit(0)}`
                  : ` ${toSubUnit(
                    key.totalTransaction_Fee_Deduction, zoneData?.generalCurrency
                  )}`,
              PassengerLevyDeduction:
                key?.totalPassenger_Levy_Deduction == null
                  ? `${toSubUnit(0)}`
                  : ` ${toSubUnit(key.totalPassenger_Levy_Deduction, zoneData?.generalCurrency)}`,
              TotalBookingFeeDeduction:
                key?.totalBooking_Fee_Deduction == null
                  ? `${toSubUnit(0)}`
                  : ` ${toSubUnit(key.totalBooking_Fee_Deduction, zoneData?.generalCurrency)}`,
              NetBankPayment:
                key?.totalNet_Bank == null
                  ? `${toSubUnit(0)}`
                  : ` ${toSubUnit(key.totalNet_Bank, zoneData?.generalCurrency)}`,
              NetRevenue:
                (key?.totalNet_Revenue == null || key.endTime == null)
                  ? `N/A`
                  : ` ${toSubUnit(key.totalNet_Revenue, zoneData?.generalCurrency)}`,
              PaymentStatus: key?.paymentStatus,
              PayableGST:
                key?.totalPayable_GST == null
                  ? `${toSubUnit(0)}`
                  : ` ${toSubUnit(key.totalPayable_GST, zoneData?.generalCurrency)}`,
            });
          });
        }
        setTransaction(ro);
        setLoading(false);
      }
    },
    [transaction, time, from, to]
  );

  const data = React.useMemo(
    () => {
      return transaction ? [...transaction] : []
    },
    [transaction]
  )

  useEffect(() => {
    if (!checkValue) {
      console.log("sel data", selectedData);
      console.log("sel row data", rowData);
      const remove = selectedData.filter(
        (item: any) => item.id !== rowData[rowIndex].id
      );

      console.log("fil arr", remove);

      setSelectedData(remove);
    } else {
      console.log("update data", selectedData);

      setSelectedData((selectedData: any) => [
        ...selectedData,
        rowData[rowIndex],
      ]);
    }
  }, [checkValue, rowIndex, columnId]);

  const updateMyData = async (rowIndex: any, columnId: any, value: any) => {
    console.log("updateMyData");

    setRowIndex(rowIndex);
    setColumnId(columnId);
    setCheckValue(value);
  };

  const columns: Array<Column<any>> = React.useMemo(() => {
    console.log("col data", columnData);
    const col: any = [
      {
        Header: "CheckBox",
        // accessor: column.replaceAll(" ",""),
        sortType: "basic",
        // sortDirection: sort.accessor === column.replaceAll(" ","") ? sort.sortDirection : undefined,
        Cell: (row: any) => {

          return (
            <div style={{ textAlign: "center" }}>
              {row.cell.row.values.EndTime !== "0" &&
                (row.cell.row.values.PaymentStatus == PaymentStatus.Pending ||
                  row.cell.row.values.PaymentStatus == PaymentStatus.Disputed) ? (
                <input
                  type="checkbox"
                  defaultChecked={row.value == "Yes" ? true : false}
                  onChange={(event) => {
                    updateMyData(
                      parseInt(row.row.id),
                      row.column.id,
                      event.target.checked ? true : false
                    );
                  }}
                />
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ];

    columnData?.map((column: any) => {
      col.push({
        Header: column,
        accessor: column?.replaceAll(" ", ""),
        sortType: "basic",
        sortDirection:
          sort.accessor === column?.replaceAll(" ", "")
            ? sort.sortDirection
            : undefined,
      });
    });

    return col;
  }, [columnData, rowData]);

  useEffect(() => {
    getColumnData();
  }, []);

  const getColumnData = async () => {
    const userId = user?.user.id;
    const columnData = await Api.get<any, any>(Endpoint.Shift, { id: userId });
    console.log("column Data of Shift", columnData);
    setColumnData(columnData[0]?.columns);
    setLoading(true)

  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const { pageSize } = state;

  const getPageCount = async () => {  
    setLoading(true);
    const tempOffset = count * pageSize;
    refres(pageSize, tempOffset, search, sort.sortDirection, sort.accessor);
  };
  const handler = useCallback(debounce((text: string) => {
    setSearch(text)
    setLoading(true)

  }, 1000), []);

  useEffect(() => {
    getPageCount();
  }, [count, search, sort, pageSize, refreshStatus,time,to,from]);

  useEffect(() => {
    setSelectedData([]);
  }, [refreshStatus]);

  const onNextPage = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const onPreviousPage = () => {
    setCount((prevCount) => prevCount - 1);
  };

  const onNextNextPage = () => {
    setCount(pageCounts - 1);
  };

  const onPreviousPreviousPage = () => {
    setCount(0);
  };

  const onClickHeader = (column: any) => {
    switch (column.sortDirection) {
      case undefined:
        column.sortDirection = undefined
        setSort({ sortDirection: undefined, accessor: column.id, });
        break;
      case "ASC":
        column.sortDirection = "DESC";
        setSort({ sortDirection: "DESC", accessor: column.id });
        break;
      case "DESC":
        column.sortDirection = undefined;
        setSort({ sortDirection: undefined, accessor: column.id });
        break;
    }
  };

  const onChangeSeaech = (e: any, column: any) => {
    handler(e.target.value)
  }

  const handleConfirmation = async (paymentStatus: PaymentStatus) => {
    switch (paymentStatus) {
      case PaymentStatus.Disputed:
        setMsg("Mark selected shifts as disputed?");
        setStatus(PaymentStatus.Disputed);
        break;
      case PaymentStatus.Paid:
        setMsg(
          "Send payments to drivers, operators, network operators for selected shifts?"
        );
        setStatus(PaymentStatus.Paid);
        break;
      case PaymentStatus.Pending:
        setMsg("Mark selected shifts as pending?");
        setStatus(PaymentStatus.Pending);
        break;
    }
    // if (paymentStatus === PaymentStatus.Disputed) {
    //   setMsg("Mark selected shifts as disputed?");
    //   setStatus(PaymentStatus.Disputed);
    // } else {
    //   setMsg(
    //     "Send payments to drivers, operators, network operators for selected shifts?"
    //   );
    //   setStatus(PaymentStatus.Paid);
    // // }

    setModal(true);
  };

  const statusChange = async () => {
    const response = await Api.patch<any, any>(Endpoint.UPDATE_SHIFT_STATUS, {
      paymentStatus: status,
      selectedData,
    });
    console.log("response", response);
    if (response) {
      setRefreshStatus(!refreshStatus);
      setModal(false);
    }
  };

  const handleRightScroll = () => {

    setIntervalId(
      setInterval(() => {
        scroll(200)
      }, 50)
    )
  }

  const handleLeftScroll = () => {

    setLeftScrollIntervalId(
      setInterval(() => {
        scroll(-200)
      }, 50)
    )
  }

  useEffect(()=>{
    handleCSV()
  },[search,time,from,to])

  async function handleCSV (){
    const CSVData = await Api.get<sessionReport, any>(
      Endpoint.TRANSACTION_SHIFT_LIST, { limit:999,search:search, column:sort.accessor, direction:sort.sortDirection,time: time, to: to, from: from});
    let ro:any = [];
    console.log("CSVData:",CSVData)
    const currency = zoneData?.generalCurrency ? (zoneData?.generalCurrency as Currency).symbol : null;
    if (CSVData && CSVData?.session && CSVData?.session.length > 0) {
      CSVData?.session.map((key: any) => {
        ro.push({
          ShiftCode: key.code == null ? "" : key.code,
                TaxiPlate: key.vehicle.plate == null ? "" : key.vehicle.plate,
                DriverId: `${key.driver?.firstName} ${key.driver?.lastName}`,
                ShiftStartTime: dateFormate(key.createTime),
                ShiftEndTime: `${key.endTime == null ? 'Open' : dateFormate(key.endTime)
                  }`,
                TotalOfflineTime:
                  key?.offlineMinutes == null ? "0 Mins" : `${key?.offlineMinutes} Mins`,
                ShiftCheckList: <button onClick={() => onclick(key.id)} className="btn btn-primary">{translationReport.t('reports.shifts.View')}</button>,
                TotalTravelledDistance: key?.totalRideDistance == null
                  ? `${(0 as number).toFixed(3)} ` + `${getGeneralZoneDistanceTitle()}`
                  : `${(key?.totalRideDistance / getGeneralZoneDistanceValue()).toFixed(3)} ${getGeneralZoneDistanceTitle()}`,
                TotalTripDistance: key?.distance == null
                  ? `${(0 as number).toFixed(3)} ` + `${getGeneralZoneDistanceTitle()}`
                  : `${(key?.distance / (getGeneralZoneDistanceValue())).toFixed(3)} ${getGeneralZoneDistanceTitle()}`,
                BookingCounts: key?.bookingCount == null ? "0" : key?.bookingCount,
                HailCounts: key?.tripCount == null ? "0" : key?.tripCount,
                TripCounts: (key?.tripCount + key?.bookingCount) == null ? "0" : key?.tripCount + key?.bookingCount,
                Tolls:
                  key?.totalCharges_Tolls == null
                    ? `${toSubUnit(0)}`
                    : ` ${toSubUnit(key.totalCharges_Tolls,zoneData?.generalCurrency)}`,
                Extras:
                  key?.totalCharges_Extras == null
                    ? `${toSubUnit(0)}`
                    : ` ${toSubUnit(key.totalCharges_Extras,zoneData?.generalCurrency)} `,
                TotalCCFee:
                  key?.totalCharges_CCFee == null
                    ? `${toSubUnit(0)}`
                    : ` ${toSubUnit(key.totalCharges_CCFee,zoneData?.generalCurrency)}`,
                TotalFare:
                  key?.totalCharges_Fares == null
                    ? `${toSubUnit(0)}`
                    : ` ${toSubUnit(key?.totalCharges_Fares,zoneData?.generalCurrency)}`,
                Fares:
                  key?.totalCharges_Fares == null
                    ? `${toSubUnit(0)}`
                    : ` ${toSubUnit(key.totalCharges_Fares,zoneData?.generalCurrency)}`,
                Fuel:
                  (key?.fuel == null && key.endTime == null)
                    ? `N/A`
                    : ` ${toSubUnit(key.fuel,zoneData?.generalCurrency)}`,
                Wash:
                  (key?.wash == null && key.endTime == null)
                    ? `N/A`
                    : ` ${toSubUnit(key.wash,zoneData?.generalCurrency)}`,
                VacantTolls:
                  (key?.vacantTolls == null && key.endTime == null)
                    ? `N/A`
                    : ` ${toSubUnit(key.vacantTolls,zoneData?.generalCurrency)}`,
                TotalTips:
                  key?.totalCharges_Tip == null
                    ? `${toSubUnit(0)}`
                    : ` ${toSubUnit(key.totalCharges_Tip,zoneData?.generalCurrency)}`,
                CreditCardPayments:
                  key?.totalCharges_CC == null
                    ? `${toSubUnit(0)}`
                    : ` ${toSubUnit(key.totalCharges_CC,zoneData?.generalCurrency)}`,
                CashPayments:
                  key?.totalCharges_Cash == null
                    ? `${toSubUnit(0)}`
                    : ` ${toSubUnit(key.totalCharges_Cash,zoneData?.generalCurrency)}`,
                TransactionFeeDeduction:
                  key?.totalTransaction_Fee_Deduction == null
                    ? `${toSubUnit(0)}`
                    : ` ${toSubUnit(
                      key.totalTransaction_Fee_Deduction,zoneData?.generalCurrency
                    )}`,
                PassengerLevyDeduction:
                  key?.totalPassenger_Levy_Deduction == null
                    ? `${toSubUnit(0)}`
                    : ` ${toSubUnit(key.totalPassenger_Levy_Deduction,zoneData?.generalCurrency)}`,
                TotalBookingFeeDeduction:
                  key?.totalBooking_Fee_Deduction == null
                    ? `${toSubUnit(0)}`
                    : ` ${toSubUnit(key.totalBooking_Fee_Deduction,zoneData?.generalCurrency)}`,
                NetBankPayment:
                  key?.totalNet_Bank == null
                    ? `${toSubUnit(0)}`
                    : ` ${toSubUnit(key.totalNet_Bank,zoneData?.generalCurrency)}`,
                NetRevenue:
                  (key?.totalNet_Revenue == null || key.endTime == null)
                    ? `N/A`
                    : ` ${toSubUnit(key.totalNet_Revenue,zoneData?.generalCurrency)}`,
                PaymentStatus: key?.paymentStatus,
                PayableGST:
                  key?.totalPayable_GST == null
                    ? `${toSubUnit(0)}`
                    : ` ${toSubUnit(key.totalPayable_GST,zoneData?.generalCurrency)}`,
        });
      });
      
      setTransactionCSV([...ro])
    }
   
  }
  
  const clickMethod = (column: any) => {
    console.log("column",column)
    // history.push(`/security/track-shift/${column.original.ShiftCode}`);
  }


  return (
    <>  <Modal
      open={checklistModal}
      onClose={() => {
        setChecklistModal(false);
        return false;
      }}
      styles={{ modal: { width: '30%' } }}
      center
    >
      <table className="table table-striped" style={{ marginTop: '20px', fontSize: '20px' }}>
        <thead>
          <tr>
            <th scope="col" style={{ fontSize: '20px' }}>CheckList Item</th>
          </tr>
        </thead>
        <tbody>

          {checklist.map((item) => {
            return item.checked ?
              <tr style={{ marginTop: '10px', background: '#69bd61', textTransform: 'capitalize', color: 'white', fontWeight: "" }}>
                <td style={{ textTransform: 'uppercase' }}>{item.checklistItem}</td>
              </tr> :
              <tr style={{ marginTop: '50px', background: '#e65252', color: 'white', fontWeight: "" }}>
                <td style={{ textTransform: 'uppercase' }}>{item.checklistItem}</td>
              </tr>
          })
          }
        </tbody>
      </table>

    </Modal>
      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
          return false;
        }}
        center
      >
        <h5
          style={{ margin: "inherit", padding: "inherit" }}
          className="modal-title"
        >
          {msg}
        </h5>
        <div className="modal-footer" style={{ justifyContent: "center" }}>
          <button
            type="button"
            onClick={statusChange}
            className="btn btn-primary btn-lg"
          >
            Yes
          </button>
          <button
            type="button"
            onClick={() => setModal(false)}
            className="btn btn-danger btn-lg"
            data-dismiss="modal"
          >
            No
          </button>
        </div>
      </Modal>
      <div><div className="py text-center" style={{ display: 'flow-root' }}>
        {transaction ? <CSVLink
          style={{ float: "right", marginTop: "26px", marginRight: "20px" }}
          filename={"shift-report.csv"}
          className="btn btn-primary"
          data={transactionCSV}
        >
          {translationReport.t('reports.exportCSV')}
        </CSVLink> : null}
        <button style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className='btn btn-primary' onClick={() => { scroll(200) }} onMouseDown={handleRightScroll} onMouseUp={() => clearInterval(intervalId)} onMouseLeave={() => clearInterval(intervalId)}>{'>'}</button>
        <button style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className='btn btn-primary' onClick={() => { scroll(-200) }} onMouseDown={handleLeftScroll} onMouseUp={() => clearInterval(leftScrollIntervalId)} onMouseLeave={() => clearInterval(leftScrollIntervalId)}>{'<'}</button>
        {loading == true ? <button disabled onClick={(e) => { e.preventDefault(); getPageCount() }} style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className={`btn btn-primary`}>
          Loading.....</button> : <button onClick={(e) => { e.preventDefault(); getPageCount() }} style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className={`btn btn-primary os-icon os-icon-refresh-ccw`}>
        </button>}

        {selectedData?.length > 0 ? (
          <>
            <button
              style={{
                float: "right",
                marginTop: "26px",
                marginRight: "20px",
              }}
              onClick={() => {
                handleConfirmation(PaymentStatus.Pending);
              }}
              className="btn btn-warning"
            >
              Mark Clear
            </button>
            <button
              style={{
                float: "right",
                marginTop: "26px",
                marginRight: "20px",
              }}
              onClick={() => {
                handleConfirmation(PaymentStatus.Disputed);
              }}
              className="btn btn-danger"
            >
              Mark Dispute
            </button>
            <button
              style={{
                float: "right",
                marginTop: "26px",
                marginRight: "20px",
              }}
              onClick={() => {
                handleConfirmation(PaymentStatus.Paid);
              }}
              className="btn btn-success"
            >
              Execute Payment
            </button>

          </>
        ) : (
          ""
        )}
      </div>
        <div> {time === 'custome' ?
          <>
            <div className="row">
              <FormInput
                label={t("From")}
                name="from"
                type="date"
                onChange={onChangeFrom}
                className="col-sm-3"
                validation={{ required: true }}
                tabIndex={7}
                {...formProps}
              />
              <FormInput
                label={t("To")}
                name="to"
                type="date"
                onChange={onChangeTo}
                className="col-sm-3"
                validation={{ required: true }}
                tabIndex={7}
                {...formProps}
              />
            </div>

          </> : null}
          <div style={{ marginRight: '15px', marginBottom: '10px', marginTop: '15px' }}>
            <form className="form-inline justify-content-sm-end">
              <select value={time} onChange={changeTime} className="form-control form-control-sm rounded">
                <option value="today">{t('dropdown.today')}</option>
                <option value="yesterday">{t('dropdown.yesterday')}</option>
                <option value="this-week">{t('dropdown.thisWeek')}</option>
                <option value="last-week">{t('dropdown.lastWeek')}</option>
                <option value="this-month">{t('dropdown.thisMonth')}</option>
                <option value="last-month">{t('dropdown.lastMonth')}</option>
                <option value="this-year">{t('dropdown.thisYear')}</option>
                <option value="custome">{t('dropdown.custome')}</option>
              </select>
            </form>
          </div> </div>
        <div
          ref={ref}
          style={{
            marginLeft: "5px",
            marginRight: "5px",
            overflowX: "scroll",
            // display: 'inline',
            width: "100%",
          }}
          className="text-center r2"
        >
          <table
            {...getTableProps()}
            className="table table-striped table-hover table-bordered"
          >
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps(
                        column.getSortByToggleProps()
                      )}
                      onClick={() => onClickHeader(column)}
                      className="bg-primary text-white"
                    >
                      {column.Header !== "CheckBox"
                        ? column.render('Header')?translationReport.t(`reports.shifts.${column.id}`):""
                        : ""}
                      <span>
                        {column.sortDirection === "ASC"
                          ? " 🔽"
                          : column.sortDirection === "DESC"
                            ? " 🔼"
                            : null}
                      </span>
                      <div>
                        <span>
                          {column.Header !== "CheckBox" ? (
                            <input
                              onChange={(e) => onChangeSeaech(e, column)}
                            />
                          ) : null}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {transaction?.length>0?<tbody {...getTableBodyProps()}>
              {page.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}  onClick={() => clickMethod(row)}>
                    {/* <td>
                       <input type="checkbox" />
                          </td> */}
                    {row.cells.map((cell: any) => {
                      return (
                        <>
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>:<tbody>
              <tr>
              <td colSpan={columnData.length-12}>
                  No results to display
                  </td>
              </tr>
            </tbody>}
          </table>
        </div>
        <div className=" py-5 text-center">
          <span>
          {translationReport.t('reports.page')}{' '}
            <strong>
              {count + 1} {translationReport.t("reports.of")} {pageCounts}
            </strong>{' '}
          </span>
          <span>
            | {translationReport.t("reports.goPage")}{''}
            <input
              type='number'
              defaultValue={count + 1}
              onChange={(e: any) => {
                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                setCount(pageNumber)
              }}
              style={{ marginRight: "10px", width: "70px", marginLeft: "5px", borderRadius: "5px" }}
            />
          </span>
          <select
            value={pageSize}
            onChange={(e: any) => setPageSize(Number(e.target.value))}
            style={{ marginRight: "10px", width: "80px", marginLeft: "5px", borderRadius: "5px" }}
          >
            {[10, 25, 50, 100].map((pageSize: any) => {
              return <option key={pageSize} value={pageSize}>
                {translationReport.t('reports.show')} {pageSize}
              </option>
            })}
          </select>
          <button type="button" className="btn btn-primary" onClick={() => onPreviousPreviousPage()} disabled={count > 0 ? false : true}>{'<<'}</button>
          <button type="button" style={{ marginRight: "10px", marginLeft: "10px" }} className="btn btn-primary" onClick={() => onPreviousPage()} disabled={count > 0 ? false : true}>{translationReport.t('reports.previous')}</button>
          <button type="button" style={{ marginRight: "10px" }} className="btn btn-primary" onClick={() => onNextPage()} disabled={(count + 1) < pageCounts ? false : true}>{translationReport.t('reports.next')}</button>
          <button type="button" className="btn btn-primary" onClick={() => onNextNextPage()} disabled={(count + 1) < pageCounts ? false : true}>{'>>'}</button>
        </div>
      </div>

    </>
  );
}
