import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { Column, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { Loading } from '../../../components/alerts/loading.component';
import { Currency } from '../../../entities/currency.entity';
import { Settings } from '../../../entities/settings.entity';
import { Api, Endpoint } from '../../../services/api.service';
import { AuthService } from '../../../services/auth.service';
import { SettingsService } from '../../../services/settings.service';
import { getGeneralZoneDistanceTitle, getGeneralZoneDistanceValue } from '../../../util/distance';
import { transactionReport } from './list.component';
import { debounce } from "lodash";
import { Zone } from '../../../entities/zone.entity';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormInput } from '../../../components/form/input/input.component';
import { dateFormate } from '../../../util/date.util';


interface Sort {
  sortDirection: string | undefined,
  accessor: string | undefined
}

interface props {
  isCorporate?: boolean | undefined,
  cooperateCol?: any
}

enum Unit {
  Metric = "metric",
  Imperial = "imperial"
}

export function RideReport({ isCorporate, cooperateCol }: props) {

  let history = useHistory();  
  const [from, setFrom] = useState<string>('');
  const { t } = useTranslation('dashboard');
  const translationReport = useTranslation('main')
  const formProps = useFormContext();
  const [time, setTime] = useState<string>('today');
  const [to, setTo] = useState<string>('');
  const [transaction, setTransaction] = useState<any>(undefined)
  const [transactionCSV, setTransactionCSV] = useState<any>("")
  const [pageCounts, setPageCounts] = useState<number>(1);
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState<string>('')
  const [itemCount, setItemCount] = useState<number>(0)
  const [user, setUser] = useState(AuthService.getUser());
  const [columnData, setColumnData] = useState([]);
  const [sort, setSort] = useState<Sort>({ sortDirection: undefined, accessor: undefined });
  const [generalTimezone, setGeneralTimezone] = useState();
  const ref = useRef<any>(null);
  const [intervalId,setIntervalId] = useState<any>();
  const [leftScrollIntervalId,setLeftScrollIntervalId] = useState<any>();
  const [loading,setLoading] = useState<boolean>(true)
  let selectedZone:Zone;
  const [zoneData,setZoneData] = useState<Zone>();

  const onChangeTo = (e: any) => {
    setTo(e.target.value)
  }

  const onChangeFrom = (e: any) => {
    setFrom(e.target.value)
  }

  const changeTime = (e: any) => {
    setTime(e.target.value)
  }

  useLayoutEffect(()=>{
    getZone()
  }, [transaction,to,from,time])

  const getZone = async()=>{

    const zone=  await Api.get<any, any>(Endpoint.ZONE_SELECTED, {})
    selectedZone =zone
    setZoneData(zone)
    
   
  }


  const toSubUnit = (value: number): any => {
    const unit = selectedZone?.generalCurrency ? (selectedZone?.generalCurrency as Currency).subunits : 100;
    const fixed = Math.round((Math.log(unit) / Math.log(10)));
    const result = (value / unit).toFixed(fixed)
    return result;
  }
  // const dateFormate = (date: any) => {
  //   const settings: Settings | undefined = SettingsService.getSetting();
  //   let d: any = new Date(date)
  //   return d.toLocaleString(undefined, { timeZone: (settings as Settings)?.zone?.generalTimezone });

  // }

  const scroll = (scrollOffset:any) => {
    ref.current.scrollLeft += scrollOffset;
  };

  let sum = 0;
  let sum1 = 0;
  let sum2 = 0;
  let sum3 = 0;
  let sum4 = 0;
  let sum5 = 0;
  let sum6 = 0;
  let sum7 = 0;
  let sum8 = 0;
  let sum9 = 0;
  let sum10 = 0;
  let sum11 = 0;
  let sum12 = 0;
  let sum13 = 0;
  let sum14 = 0;



  const refresh = useCallback(async (limit: number, offset: number, search?: string, direction?: string, column?: string, total?: any, sumVal?: any) => {
    setLoading(true);
    if (column && search) {
      let number;
      const unit = selectedZone?.generalCurrency ? (selectedZone?.generalCurrency as Currency).subunits : 100;
      // for distance
      if ((column ===  'TotalCCFee') || (column === "StartCharge") || (column ==='TotalFare') || (column ==='GST') || (column ==='Extras') || (column === 'Levy') || (column === 'Tolls') || (column === 'BookingFee') || (column === 'DiscountValue') || (column ==='Tips') || (column === 'ccCharge') || (column === 'WaitTimeFare') || (column === 'DistanceFare') ) {
        number = search.split(" ", 2)
        const cashValue = parseInt(number[1]) * unit
        search = `${number[0]} ${cashValue}`
        console.log("search data", search)
      }
      if(column === 'Distance'){
        number = search.split(" ")
        console.log("distance no ",number)
        const distance = selectedZone?.generalUnit == Unit.Metric ? parseFloat(number[1])* 1000: parseFloat(number[1])* 1609.34
        search = `${number[0]} ${distance}`
        console.log("distance",search)
      }
    }
    let trans:transactionReport|undefined=undefined;
    if (time === 'custome' && from && to) {
      const datetime = new Date();
      if (new Date(from).getFullYear() >= 2000 && new Date(from).getFullYear() <= datetime.getFullYear() && new Date(to).getFullYear() <= datetime.getFullYear()) {
     trans = await Api.get<transactionReport, any>(Endpoint.TRANSACTION_RIDES_LIST, { limit, offset, search, column, direction, isCorporate,time: time, to: to, from: from });
     const temp = Math.ceil(trans?.count / pageSize)
      const tempOffset = count * pageSize
      setPageCounts(temp)
    }} 
    else if (time === 'today' || time === 'yesterday' || time === 'this-week' || time === 'last-week' || time === 'this-month' || time === 'last-month' || time === 'this-year') 
    {
       trans = await Api.get<transactionReport, any>(Endpoint.TRANSACTION_RIDES_LIST, { limit, offset, search, column, direction, isCorporate,time: time, from: new Date()  });
       const temp = Math.ceil(trans?.count / pageSize)
      const tempOffset = count * pageSize
      setPageCounts(temp)
      }
      console.log("trans",trans)

    const currency = selectedZone?.generalCurrency ? (selectedZone?.generalCurrency as Currency).symbol : null;
      let ro: any = [];
      if (trans && trans?.transaction && trans?.transaction.length > 0) {
        trans?.transaction.map((key: any) => {
          sum = sum + (key.trip?.distance);
          sum1 = sum1 + key.trip?.waitCharge;
          sum2 = sum2 + key.trip?.totalFare;
          sum3 = sum3 + key.trip?.distanceCharge;
          sum4 = sum4 + key.trip?.tax;
          sum5 = sum5 + key.trip?.extraCharge;
          sum6 = sum6 + key.trip?.tolls;
          sum7 = sum7 + key.trip?.tip;
          sum10 = sum10 + key.trip?.ccCharge;
          sum8 = sum8 + (key.trip?.rideTime? key.trip.rideTime:0);
          sum9 = sum9 + (key.trip?.waitTime?key.trip?.waitTime:0);
          sum11 = sum11 + key.trip?.discountValue;
          sum12 = sum12 + key.trip?.levy;
          sum13 = sum13 + key.trip?.bookingCharge;
          sum14 = sum14 + key.trip?.startCharge
          ro.push({
            'TripCode': key.trip?.code == null ? "" : key.trip.code,
            'CreateTime': dateFormate(key.createTime),
            'TransactionID': key.shortId == null ? "" : key.shortId,
            'VehiclePlate': key.trip?.session?.vehicle?.plate == null ? "" : key.trip?.session?.vehicle?.plate,
            'StartTime': dateFormate(key.trip?.startTime),
            'EndTime': `${key.trip?.endTime == null ? 0 : dateFormate(key.trip.endTime)}`,
            'Distance': key.trip?.distance == null ? `${(0 as number).toFixed(3)}` + `${getGeneralZoneDistanceTitle()}` : `${(key.trip.distance / getGeneralZoneDistanceValue()).toFixed(3)} ${getGeneralZoneDistanceTitle()}`,
            'WaitTime': `${key.trip?.waitTime == null ? 0 : new Date(key.trip.waitTime * 1000)?.toISOString().substr(11, 8)}`,
            'TotalCCFee': key.trip?.ccCharge == null ? "" : `${currency} ${toSubUnit(key.trip.ccCharge)}`,
            'StartCharge': key.trip?.startCharge == null ? "" : `${currency} ${toSubUnit(key.trip.startCharge)}`,
            'TotalFare': key.trip?.totalFare == null ? "" : `${currency} ${toSubUnit(key.trip.totalFare)}`,
            'TotalTime': key.trip?.rideTime == null ? "" : new Date(key.trip.rideTime * 1000).toISOString().substr(11, 8),
            "GST": key.trip?.tax == null ? "" : `${currency} ${toSubUnit(key.trip.tax)}`,
            "Extras": key.trip?.extraCharge == null ? "" : `${currency} ${toSubUnit(key.trip.extraCharge)}`,
            "Levy": key.trip?.levy == null ? "" : `${currency} ${toSubUnit(key.trip.levy)}`,
            "Tolls": key.trip?.tolls == null ? "" : `${currency} ${toSubUnit(key.trip.tolls)}`,
            "BookingFee": key.trip?.bookingCharge == null ? "" : `${currency} ${toSubUnit(key.trip.bookingCharge)}`,
            "Type": key.trip?.type == null ? "" : key.trip.type,
            "RateType": key.trip?.rateType == null ? "" : key.trip.rateType,
            "DiscountType": key.trip?.discountType == null ? "" : key.trip.discountType,
            "DiscountValue": key.trip?.discountValue == null ? "" : `${currency} ${toSubUnit(key.trip.discountValue)}`,
            "Tips": key.trip?.tip == null ? "" : `${currency} ${toSubUnit(key.trip.tip)}`,
            "ccCharge": key.trip?.ccCharge == null ? "" : `${currency} ${toSubUnit(key.trip.ccCharge)}`,
            'WaitTimeFare': key.trip?.waitCharge == null ? "" : `${currency} ${toSubUnit(key.trip.waitCharge)}`,
            'DistanceFare': key.trip?.distanceCharge == null ? "" : `${currency} ${toSubUnit(key.trip.distanceCharge)}`,
            "Service": key.trip?.service.title == null ? "" : key.trip.service.title,
            'Zone': key.trip?.zone.title == null ? "" : key.trip.zone.title,
            'Fare': key.trip?.fare.title == null ? "" : key.trip.fare.title,
            'DriverID': key.trip?.session == null ? "" : `${key.trip?.session.driver.firstName} ${key.trip.session.driver.lastName}`,
            'Passenger': key.trip?.passenger?.firstName == undefined ? "" : `${key.trip?.passenger?.firstName} ${key.trip?.passenger?.lastName}`,
            'CompanyName': key.trip?.booking?.corporateBooking.companyName == null ? "" : `${key.trip?.booking?.corporateBooking.companyName}`,
            'ShiftCode': key.trip?.session.code == null ? "" : key.trip?.session.code,
          });
        })
        ro = [...ro, {
  
          'TripCode': "",
          'CreateTime': "",
          'TransactionID': "",
          'VehiclePlate': "",
          'StartTime': "",
          'EndTime': "",
          'Distance': <><strong> Sub Total : </strong>{new Intl.NumberFormat().format(sum / (getGeneralZoneDistanceValue()))} {`${getGeneralZoneDistanceTitle()}`}</>,
          'WaitTime': <><strong> Sub Total : </strong> {new Date(sum9 * 1000)?.toISOString().substr(11, 8)}</>,
          'StartCharge': <><strong> Sub Total : </strong>{currency} {toSubUnit(sum14)}</>,
          'TotalFare': <><strong> Sub Total : </strong>{currency} {toSubUnit(sum2)}</>,
          'TotalTime': <><strong> Sub Total : </strong>{new Date(sum8 * 1000).toISOString().substr(11, 8)}</>,
          "GST": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum4)}</>,
          "Extras": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum5)}</>,
          "Levy": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum12)}</>,
          "Tolls": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum6)}</>,
          "BookingFee": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum13)}</>,
          "Type": "",
          "Tips": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum7)}</>,
          "TotalCCFee": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum10)}</>,
          'WaitTimeFare': <><strong> Sub Total : </strong>{currency} {toSubUnit(sum1)}</>,
          'DistanceFare': <><strong> Sub Total : </strong>{currency} {toSubUnit(sum3)}</>,
          'DiscountValue': <><strong> Sub Total : </strong>{currency} {toSubUnit(sum11)}</>,
          "Service": "",
          'Zone': "",
          'Fare': "",
          'Driver': "",
          'Passenger': "",
  
        }
          , {
          'TripCode': "",
          'CreateTime': "",
          'TransactionID': "",
          'VehiclePlate': "",
          'StartTime': "",
          'EndTime': "",
          'Distance': <>{sumVal && !search ? <><strong>Total : </strong>{new Intl.NumberFormat().format(sumVal[1]?.sumDistance / (getGeneralZoneDistanceValue()))} {getGeneralZoneDistanceTitle()}</>:<></>}</>,
          'WaitTime': <>{sumVal && !search ? <><strong>Total : </strong> {new Date(sumVal[4]?.sumWaitTime * 1000).toISOString().substr(11, 8)}</> : <></>}</>,
          'TotalTime': <>{sumVal && !search ? <><strong>Total : </strong> {new Date(sumVal[5]?.sumRideTime * 1000).toISOString().substr(11, 8)}</> : <> </>}</>,
          "GST": <>{sumVal && !search ? <><strong>Total : </strong>{currency} {toSubUnit(sumVal[6]?.sumTax)}</> : <></>}</>,
          "Extras": <>{sumVal && !search ? <><strong>Total : </strong>{currency} {toSubUnit(sumVal[9]?.sumExtraCharge)}</> : <></>}</>,
          "Levy": <>{sumVal && !search ? <><strong>Total : </strong>{currency} {toSubUnit(sumVal[12]?.sumLevyCharge)}</> : <></>}</>,
          "Tolls": <>{sumVal && !search ? <><strong>Total : </strong>{currency} {toSubUnit(sumVal[8]?.sumToll)}</> : <></>}</>,
          "BookingFee": <>{sumVal && !search ? <><strong>Total : </strong>{currency} {toSubUnit(sumVal[13]?.sumBookingCharge)}</> : <></>}</>,
          "StartCharge": <>{sumVal && !search ? <><strong>Total : </strong>{currency} {toSubUnit(sumVal[14]?.sumStartCharge)}</> : <></>}</>,
          "Type": "",
          "Tips": <>{sumVal && !search ? <><strong>Total : </strong>{currency} {toSubUnit(sumVal[7]?.sumTip)}</> : <></>}</>,
          "TotalCCFee": <>{sumVal && !search ? <><strong>Total : </strong>{currency} {toSubUnit(sumVal[10]?.sumCcCharge)}</> : <></>}</>,
          'WaitTimeFare': <>{sumVal && !search ? <><strong>Total : </strong>{currency} {toSubUnit(sumVal[3]?.sumWaitCharge)}</> : <></>}</>,
          'DistanceFare': <>{sumVal && !search ? <><strong>Total : </strong>{currency} {toSubUnit(sumVal[2]?.sumDistanceCharge)}</> : <></>}</>,
          'DiscountValue': <>{sumVal && !search ? <><strong>Total : </strong>{currency} {toSubUnit(sumVal[11]?.sumDiscountValue)}</> : <></>}</>,
          "Service": "",
          'Zone': "",
          'Fare': "",
          'Driver': "",
          'Passenger': "",
          'TotalFare': <>{sumVal && !search ? <><strong>Total : </strong>{currency} {toSubUnit(sumVal[15]?.sumTotalFare)}</> : <></>}</> ,
  
        }
  
  
        ]
        
      }
      setTransaction(ro)
      setLoading(false)
  }, [transaction,time,from,to])

  const data = React.useMemo(
    () => {
      return transaction ?[...transaction]:[]
    },
    [transaction]
  )

  // const columns: Array<Column<any>> = React.useMemo(
  //   () => [
  //     {
  //       Header: 'Trip Code',
  //       accessor: 'TripCode',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'TripCode' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'CreateTime',
  //       accessor: 'CreateTime',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'CreateTime' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'Transaction ID',
  //       accessor: 'TransactionID',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'TransactionID' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'Vehicle Plate',
  //       accessor: 'VehiclePlate',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'VehiclePlate' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'StartTime',
  //       accessor: 'StartTime',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'StartTime' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'EndTime',
  //       accessor: 'EndTime',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'EndTime' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'DistanceInKilometers',
  //       accessor: 'DistanceInKilometers',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'DistanceInKilometers' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'Wait Time',
  //       accessor: 'WaitTime',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'WaitTime' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'Total Fare',
  //       accessor: 'TotalFare',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'TotalFare' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'Total Time',
  //       accessor: 'TotalTime',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'TotalTime' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'GST',
  //       accessor: 'GST',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'GST' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'Extras',
  //       accessor: 'Extras',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'Extras' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'Tolls',
  //       accessor: 'Tolls',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'Tolls' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'Type',
  //       accessor: 'Type',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'Type' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'Tips',
  //       accessor: 'Tips',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'Tips' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'ccCharge',
  //       accessor: 'ccCharge',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'ccCharge' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'WaitTimeFare',
  //       accessor: 'WaitTimeFare',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'WaitTimeFare' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'DistanceFare',
  //       accessor: 'DistanceFare',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'DistanceFare' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'Service',
  //       accessor: 'Service',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'Service' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'Zone',
  //       accessor: 'Zone',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'Zone' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'Fare',
  //       accessor: 'Fare',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'Fare' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'Driver',
  //       accessor: 'Driver',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'Driver' ? sort.sortDirection : undefined
  //     },
  //     {
  //       Header: 'Passenger',
  //       accessor: 'Passenger',
  //       sortType: 'basic',
  //       sortDirection: sort.accessor === 'Passenger' ? sort.sortDirection : undefined
  //     },
  //   ],
  //   []
  // )

  const columns: Array<Column<any>> = React.useMemo(
    () => {
      const col: any = [];
      columnData?.map((column: any) => {
        col.push({
          Header: column,
          accessor: column?.replaceAll(" ", ""),
          sortType: 'basic',
          sortDirection: sort.accessor === column?.replaceAll(" ", "") ? sort.sortDirection : undefined

        })


      })

      return col
    }

    , [columnData]
  )



  useEffect(() => {
    getColumnData()
  }, [])


  useEffect(() => {

    if (!columnData) {

      history.push("/dashboard/overview")

    }

  }, [columnData])

  const getColumnData = async () => {

    const userId = user?.user.id
    let columnData = await Api.get<any, any>(Endpoint.Ride, { id: userId });
    columnData = columnData[0]?.columns
    if (isCorporate) {
      columnData = columnData.concat(cooperateCol)
    }

    setColumnData(columnData)
    setLoading(true)
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    setPageSize,
    prepareRow,
  } = useTable({
    columns, data, manualPagination: true, manualSortBy: true, manualFilters: true
  }, useFilters, useSortBy, usePagination)

  const { pageSize } = state

  const getPageCount = async () => {
    setLoading(true);
    const sumValues = await Api.get<any, any>(Endpoint.TRANSACTION_RIDES_SUM, {time,from:from,to:to});
    console.log("sum Values",sumValues)
    const tempOffset = count * pageSize
    refresh(pageSize, tempOffset, search, sort.sortDirection, sort.accessor, data, sumValues)
  }

  const handler = useCallback(debounce((text: string) => { 
    setLoading(true)
    setSearch(text) 
  }, 2000), []);

  useEffect(() => {
    getPageCount()
    // setLoading(true)
  }, [search, pageSize, count, sort,time,from,to])

  const onNextPage = () => {
    setCount(prevCount => prevCount + 1)
  }

  const onPreviousPage = () => {
    setCount(prevCount => prevCount - 1)
  }

  const onNextNextPage = () => {
    setCount(pageCounts - 1)
  }

  const onPreviousPreviousPage = () => {
    setCount(0)
  }

  const onClickHeader = (column: any) => {
    switch (column.sortDirection) {
      case undefined:
        column.sortDirection = undefined
        setSort({ sortDirection: undefined, accessor: column.id,  });
        break;
      case 'ASC':
        column.sortDirection = 'DESC'
        setSort({ sortDirection: 'DESC', accessor: column.id });
        break;
      case 'DESC':
        column.sortDirection = undefined
        setSort({ sortDirection: undefined, accessor: column.id });
        break;
    }
  }

  const onChangeSeaech = (e: any, column: any) => {
    handler(e.target.value)
  }
  const clickMethod = (column: any) => {
    history.push(`/security/track/${column.original.TripCode}`);
  }

  
  const handleRightScroll = ()=>{
    
    setIntervalId(
    setInterval(()=>{
      scroll(200)
    },50)
    )
  }

  const handleLeftScroll = ()=>{
    
    setLeftScrollIntervalId(
    setInterval(()=>{
      scroll(-200)
    },50)
    )
  }

  useEffect(()=>{
    handleCSV()
  },[search,time,from,to])

  async function handleCSV (){

    const CSVData = await Api.get<transactionReport, any>(Endpoint.TRANSACTION_RIDES_LIST, { limit:999,search:search, column:sort.accessor, direction:sort.sortDirection, isCorporate:isCorporate,time: time, to: to, from: from});
    let ro:any = [];
    const currency = zoneData?.generalCurrency ? (zoneData?.generalCurrency as Currency).symbol : null;
    if (CSVData && CSVData?.transaction && CSVData?.transaction.length > 0) {
      CSVData?.transaction.map((key: any) => {
        ro.push({
          'TripCode': key.trip?.code == null ? "" : key.trip.code,
          'CreateTime': new Date(key.createTime).toLocaleString(),
          'TransactionID': key.shortId == null ? "" : key.shortId,
          'VehiclePlate': key.trip?.session?.vehicle?.plate == null ? "" : key.trip?.session?.vehicle?.plate,
          'StartTime': dateFormate(key.trip?.startTime),
          'EndTime': `${key.trip?.endTime == null ? 0 : dateFormate(key.trip.endTime)}`,
          'Distance': key.trip?.distance == null ? `${(0 as number).toFixed(3)}` + `${getGeneralZoneDistanceTitle()}` : `${(key.trip.distance / getGeneralZoneDistanceValue()).toFixed(3)} ${getGeneralZoneDistanceTitle()}`,
          'WaitTime': `${key.trip?.waitTime == null ? 0 : new Date(key.trip.waitTime * 1000).toISOString().substr(11, 8)}`,
          'TotalCCFee': key.trip?.ccCharge == null ? "" : `${currency} ${toSubUnit(key.trip.ccCharge)}`,
          'StartCharge': key.trip?.startCharge == null ? "" : `${currency} ${toSubUnit(key.trip.startCharge)}`,
          'TotalFare': key.trip?.totalFare == null ? "" : `${currency} ${toSubUnit(key.trip.totalFare)}`,
          'TotalTime': key.trip?.rideTime == null ? "" : new Date(key.trip.rideTime * 1000).toISOString().substr(11, 8),
          "GST": key.trip?.tax == null ? "" : `${currency} ${toSubUnit(key.trip.tax)}`,
          "Extras": key.trip?.extraCharge == null ? "" : `${currency} ${toSubUnit(key.trip.extraCharge)}`,
          "Levy": key.trip?.levy == null ? "" : `${currency} ${toSubUnit(key.trip.levy)}`,
          "Tolls": key.trip?.tolls == null ? "" : `${currency} ${toSubUnit(key.trip.tolls)}`,
          "BookingFee": key.trip?.bookingCharge == null ? "" : `${currency} ${toSubUnit(key.trip.bookingCharge)}`,
          "Type": key.trip?.type == null ? "" : key.trip.type,
          "RateType": key.trip?.rateType == null ? "" : key.trip.rateType,
          "DiscountType": key.trip?.discountType == null ? "" : key.trip.discountType,
          "DiscountValue": key.trip?.discountValue == null ? "" : `${currency} ${toSubUnit(key.trip.discountValue)}`,
          "Tips": key.trip?.tip == null ? "" : `${currency} ${toSubUnit(key.trip.tip)}`,
          "ccCharge": key.trip?.ccCharge == null ? "" : `${currency} ${toSubUnit(key.trip.ccCharge)}`,
          'WaitTimeFare': key.trip?.waitCharge == null ? "" : `${currency} ${toSubUnit(key.trip.waitCharge)}`,
          'DistanceFare': key.trip?.distanceCharge == null ? "" : `${currency} ${toSubUnit(key.trip.distanceCharge)}`,
          "Service": key.trip?.service.title == null ? "" : key.trip.service.title,
          'Zone': key.trip?.zone.title == null ? "" : key.trip.zone.title,
          'Fare': key.trip?.fare.title == null ? "" : key.trip.fare.title,
          'DriverID': key.trip?.session == null ? "" : `${key.trip?.session.driver.firstName} ${key.trip.session.driver.lastName}`,
          'Passenger': key.trip?.passenger?.firstName == undefined ? "" : `${key.trip?.passenger?.firstName} ${key.trip?.passenger?.lastName}`,
          'CompanyName': key.trip?.booking?.corporateBooking.companyName == null ? "" : `${key.trip?.booking?.corporateBooking.companyName}`,
          'ShiftCode': key.trip?.session.code == null ? "" : key.trip?.session.code,
        });
      })
      setTransactionCSV([...ro])
    }
   
  }
  

  return (
    <>
        <div>
          <div className=" py text-center" style={{ display: 'flow-root' }}>
            {(transaction)?<CSVLink style={{ float: "right", marginTop: "26px", marginRight: "20px" }} filename={"rides-report.csv"} className="btn btn-primary" data={transactionCSV}>{translationReport.t('reports.exportCSV')}</CSVLink>:null}
            <button style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className='btn btn-primary' onClick={()=>{scroll(200)}} onMouseDown={handleRightScroll}  onMouseUp={()=>clearInterval(intervalId)} onMouseLeave={()=>clearInterval(intervalId)}>{'>'}</button>
            <button style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className='btn btn-primary'  onClick={()=>{scroll(-200)}} onMouseDown={handleLeftScroll}  onMouseUp={()=>clearInterval(leftScrollIntervalId)} onMouseLeave={()=>clearInterval(leftScrollIntervalId)}>{'<'}</button>
            {loading==true?<button disabled onClick={(e) => { e.preventDefault(); getPageCount() }} style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className={`btn btn-primary`}>
            Loading.....</button>:<button onClick={(e) => { e.preventDefault(); getPageCount() }} style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className={`btn btn-primary os-icon os-icon-refresh-ccw`}>
            </button>}
          </div>
          <div> {time === 'custome' ?
          <>
            <div className="row">
              <FormInput
                label={t("From")}
                name="from"
                type="date"
                onChange={onChangeFrom}
                className="col-sm-3"
                validation={{ required: true }}
                tabIndex={7}
                {...formProps}
              />
              <FormInput
                label={t("To")}
                name="to"
                type="date"
                onChange={onChangeTo}
                className="col-sm-3"
                validation={{ required: true }}
                tabIndex={7}
                {...formProps}
              />
            </div>

          </> : null}
          <div style={{ marginRight: '15px', marginBottom: '10px', marginTop: '15px' }}>
            <form className="form-inline justify-content-sm-end">
              <select value={time} onChange={changeTime} className="form-control form-control-sm rounded">
                <option value="today">{t('dropdown.today')}</option>
                <option value="yesterday">{t('dropdown.yesterday')}</option>
                <option value="this-week">{t('dropdown.thisWeek')}</option>
                <option value="last-week">{t('dropdown.lastWeek')}</option>
                <option value="this-month">{t('dropdown.thisMonth')}</option>
                <option value="last-month">{t('dropdown.lastMonth')}</option>
                <option value="this-year">{t('dropdown.thisYear')}</option>
                <option value="custome">{t('dropdown.custome')}</option>
              </select>
            </form>
          </div> </div>
          <div ref={ref} style={{ marginLeft: "5px", marginRight: "5px", overflowX: "scroll", width: "100%" }} className=" py-5 text-center r2">
        <table {...getTableProps()} className="table table-striped table-Info table-hover table-bordered">
              <thead>
                {headerGroups.map((headerGroup: any) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())} onClick={() => onClickHeader(column)} className="bg-primary text-white">
                        {column.render('Header')?translationReport.t(`reports.rides.${column.id}`):""}
                        <span>
                          {column.sortDirection === 'ASC' ? (
                            ' 🔽'
                          ) : column.sortDirection === 'DESC' ? (
                            ' 🔼'
                          ) : null}
                        </span>
                        <div><span>
                          <input onChange={(e) => onChangeSeaech(e, column)}
                          />
                        </span></div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
       {transaction?.length>0?<tbody {...getTableBodyProps()}>
                {page.map((row: any) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} onClick={() => clickMethod(row)}>
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>:<tbody>
                <tr>
                  <td colSpan={columnData.length-12}>
                  No results to display
                  </td>
                </tr>
                </tbody>}
            </table>
          </div>
          <div className=" py-5 text-center">
            <span>
            {translationReport.t('reports.page')}{' '}
              <strong>
                {count + 1} {translationReport.t("reports.of")} {pageCounts}
              </strong>{' '}
            </span>
            <span>
              | {translationReport.t("reports.goPage")}{''}
              <input
                type='number'
                defaultValue={count + 1}
                onChange={(e: any) => {
                  const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                  setCount(pageNumber)
                }}
                style={{ marginRight: "10px", width: "70px", marginLeft: "5px", borderRadius: "5px" }}
              />
            </span>
            <select
              value={pageSize}
              onChange={(e: any) => setPageSize(Number(e.target.value))}
              style={{ marginRight: "10px", width: "80px", marginLeft: "5px", borderRadius: "5px" }}
            >
              {[10, 25, 50, 100].map((pageSize: any) => {
                return <option key={pageSize} value={pageSize}>
                  {translationReport.t('reports.show')} {pageSize}
                </option>
              })}
            </select>
            <button type="button" className="btn btn-primary" onClick={() => onPreviousPreviousPage()} disabled={count > 0 ? false : true}>{'<<'}</button>
            <button type="button" style={{ marginRight: "10px", marginLeft: "10px" }} className="btn btn-primary" onClick={() => onPreviousPage()} disabled={count > 0 ? false : true}>{translationReport.t('reports.previous')}</button>
          <button type="button" style={{ marginRight: "10px" }} className="btn btn-primary" onClick={() => onNextPage()} disabled={(count + 1) < pageCounts ? false : true}>{translationReport.t('reports.next')}</button>
            <button type="button" className="btn btn-primary" onClick={() => onNextNextPage()} disabled={(count + 1) < pageCounts ? false : true}>{'>>'}</button>
          </div>
        </div>
    </>
  )
}