import Peer from 'peerjs';
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "react-use";
import EmergenyFunctions from '../../../../components/emergency/emergency.functions';
import { GroupFilter, ListFilter, StatusFilter } from "../../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../../components/list/items.component";
import { List } from "../../../../components/list/wrapper.component";
import { View } from '../../../../components/view/wrapper.component';
import { Driver } from '../../../../entities/driver.entity';
import { Emergency, EmergencyStatus } from '../../../../entities/emergency.entity';
import { Passenger } from "../../../../entities/passenger.entity";
import FemaleImg from '../../../../img/female.png';
import MaleImg from '../../../../img/male.png';
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../../services/api.service';
import { getStorageUrl } from "../../../../util/file.util";
import SelectedItemContent from "./content.component";

export enum ProfilePicSize {
    List = "admin_list",
    Single = "admin_single",
}

export enum Gender {
    Male = 'male',
    Female = 'female',
    Other = 'other',
}

function EmergencyList() {
    const { t } = useTranslation('list');
    const { y: pageYOffset } = useWindowScroll();
    const groupFilters: GroupFilter[] = [
        { title: "new", icon: "phone-21" },
        { title: "action", icon: "alert-circle" },
        { title: "TakenByWebAdmin", icon: "edit-1" },
        { title: "completed", icon: "newspaper" },
    ];
    const statusFilters: StatusFilter[] = [
        { title: "all", status: ListItemStatus.Blue },
        { title: "high", status: ListItemStatus.Red },
        { title: "medium", status: ListItemStatus.Yellow }
    ];
    const [peer, setPeer] = useState<Peer | null>(null);
    const [changeStatus, setChangeStatus] = useState<EmergencyStatus | undefined>(undefined)
    const [search, setSearch] = useState<string>('');
    const [limit, setLimit] = useState<number>(20);
    const [count, setCount] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [newCount, setNewCount] = useState<number>(0);
    const [actionCount, setActionCount] = useState<number>(0);
    const [takenCount, setTakenCount] = useState<number>(0);
    const [completedCount, setCompletedCount] = useState<number>(0);
    const [emergencies, setEmergencies] = useState<Emergency[]>();
    const [selectedEmergency, setSelectedEmergency] = useState<Emergency>();
    const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[3]);
    const [activeStatusFilter, setActiveStatusFilter] = useState<StatusFilter | undefined>(statusFilters[0]);
    const [size, setSize] = useState<number>()

    const refresh = useCallback(async () => {
        let group: string | undefined = activeGroupFilter?.title;
        group = group === 'all' ? undefined : group;
        console.log("Data Group:",group)
        const status = activeStatusFilter?.title==='all'?undefined:activeStatusFilter?.title;
        setEmergencies([]);
        const { items, totalItems } = await Api.get<IPaginationResponse<Emergency>, IPaginationOptions>(Endpoint.EMERGENCY_LIST, { page: 0, limit, group, status });

        if (items && items.length !== totalItems) {
            setHasMore(true)
        } else {
            setHasMore(false)
        }

        if (items) {
            setEmergencies(items);
        }
        if (items.length > 0 && !selectedEmergency) {
            setSelectedEmergency(items[0]);
        }
        const ITEMS = await Api.get<IPaginationResponse<Emergency>, IPaginationOptions>(Endpoint.EMERGENCY_LIST, { limit: 10000000 });
        console.log('Total Emergencies:', ITEMS)
        setNewCount(ITEMS.items.filter(item => item.status === 'new').length)
        setActionCount(ITEMS.items.filter(item => item.status === 'action').length)
        setTakenCount(ITEMS.items.filter(item => item.status === 'TakenByWebAdmin').length)
        setCompletedCount(ITEMS.items.filter(item => item.status === 'completed').length)

        // setEmergencies(items);
        // switch (activeGroupFilter.title) {
        //     case EmergencyStatus.New:
        //         setChangeStatus(EmergencyStatus.New);
        //         break;
        //     case EmergencyStatus.Taken:
        //         setChangeStatus(EmergencyStatus.Taken);
        //         break;
        //     case EmergencyStatus.Completed:
        //         setChangeStatus(EmergencyStatus.Completed);
        //         break;
        //     case EmergencyStatus.Action:
        //         setChangeStatus(EmergencyStatus.Action);
        //         break;
        // }
    }, [search, limit, activeGroupFilter, activeStatusFilter]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useEffect(() => {
        const peer = new Peer(undefined, {
            host: 'rtc.taxis.plus',
            secure: true,
            path: '/',
            config: {
                'iceServers': [
                    { 'urls': 'stun:av.taxis.plus:3478' }
                ]
            },
        });

        peer.on('open', () => {
            setPeer(peer);
        });

        return () => {
            peer && peer.destroy();
            setPeer(null);
        }
    }, []);

    function getPeerConnection(handlerPeerMessage: any, peerID: string) {
        if (peer) {
            const connection = peer.connect(peerID);
            connection.on('open', () => {
                handlerPeerMessage("Connection Available");
                connection.close();
            });
            connection.on('error', () => {
                handlerPeerMessage("No Connection Available");
            });
            peer.on('error', () => {
                handlerPeerMessage("No Connection Available");
            });
        }
    }

    const getProfilePic = (driver: Driver, type: ProfilePicSize): string => {
        console.log('driver ka ma ka:', driver)
        if (driver?.picFile) {
            return `${getStorageUrl()}/driver/${driver.id}/pic/${driver.picFile?.id
                }/${type}.${driver.picFile?.extension}`;
        } else {
            return driver?.gender === Gender.Female ? FemaleImg : MaleImg;
        }
    };

    const fetchMoreData = () => {
        setLimit(limit + 10);
    };

    const onNext = () => {
        if (emergencies) {
            const currentIncidentIndex = emergencies.findIndex(emergency => !!selectedEmergency && emergency.id === selectedEmergency.id);
            if (currentIncidentIndex + 1 < emergencies.length && currentIncidentIndex !== -1) {
                setSelectedEmergency(emergencies[currentIncidentIndex + 1]);
            }
        }
    }

    const onPrev = () => {
        if (emergencies) {
            const currentIncidentIndex = emergencies.findIndex(emergency => !!selectedEmergency && emergency.id === selectedEmergency.id);
            if (currentIncidentIndex - 1 > -1 && currentIncidentIndex !== -1) {
                setSelectedEmergency(emergencies[currentIncidentIndex - 1]);
            }
        }
    }

    const getFullName = (driver: Driver): string => {
        return [driver?.firstName, driver?.lastName].join(" ");
    };

    const getPassengerFullName = (passenger: Passenger): string => {
        return [passenger?.firstName, passenger?.lastName].join(" ");
    };

    const toListItems = (emergency?: Emergency): ListItem<Emergency> | undefined => {
        if (!emergency) {
            return;
        }

        return {
            id: emergency.id as string,
            title: emergency.type === 'DriverApp' ? getFullName(emergency.driver) : getPassengerFullName(emergency.passenger),
            status: ListItemStatus.Green,
            ref: emergency,
        };
    }

    const selectGroup = (target: GroupFilter) => {
        setSelectedEmergency(undefined);
        setActiveGroupFilter(target);
    };

    const selectStatus = (target: StatusFilter | undefined) => {
        setSelectedEmergency(undefined);
        setActiveStatusFilter(
            target?.title !== activeStatusFilter?.title ? target : undefined
        );
    };

    const onSearchChange = (search: string) => {
        setSearch(search);
    }

    const deleteEmergency = async () => {
        if (selectedEmergency) {
            await EmergenyFunctions.deleteEmerencyRequest(selectedEmergency?.id);
            setSelectedEmergency(undefined);
            refresh();
        }
    };

    const updateSize = () => {
        setSize(window.screen.width)
    }

    useEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [])

    return (
        <div>
            {size && size < 1000 ?
                <List>
                    <div className="row">
                        <div className="col-xl-12">
                            <ListFilter
                                groupFilters={[
                                    { title: "new", icon: "phone-21", count: newCount },
                                    { title: "action", icon: "alert-circle", count: actionCount },
                                    { title: "TakenByWebAdmin", icon: "edit-1", count: takenCount },
                                    { title: "completed", icon: "newspaper", count: completedCount },
                                ]}
                                statusFilters={statusFilters}
                                activeGroupFilter={activeGroupFilter}
                                onGroupSelect={selectGroup}
                                activeStatusFilter={activeStatusFilter}
                                onStatusSelect={selectStatus}
                            />
                        </div>
                        <div className="col-xl-12">
                            <ListItems
                                items={emergencies?.map(toListItems) as ListItem<Emergency>[]}
                                activeItem={toListItems(selectedEmergency)}
                                onSearchChange={onSearchChange}
                                showSearch={false}
                                showRefresh={true}
                                hasMore={hasMore}
                                fetchMoreData={fetchMoreData}
                                onRefresh={() => { setEmergencies(undefined); refresh(); }}
                                onClick={(item: ListItem<Emergency>) => { setSelectedEmergency(item.ref); }}
                            />
                        </div>
                        <div className="col-xl-12" style={{ marginTop: "40px" }}>
                            {selectedEmergency && (
                                <View
                                    componentName={"emergency"}
                                    showDelete={true}
                                    onDelete={deleteEmergency}
                                    showNext={true}
                                    showPrev={true}
                                    showStateChange={true}
                                    onPrev={onPrev}
                                    onNext={onNext}
                                >
                                    <SelectedItemContent
                                    key={selectedEmergency.id}
                                        selectedEmergency={selectedEmergency}
                                        getProfilePic={getProfilePic}
                                        refresh={refresh}
                                        handlerEmergency={setSelectedEmergency}
                                        checkConnection={getPeerConnection}
                                    // getFullName={getFullName}
                                    // onApprove={refresh}
                                    />
                                </View>
                            )}
                        </div>
                    </div>
                </List> :
                <List>
                    <ListFilter
                        groupFilters={[
                            { title: "new", icon: "phone-21", count: newCount },
                            { title: "action", icon: "alert-circle", count: actionCount },
                            { title: "TakenByWebAdmin", icon: "edit-1", count: takenCount },
                            { title: "completed", icon: "newspaper", count: completedCount },
                        ]}
                        statusFilters={statusFilters}
                        activeGroupFilter={activeGroupFilter}
                        onGroupSelect={selectGroup}
                        activeStatusFilter={activeStatusFilter}
                        onStatusSelect={selectStatus}
                    />
                    <ListItems
                        items={emergencies?.map(toListItems) as ListItem<Emergency>[]}
                        activeItem={toListItems(selectedEmergency)}
                        onSearchChange={onSearchChange}
                        showSearch={false}
                        showRefresh={true}
                        hasMore={hasMore}
                        fetchMoreData={fetchMoreData}
                        onRefresh={() => { setEmergencies(undefined); refresh(); }}
                        onClick={(item: ListItem<Emergency>) => { setSelectedEmergency(item.ref); }}
                    />
                    {selectedEmergency && (
                        <View
                            componentName={"emergency"}
                            showDelete={true}
                            onDelete={deleteEmergency}
                            showNext={true}
                            showPrev={true}
                            showStateChange={true}
                            onPrev={onPrev}
                            onNext={onNext}
                        >
                            <SelectedItemContent
                             key={selectedEmergency.id}
                                selectedEmergency={selectedEmergency}
                                getProfilePic={getProfilePic}
                                refresh={refresh}
                                handlerEmergency={setSelectedEmergency}
                                checkConnection={getPeerConnection}
                            // getFullName={getFullName}
                            // onApprove={refresh}
                            />
                        </View>
                    )}
                </List>
            }
        </div>
    )
}

export default EmergencyList;
