import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Api, Endpoint } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { AuthRoute } from './routes';


enum BrowserStatus {
  Online = "online",
  Offline = "offline",
  OnCall = "on-call"
}

export function AuthLogout() {

  const logout = async () => {
    const data: any = AuthService.getUser();
    console.log('This is User Data:', data);
    await Api.patch<any, any>(Endpoint.USER, { browserStatus: BrowserStatus.Offline, id: data.user?.id, twilioClientId: "" })
  }
  useEffect(() => {
    logout();
    AuthService.logout();
  }, [])

  return (
    <Redirect to={AuthRoute.Login} />
  );

}
